<template>
  <div class="h-[400px]">
    <div class="p-5 overflow-y-auto h-full xl:pb-1 pb-10">
      <div
        class="shadow h-96 overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead
            class="bg-white border-black border-dotted border-b-2 border-opacity-20"
          >
            <tr>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 270px !important"
              >
                Sipariş No
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 120px !important"
              >
                Müşteri
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 100px !important"
              >
                Miktar
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 190px !important"
              >
                Yükleme Noktası
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 190px !important"
              >
                Teslim Noktası
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 110px !important"
              >
                Ö. Araç Sayısı
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 110px !important"
              >
                Ö. Araç Türü
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 150px !important"
              >
                km / saat
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 70px !important"
              >
                Teslim Tarihi
              </th>

              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 80px !important"
              >
                #
              </th>
            </tr>
          </thead>
          <tbody
            class="divide-y-2 divide-gray-500"
            style="background-color: #3a3a3a"
          >
            <td>
              <div class="flex items-center justify-center px-2 py-1">
                <orderInput
                  :hideContainer="true"
                  title="1"
                  v-model="orderId"
                  :isBulk="true"
                  @changeObject="
                    (val) => {
                      selected = val;
                    }
                  "
                />
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center px-2 py-1">
                <diffInput
                  :hideContainer="true"
                  :disabled="true"
                  :value="selected.customer"
                />
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center px-2 py-1">
                <diffInput
                  :hideContainer="true"
                  type="number"
                  v-model="selected.amount"
                  :disabled="true"
                  :required="true"
                  :max="99"
                  :min="1"
                  :step="1"
                />
              </div>
            </td>
            <td class="">
              <div class="flex items-center justify-center px-2 py-1">
                <diffInput
                  :hideContainer="true"
                  type="text"
                  :disabled="true"
                  v-model="selected.outpointAdress"
                />
              </div>
            </td>
            <td class="">
              <div class="flex items-center justify-center px-2 py-1">
                <diffInput
                  :hideContainer="true"
                  type="text"
                  :disabled="true"
                  v-model="selected.targetAdress"
                />
              </div>
            </td>

            <td>
              <p
                class="flex items-center justify-center text-white px-2 py-1 border-2 mx-1 border-white"
              >
                #
              </p>
            </td>
            <td>
              <p
                class="flex items-center justify-center text-white px-2 py-1 border-2 mx-1 border-white"
              >
                #
              </p>
            </td>
            <td class="">
              <div class="flex items-center justify-center px-2 py-1">
                <diffInput
                  :hideContainer="true"
                  type="datetime-local"
                  v-model="selected.deliveryDate"
                  :required="true"
                  :disabled="true"
                />
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center px-2 py-1">
                <button
                  type="button"
                  :disabled="load"
                  class="process-btn mr-2"
                  @click="getRoute()"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  :disabled="load"
                  type="button"
                  class="process-btn"
                  @click="resetItem()"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
            <tr v-for="(item, index) in List" :key="index">
              <td class="table-td border-r border-opacity-20">
                <span># {{ item.orderNo }} </span>
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.customer }}
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.amount }} TON
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.outpointAdress }}
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.targetAdress }}
              </td>

              <td class="table-td border-r border-opacity-20">
                {{ item.recomendedCount }} Araç
              </td>
              <td class="table-td border-r border-opacity-20">
                <div class="w-full md:pl-2">
                  <diffInput
                    type="select"
                    :hideContainer="true"
                    :optList="[
                      {
                        id: 3,
                        name: 'Hepsi',
                      },
                      {
                        id: 1,
                        name: 'Kiralık',
                      },
                      {
                        id: 2,
                        name: 'Özmal',
                      },
                    ]"
                    minlength="1"
                    :required="true"
                    v-model="item.haveType"
                  />
                </div>
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.kilometer }}km / {{ (item.time / 60).toFixed(2) }} saat
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.deliveryDate | dateFilter }}
              </td>
              <td class="">
                <div class="flex justify-center items-center">
                  <button
                    type="button"
                    class="process-btn"
                    @click="deleteItem(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tableLoader :load="load" colspan="9" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
//Npm
import moment from "moment";

//Global Components
import orderInput from "@/components/devItem/orderInput.vue";
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//Networking
import axios from "axios";
import { bulkShipPlanning, routePlanner } from "@/networking/urlmanager";
import { routeEngine } from "@/networking/routeEngine";

//Utils
import calculatorModel from "@/utils/vehecleCountRecomendation.js";
export default {
  name: "steptwoPage",
  props: [
    "shipId",
    "isAdd",
    "orderCount",
    "dates",
    "isLaterAddOrder",
    "minVehicleCount",
  ],
  components: {
    orderInput,
    tableLoader,
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      // routes: [],
      load: false,
      orderId: "",
      selected: {
        amount: "",
        deliveryDate: "",
        targetAdress: "",
        customer: "",
        kilometer: "",
        recomendedCount: "",
      },
      totalRequimentVehicleCount: 0,
      totalAmount: 0,
      List: [],
      activeRoute: 0,
      routes: {},
    };
  },

  methods: {
    deleteItem(index) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "SİPARİŞİ LİSTEDEN ÇIKARMAK İSTEDİĞİNİZE EMİN MİSİNİZ?",
        true,
        this.$t("general.confirmText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          if (index > -1 && index < this.List.length) {
            this.List.splice(index, 1); // Belirtilen indeksteki öğeyi listeden kaldırır
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "Sipariş başarılı bir şekilde kaldırıldı...",
              false,
              this.$t("general.OkayTitle")
            );
          } else {
            this.swalBox(
              "warning",
              "HATA",
              "Sipariş kaldırılırken  bir hata oluştu lütfen tekrar deneyin...",
              false,
              this.$t("general.OkayTitle")
            );
          }
        }
      });
    },
    async calculateVehicleCount(totalMinute, deliveryDate, amount, orderDate) {
      const totalTonnage = amount;
      const loadPerVehicle = 26; // her bir aracın bir seferde taşıyabileceği yük miktar
      const maxDailyDrivingTime = 540;

      const { requiredVehicles, errorMessage } =
        await new calculatorModel().calculateRecomendedCount(
          orderDate,
          1,
          totalMinute,
          this.dates?.filling, //30, //this.fillingDate,
          this.dates?.delivery, //this.deliveryDate,
          deliveryDate,
          totalTonnage,
          loadPerVehicle,
          maxDailyDrivingTime,
          1
        );

      if (errorMessage) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          errorMessage,
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      if (!Array.isArray(requiredVehicles)) {
        return requiredVehicles;
      }
    },
    getRoute() {
      if (this.orderId == "") {
        this.swalBox(
          "warning",
          "UYARI!",
          "LÜTFEN ÖNCE BİR SİPARİŞ SEÇİN...",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      const alreadyAdded = this.List.some(
        (item) => item.id === this.selected.id
      );
      if (alreadyAdded) {
        this.swalBox(
          "warning",
          "UYARI!",
          "Sipariş zaten listenize eklenmiş. Tekrar eklemek mümkün değil.",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      this.activeRoute = 0;
      this.load = true;
      axios
        .post(
          routeEngine.generate,
          {
            from: {
              lat: Number(this.selected.outpointLat),
              lng: Number(this.selected.outpointLng),
            },
            to: {
              lat: Number(this.selected.targetPointLat),
              lng: Number(this.selected.targetPointLng),
            },
            fuelRatio: 35,
            isPolyline: 1,
            shippedHazardousGoods: 1,
            vehicleTypeId: 5,
            routeType: "fast",
            isApi: 1,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.routeEngineToken,
            },
          }
        )
        .then((res) => {
          this.routes = res.data.data[0];
          this.addItem();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async addItem() {
      // const data = this.routes[this.activeRoute];
      const data = this.routes;
      const kilometer = data.kilometer;
      const time = data.totalMinutes;
      const orderDate = this.selected.orderDate;

      const recomendedCount = await this.calculateVehicleCount(
        time,
        moment(this.selected.deliveryDate).format("YYYY-MM-DD HH:mm"),
        Number(this.selected.amount),
        orderDate
      );

      this.totalRequimentVehicleCount += recomendedCount;
      this.totalAmount += Number(this.selected.amount);

      this.List.push({
        ...this.selected,
        haveType: 3,
        kilometer: kilometer,
        time: time,
        recomendedCount,
      });

      this.resetItem();
    },
    resetItem() {
      this.orderId = "";
      this.selected = {
        amount: "",
        deliveryDate: "",
      };
    },
    async controller() {
      return new Promise((resolve, reject) => {
        const totalCalculatedVehicles = this.List.reduce(
          (sum, order) => sum + order.recomendedCount,
          0
        );

        const additionalVehiclesNeeded = Math.max(
          this.minVehicleCount - totalCalculatedVehicles,
          0
        );

        if (additionalVehiclesNeeded > 0) {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Araç sayısı otomatik olarak artırıldı: Operasyon için gereken minimum sayıya ulaşıldı.",
            false,
            this.$t("general.OkayTitle")
          )
            .then(() => {
              // Ek araçları siparişler arasında paylaştıralım
              this.distributeAdditionalVehicles(additionalVehiclesNeeded);
              resolve();
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else resolve();
      });
    },
    async save(callback) {
      if (this.List.length == 0) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen ilk önce bir sipariş ekleyin!",
          false,
          this.$t("general.OkayTitle")
        );
        return callback(false);
      }

      await this.controller();
      axios
        .post(
          bulkShipPlanning.orderAdd,
          {
            orders: JSON.stringify(this.List),
            shipId: this.shipId,
            isAdd: this.isAdd && this.orderCount > 0 ? 1 : 0,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          // this.$emit("refresh");
          callback(true);
        })
        .catch((err) => {
          this.errorBox(err.response);
          callback(false);
        });
    },
    distributeAdditionalVehicles(additionalVehicles) {
      // Siparişleri miktarlarına göre sıralayalım (büyükten küçüğe)
      const sortedList = [...this.List].sort((a, b) => b.amount - a.amount);

      let remainingVehicles = additionalVehicles;
      let index = 0;

      while (remainingVehicles > 0 && index < sortedList.length) {
        sortedList[index].recomendedCount++;
        remainingVehicles--;
        index = (index + 1) % sortedList.length;
      }

      // Orijinal listeyi güncelleyelim
      this.List.forEach((order) => {
        const updatedOrder = sortedList.find((o) => o.id === order.id);
        order.recomendedCount = updatedOrder.recomendedCount;
      });
    },
  },
  watch: {
    List(val) {
      this.$emit("changeList", val);
    },
  },
  filters: {
    dateFilter(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>
