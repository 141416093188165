<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <routeReportModal v-if="selected" :detail="selected" />
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <LyrausTable
        :columns="columns"
        :data="List"
        :isLoading="load"
        :enablePagination="true"
        :totalCount="totalCount"
        :pageCount="pageCount"
        @update-current-page="handleCurrentPage"
        :paginationButtonColor="'red-600'"
        :paginationHoverColor="'red-700'"
        :alternateRowBg="['white', 'gray-100']"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :buttonsColumn="{ label: 'İşlem', width: 40 }"
        :batchOperations="true"
        :searchBar="true"
        @update-search-value="(val) => handleSearchValue(val)"
        :handleSearch="handleSearch"
      >
        <template v-slot:colButtons="{ item }">
          <div class="flex items-center justify-end px-2">
            <button @click="showPreviewMapModal(item)">
              <i class="fas fa-book text-gray-700"></i>
            </button>
          </div>
        </template>
        <template v-slot:rowButtons="{ item, columnKey }">
          <div v-if="columnKey === 'actualKm'">
            <span>
              {{ Math.round(item.actualKm) }}
            </span>
          </div>
          <div v-if="columnKey === 'expectedKm'">
            <span>
              {{ Math.round(item.expectedKm) }}
            </span>
          </div>
          <div v-if="columnKey === 'seferiKm'">
            <span>
              {{ Math.round(item.seferiKm) }}
            </span>
          </div>
        </template>
        <template v-slot:batchOperations>
          <form
            @submit.prevent="getAll"
            class="p-2 flex items-center justify-between bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
          >
            <div class="w-full">
              <diffInput
                v-model="startDate"
                :max="endDate"
                title="Başlangıç tarihi"
                type="datetime-local"
                :hideContainer="true"
              />
            </div>

            <div class="w-full mx-4">
              <diffInput
                v-model="endDate"
                :min="startDate"
                title="Bitiş Tarihi"
                type="datetime-local"
                :hideContainer="true"
                :max="getNowDate"
              />
            </div>

            <div class="w-full">
              <asyncBtn
                icon="fas fa-search"
                text="Ara"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-32"
                size="py-2"
              />
            </div>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </form>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import axios from "axios";
import moment from "moment";

import { reports } from "../../../networking/urlmanager";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

import routeReportModal from "./components/vehicleIdleModal.vue";

export default {
  name: "routeReport",
  components: {
    diffInput,
    asyncBtn,
    routeReportModal,
  },
  data() {
    return {
      load: false,
      selected: undefined,
      columns: [
        { label: "Araç Plakası", key: "vehicle" },
        {
          label: "Ekleme Tarihi",
          key: "addDate",
          isDate: true,
        },
        {
          label: "Son İş Tarihi",
          key: "lastJobDate",
          isDate: true,
        },
        {
          label: "Sıradaki İş Tarihi",
          key: "nextJobDate",
          isDate: true,
        },
        {
          label: "Boşta Geçen Süre",
          key: "timePassed",
        },
        {
          label: "İş Durumu",
          key: "state",
        },
      ],
      currentPage: 0,
      totalCount: 0,
      pageCount: 1,
      List: [],
      realList: [],
      startDate: "",
      search: "",
      endDate: "",
    };
  },
  methods: {
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    showPreviewMapModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("vehicle-idle-time-modal");
      }, 50);
    },
    calculateTimeDifference(startDate, endDate) {
      if (!startDate || !endDate) return "N/A";
      const diffInMilliseconds = endDate - startDate;
      const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
      const days = Math.floor(diffInMinutes / (24 * 60));
      const hours = Math.floor((diffInMinutes % (24 * 60)) / 60);
      const minutes = diffInMinutes % 60;

      let result = "";
      if (days > 0) result += `${days} gün `;
      if (hours > 0) result += `${hours} saat `;
      result += `${minutes} dakika`;

      return result.trim();
    },
    processVehicleData(newList) {
      const currentDate = new Date();

      return newList.map((item) => {
        let state, timePassed;

        if (item.nextJobDate) {
          state = "Araç iş aldı";
          timePassed = this.calculateTimeDifference(
            new Date(item.lastJobDate),
            new Date(item.nextJobDate)
          );
        } else if (!item.lastJobDate) {
          state = "Araç boşta";
          timePassed = this.calculateTimeDifference(
            new Date(item.addDate),
            currentDate
          );
        } else {
          state = "Araç boşta";
          timePassed = this.calculateTimeDifference(
            new Date(item.lastJobDate),
            currentDate
          );
        }

        return {
          ...item,
          state,
          timePassed,
        };
      });
    },
    async getAll() {
      this.load = true;
      const params = {
        startDate: this.startDate
          ? moment(this.startDate)
              .utc()
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        endDate: this.endDate
          ? moment(this.endDate)
              .utc()
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        page: this.currentPage,
        search: this.search,
      };
      try {
        const res = await axios.get(reports.getVehicleIdleTimes, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        });
        const { detail, totalCount, pageCount } = res.data.data;
        const processedList = this.processVehicleData(detail);
        this.totalCount = totalCount;
        this.pageCount = pageCount;

        this.List = processedList;
        this.realList = processedList;
      } catch (err) {
        console.log("err", err);

        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    async excelExport() {
      const workbook = XLSX.utils.book_new();
      const headers = {
        vehicle: "Araç Plakası",
        addDate: "Ekleme Tarihi",
        lastJobDate: "Son İş Tarihi",
        nextJobDate: "Sıradaki İş Tarihi",
      };

      const detail = [
        Object.values(headers), // Başlıklar
        ...this.List.map((item) => {
          return Object.keys(headers).map((key) => {
            if (key === "vehicle") return item.vehicle;
            if (key === "addDate") return item.orderNo;
            if (key === "lastJobDate") return item.routeId;
            if (key === "nextJobDate") return item.routeId;
            return item[key] || "";
          });
        }), // Veriler
      ];

      // Verileri worksheet'e dönüştür
      const worksheet = XLSX.utils.json_to_sheet(detail);

      XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", "", ""]], {
        origin: "A1",
      });

      // Worksheet'i workbook'a ekle
      XLSX.utils.book_append_sheet(workbook, worksheet, "routeReports");

      // Excel dosyasını oluştur
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Dosyayı kaydet
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(data, "route-reports.xlsx");
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
