<template>
  <modal
    name="fleet-vehicles-modal"
    :width="isMobile ? '100%' : '40%'"
    :height="isMobile ? '100%' : 'auto'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-center items-center"
    >
      <h4 class="inline-block w-10/12">{{ name }}</h4>
      <div class="w-2/12 flex items-center justify-end mr-1">
        <logBtn />
        <button class="p-2 w-1/12" @click="$modal.hide('fleet-vehicles-modal')">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="mx-4 items-center justify-center">
      <VehiclesSelect v-model="fleetCars" :allCars="List" />
    </div>
    <div
      v-if="fleetCars.length"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto pb-20 xl:h-auto xl:pb-3"
    >
      <LyrausTable :columns="columns" :data="fleetCars" :indexColumn="true">
      </LyrausTable>
    </div>
    <div v-else class="p-4 text-center">
      <span>Bu filoya bağlı araç bulunmamaktadır</span>
    </div>
    <div class="p-4 text-right">
      <asyncBtn
        @click="handleSubmit"
        :text="'KAYDET'"
        :loadTitle="$t('general.saveButtonLoadTitle')"
        :loadState="load"
        class="w-full"
      />
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import { fleetConfiguration } from "@/networking/urlmanager";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import VehiclesSelect from "./vehicles.select.vue";

export default {
  components: {
    asyncBtn,
    VehiclesSelect,
    logModule,
    logBtn,
  },
  name: "fleet-vehicles-modal",
  props: ["detail"],
  data() {
    return {
      load: false,
      List: [],
      fleetCars: [],
      name: "",
      rowId: null,
      columns: [
        {
          key: "vehicle",
          label: "Araç",
        },
      ],
    };
  },
  methods: {
    getDetail() {
      const params = {
        fleetId: this.detail.fleetId,
      };
      this.load = true;
      axios
        .get(fleetConfiguration.getFleetVehicles, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.fleetCars = result.data.data.fleetCars.map((item) => ({
            ...item,
            status: 1,
          }));

          this.List = [...this.fleetCars, ...result.data.data.independentCars];
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async handleSubmit() {
      this.load = true;
      const params = {
        fleetId: this.detail.fleetId,
        rowId: this.detail.id,
        vehicleList: this.fleetCars,
      };
      try {
        await axios.post(fleetConfiguration.editFleetVehicles, params, {
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        });
        this.swalBox(
          "success",
          this.$t("general.successTitle"),
          "",
          false,
          this.$t("general.OkayTitle")
        );
        this.$emit("refresh", true);
        this.$modal.hide("fleet-vehicles-modal");
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
