<template>
  <div>
    <div class="w-full">
      <diffInput
        title="Adı"
        v-model="value.name"
        maxlength="1024"
        minlength="2"
        type="Text"
      />
    </div>
    <div class="mt-4">
      <GmapItem
        placeHolder="Konum | Adres"
        height="192px"
        @change="(val) => (value.position = val)"
        :lat="value.position.lat"
        :lng="value.position.lng"
        :draggable="true"
        :defaultAdress="value.position.adress"
      />
    </div>
    <div class="mt-4">
      <diffInput
        title="Hassasiyet (metre)"
        v-model="value.sensibility"
        max="20000"
        type="number"
        :step="1"
        :min="5"
      />
    </div>
  </div>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import GmapItem from "@/components/general/gmapInput.vue";
export default {
  name: "washpoint-step-one",
  props: ["value"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    diffInput,
    GmapItem,
  },
};
</script>
