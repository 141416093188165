<template>
  <div
    class="fixed md:top-24 xl:top-24 top-0 h-screen xl:h-full w-full bg-white px-4 py-8 p z-20 md:right-0 xl:right-0 shadow md:w-64 xl:w-64"
  >
    <MenuItem v-model="activeMenu" :total-warning-count="totalWarningCount" />
    <div :class="activeMenu === 2 && 'h-0.5/6 '">
      <div class="mt-6" v-if="activeMenu === 2">
        <approveInput
          v-model="isApproved"
          :totalUnapprovedCount="totalUnapprovedCount"
          :totalWontMakeIt="totalWontMakeIt"
        />
      </div>
    </div>
    <div
      v-if="activeMenu == 1"
      class="w-full pt-2 overflow-y-scroll h-5/6 pb-24"
    >
      <groupPanel
        @changeFleet="(val) => handleFleet(val)"
        @changeGroup="(val) => handleGroup(val)"
      />
    </div>

    <div v-else class="w-full py-2 overflow-y-scroll h-5/6 pb-24">
      <AnApprovadPanel
        :List="getWarning()"
        @selectedItem="(val) => $emit('selectedItem', val)"
        :totalWarningCount="totalWarningCount"
        :isApproved="isApproved"
      />
    </div>
  </div>
</template>

<script>
// npm
import moment from "moment";

// utils
import { haversineDistance } from "@/utils/recomendation";

// local components
import MenuItem from "./components/menu.vue";
import groupPanel from "./components/group.vue";
import AnApprovadPanel from "./components/anApprovad.vue";
import approveInput from "./components/approveInput.vue";

export default {
  name: "vehicle-tracking",
  components: {
    MenuItem,
    groupPanel,
    AnApprovadPanel,
    approveInput,
  },
  data() {
    return {
      isApproved: "noFilter",
      activeMenu: 1,
      totalWarningCount: 0,
      totalUnapprovedCount: 0,
      totalWontMakeIt: 0,
    };
  },
  methods: {
    handleFleet(val) {
      this.$emit("selected", val);
    },
    handleGroup(val) {
      this.$emit("selectedGroup", val);
    },
    getWarning() {
      let list = this.$parent.markers.filter((item) => {
        if (item.planning) {
          const distance =
            haversineDistance(
              item.position,
              item.stateId <= 3
                ? {
                    lat: parseFloat(item.outpointLat),
                    lng: parseFloat(item.outpointLng),
                  }
                : {
                    lat: parseFloat(item.targettLat),
                    lng: parseFloat(item.targettLng),
                  }
            ) / 1000;

          const hizDakika = 80 / 60;
          const tahminiSure = distance / hizDakika;

          var duration = moment.duration(
            moment(item.planning.targetDate).diff(
              moment().add(tahminiSure, "minutes")
            )
          );

          if (item.planning.state === 4 || item.planning.state > 5) {
            return false;
          }

          if (duration.asMinutes() <= 0) {
            return item;
          }
          if (item.planning.date <= 10 && item.planning.state == 1) {
            return item;
          }
        }
      });

      list = list.map((item) => {
        return {
          ...item,
          isUnApprovad: item.planning.date <= 10 && item.planning.state == 1,
        };
      });

      this.totalWarningCount = list.length;

      const unApprovedLength = list.filter(
        (item) => item.isUnApprovad === true
      ).length;

      this.totalUnapprovedCount = unApprovedLength;
      this.totalWontMakeIt = list.length - unApprovedLength;

      this.$emit("changeInterventionList", list);

      return list;
    },
  },
  created() {
    this.getWarning();
  },
  watch: {
    "$parent.markers"(val) {
      if (val.length > 0) this.getWarning();
    },
    activeMenu(val) {
      this.$emit("changeIntervention", val == 2);
    },
  },
};
</script>
