const moment = require("moment/moment");
const operationTypes = {
  normal: 1,
  shipEvacuation: 2,
  shipLoading: 3,
};

module.exports = class calculateBulkModel {
  // utils
  calculateTimeDifference(startTime, endTime) {
    // Create Date objects for today with the given start and end times
    var startDate = new Date(`1970-01-01T${startTime}:00`);
    var endDate = new Date(`1970-01-01T${endTime}:00`);

    // Calculate the difference in milliseconds
    var differenceInMilliseconds = endDate - startDate;

    // Convert milliseconds to minutes (1 minute = 60,000 milliseconds)
    var differenceInMinutes = differenceInMilliseconds / 60000;

    return differenceInMinutes;
  }

  // process functions

  // normal bulk function
  calculateNormalBulk(
    TransitionType,
    fillingDate,
    deliveryDate,
    roadMinutes,
    totalTime,
    totalTonnage,
    loadPerVehicle,
    maxDailyDrivingTime,
    orderDate
  ) {
    if (TransitionType == 1) {
      let errorMessage = "";
      let roundTripTime = Number(roadMinutes) * 2;
      roundTripTime += Number(fillingDate);
      roundTripTime += Number(deliveryDate);

      const unixOrderDate = new Date(orderDate).getTime();
      const currentDate = new Date();
      const unixCurrentDate = Date.now();
      const differentDate = parseInt(
        moment
          .duration(
            moment(new Date(totalTime)).diff(
              moment(unixOrderDate < unixCurrentDate ? currentDate : orderDate)
            )
          )
          .asMinutes()
          .toFixed(2)
      );

      // Bir aracın bir günde yapabileceği maksimum gidiş-dönüş sefer sayısı
      // const tripPerCount = Math.floor(maxDailyDrivingTime / roundTripTime);


      let tripsPerDay = maxDailyDrivingTime / roundTripTime;


      // if (tripPerCount == 0) {
      //   errorMessage = "Bir aracın operasyon tamamlama süresi 1 günden fazla";
      // }

      // Bir aracın bir günde taşıyabileceği maksimum tonaj
      const dailyLoadPerVehicle = tripsPerDay * loadPerVehicle;

      // Verilen süre içinde bir aracın taşıyabileceği maksimum tonaj
      let totalLoadPerVehicleWithinTimeLimit =
        dailyLoadPerVehicle * (differentDate / (24 * 60)); // availableTime, dakika cinsindendir

      // eğer aracın bir günde taşıyabileceği  yük 27 ton dan az ise otomatik 27 ton olarak atanır
      // sebebi tek yön gibi çalışması gerekir bu tarz günlerde
      if (totalLoadPerVehicleWithinTimeLimit < 27)
        totalLoadPerVehicleWithinTimeLimit = 26;


      // Toplam gereken araç sayısı
      let requiredVehicles =
        totalLoadPerVehicleWithinTimeLimit > 0
          ? totalTonnage / totalLoadPerVehicleWithinTimeLimit
          : 0;

      // Araç sayısını yuvarlama
      requiredVehicles = Math.round(requiredVehicles);
      // console.log("araç sayısı  yuvarlanmış hali:", requiredVehicles);

      return {
        requiredVehicles,
        errorMessage,
      };
    } else {
      // tek yön
      return {
        requiredVehicles: Math.round(totalTonnage / loadPerVehicle),
        errorMessage: "",
      };
    }
  }



  async calculateRecomendedCount(
    orderDate,
    TransitionType,
    roadMinutes,
    fillingDate,
    deliveryDate,
    totalTime,
    totalTonnage,
    loadPerVehicle,
    maxDailyDrivingTime,
    typeId,
    endDailyTime,
    startEvacationTime,
    horAtDeliveryTonnage,
    fillingPoint,
    token
  ) {
    if (typeId == operationTypes.normal) {
      const recomendedCount = this.calculateNormalBulk(
        TransitionType,
        fillingDate,
        deliveryDate,
        roadMinutes,
        totalTime,
        totalTonnage,
        loadPerVehicle,
        maxDailyDrivingTime,
        orderDate
      );
      return recomendedCount;
    } else if (typeId == operationTypes.shipEvacuation) {
      return await this.calculateShipEvacation(
        roadMinutes,
        totalTime,
        fillingDate,
        deliveryDate,
        totalTonnage,
        loadPerVehicle,
        maxDailyDrivingTime,
        endDailyTime,
        startEvacationTime,
        horAtDeliveryTonnage,
        fillingPoint,
        token
      );
    }
  }


    // ship evacation function
    calculateShipEvacation(
      roadMinutes,
      totalTime,
      fillingDate,
      deliveryDate,
      totalTonnage,
      loadPerVehicle,
      maxDailyDrivingTime,
      startEvacationTime,
      endDailyTime,
      horAtDeliveryTonnage,
      fillingPoint,
      token
    ) {
      if (Number(totalTonnage) < Number(horAtDeliveryTonnage)) {
        alert(
          "UYARI,SAAT BAŞI BOŞALTILACAK TONAJ DEĞERİ TOPLAM TONAJDAN BÜYÜK OLAMAZ!"
        );
        return;
      }
  
      // operation time
      let roundTripTime = Number(roadMinutes) * 2;
      roundTripTime += Number(fillingDate);
      roundTripTime += Number(deliveryDate);
  
      let list = [];
  
      // start day calculate
      const firstDayShipTime =
        this.calculateTimeDifference(startEvacationTime, endDailyTime) / 60;
  
      // saatte
      const hourAtTheFillingMaxVehicleCount = Math.ceil(
        horAtDeliveryTonnage / loadPerVehicle
      );
  
      const firstDayDiffrentVehicleCount = Math.ceil(
        firstDayShipTime * hourAtTheFillingMaxVehicleCount
      );
  
      const firstDayRecomendedVehicleCount =Math.ceil(roundTripTime / 60) * hourAtTheFillingMaxVehicleCount - 1;
  
      const firstDayTargetTonnage = firstDayDiffrentVehicleCount * loadPerVehicle;
      const transitionCount = Math.ceil(
        firstDayDiffrentVehicleCount / firstDayRecomendedVehicleCount
      );
  
  
      if (firstDayRecomendedVehicleCount > firstDayDiffrentVehicleCount) {
      } else {
        console.log("Araç güm içerisinde ", firstDayRecomendedVehicleCount);
      }
  
      list.push({
        name: "İlk Gün",
        dailyTonnage: firstDayTargetTonnage,
        vehicleCount: firstDayRecomendedVehicleCount,
        transitionCount,
      });
  
      // between day calculate
      const betweenStartAndEndDateDay =
        moment(totalTime).diff(moment(), "days") - 1;
  
      if (betweenStartAndEndDateDay > 0) {
        for (let index = 1; index <= betweenStartAndEndDateDay; index++) {
          const shipTime =
            this.calculateTimeDifference("00:00", endDailyTime) / 60;
          const betweenStartAndEndDateDayVehicleCount = Math.ceil(
            shipTime * hourAtTheFillingMaxVehicleCount
          );
          const recomendedbetweenStartAndEndDateDayVehicleCount = Math.ceil(
            (roundTripTime / 60) * hourAtTheFillingMaxVehicleCount - 1
          );
  
          const targetTonnage =
            betweenStartAndEndDateDayVehicleCount * loadPerVehicle;
          const transitionCount =
            betweenStartAndEndDateDayVehicleCount /
            recomendedbetweenStartAndEndDateDayVehicleCount;
  
          list.push({
            name: index + 1 + " . Gün",
            dailyTonnage: targetTonnage,
            transitionCount: transitionCount,
            vehicleCount: recomendedbetweenStartAndEndDateDayVehicleCount,
          });
        }
      }
  
      // last day calculate
  
      const lastDayTime =
        this.calculateTimeDifference("00:00", moment(totalTime).format("HH:mm")) /
        60;
  
      const lastDayDiffrentVehicleCount =
        this.calculateTimeDifference("00:00", endDailyTime) / 60;
  
      const lastDayRecomendedVehicleCount = Math.ceil(
        (lastDayTime / 60) * hourAtTheFillingMaxVehicleCount - 1
      );
      const lastDayTargetTonnage = lastDayDiffrentVehicleCount * loadPerVehicle;
      const lastDayTransitionCount =
        lastDayDiffrentVehicleCount / lastDayRecomendedVehicleCount;
  
      list.push({
        name: "Son Gün",
        dailyTonnage: lastDayTargetTonnage,
        vehicleCount: lastDayRecomendedVehicleCount,
        transitionCount: lastDayTransitionCount,
      });
  
      return list;
    }
};
