<template>
  <modal
    name="vehicleState-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
    @opened="show"
    style="margin-top: 40px"
  >
    <div class="items-center" style="z-index: 999999 !important">
      <div
        class="pb-2 pt-3 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
      >
        <h4 class="inline-block w-11/12 font-bold">Durum Geçmişi</h4>

        <button class="p-2 w-1/12" @click="hide">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <setTonnageAndKilometer
        :planId="planId"
        :rowId="selected.id"
        :typeId="typeId"
        :defaultKilometer="selected.kilometer"
        :defaultTonnage="selected.tonnage"
        :vehiclePlaque="Detail.vehicle"
        @refresh="() => getVehiceStatus()"
        :wayPointNo="this.wayPointNo"
      />
      <div class="w-full">
        <div
          class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead
              class="bg-white border-black border-dotted border-b-2 border-opacity-20"
            >
              <tr>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 40px !important"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 150px !important"
                >
                  Durum
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 200px !important"
                >
                  Kilometre/Tonaj
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 200px !important"
                >
                  Tarih
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  İrsaliye
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  Kantar Fişi
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  Diğer
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 50px !important"
                >
                  #
                </th>
              </tr>
            </thead>
            <tbody class="divide-y-2 divide-gray-500">
              <tr
                v-for="(item, index) in List"
                :key="index"
                :class="
                  item.isError == 1 && item.errorAnswered == 2
                    ? 'bg-red-500 animate-pulse cursor-pointer select-none '
                    : 'td-black'
                "
              >
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <span> {{ index + 1 }} </span>
                </td>
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <p>{{ item.name }}</p>
                </td>
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <p v-if="item.kilometer && item.tonnage">
                    {{ item.kilometer + "/" + item.tonnage }}
                  </p>
                </td>
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <p>
                    {{ formattedDate(item.addDate) }}
                  </p>
                </td>
                <td class="table-td border-r border-opacity-20 text-center">
                  <linkBtn
                    :filekey="item.fileUrl"
                    :containerClass="
                      item.isError == 1 && item.errorAnswered == 2
                        ? 'bg-white w-full'
                        : ''
                    "
                  />
                </td>
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <linkBtn
                    :filekey="item.fileTwoUrl"
                    :containerClass="
                      item.isError == 1 && item.errorAnswered == 2
                        ? 'bg-white w-full'
                        : ''
                    "
                  />
                </td>
                <td
                  @click="showErrorAlert(item)"
                  class="table-td border-r border-opacity-20"
                >
                  <linkBtn
                    :filekey="item.fileThreeUrl"
                    :containerClass="
                      item.isError == 1 && item.errorAnswered == 2
                        ? 'bg-white w-full'
                        : ''
                    "
                  />
                </td>
                <td class="table-td border-r border-opacity-20">
                  <button
                    v-if="
                      item.kilometer && item.tonnage
                      // && !(item.isError == 2 && item.errorAnswered == 2)
                    "
                    @click="editItem(item)"
                    class="mr-4"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <p v-else-if="item.stateId === 10">
                    {{ item.driver + " - " + item.vehicle }}
                  </p>

                  <button
                    v-if="item.isError == 1 && item.errorAnswered == 2"
                    @click="showErrorAlert(item)"
                  >
                    <i class="fas fa-exclamation-triangle"></i>
                  </button>

                  <button
                    class="text-red-500 w-6"
                    v-if="item.errorAnswered == 1"
                    @click="showDetail(item)"
                  >
                    <i class="fas fa-info-circle text-lg text-red-500"></i>
                  </button>
                </td>
              </tr>
              <tableLoader :load="load" :length="List.length" colspan="8" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
// networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import moment from "moment";

// local component
import setTonnageAndKilometer from "./setTonnageAndKilometer.vue";

//global components
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
import linkBtn from "@/components/devItem/link-btn.vue";

export default {
  components: {
    diffInput,
    tableLoader,
    moment,
    linkBtn,
    setTonnageAndKilometer,
  },
  name: "detail-table",
  props: ["Detail", "typeId"],
  data() {
    return {
      load: false,
      List: [],
      status: "",
      planId: "",
      selected: {},
      wayPointNo: "",
    };
  },
  methods: {
    hide() {
      this.$modal.hide("vehicleState-modal");
      this.$emit("refresh", true);
    },
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },

    show() {
      this.load = true;
      this.List = [];
      setTimeout(() => {
        this.getVehiceStatus();
      }, 50);
    },

    editItem(item) {
      let nameParts = item.name.split(".");
      this.wayPointNo = nameParts.length > 1 ? nameParts[0] : "0";
      this.planId = this.Detail.id;
      this.selected = item;
      this.$modal.show("edit-state-tonnage-and-kilometer-plan-modal");
    },
    getVehiceStatus() {
      this.List = [];
      this.load = true;
      axios
        .get(
          planning.getVehiceStatus +
            "?planId=" +
            this.Detail.id +
            "&typeId=" +
            this.typeId,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          const list = response.data.data;
          const totalWayPoints = this.Detail?.wayList?.length;
          let deliveryCounter = 1;
          let completedCounter = 1;
          this.List = list.map((item) => {
            if (item.stateId == 6 && deliveryCounter <= totalWayPoints) {
              item.name = deliveryCounter + ". Teslim Noktasına Ulaştı";
              deliveryCounter++;
            } else if (
              item.stateId == 8 &&
              completedCounter <= totalWayPoints
            ) {
              item.name = completedCounter + ". Teslim Noktası Tamamlandı";
              completedCounter++;
            } else if (
              item.stateId == 8 &&
              completedCounter == totalWayPoints + 1 &&
              totalWayPoints > 0
            ) {
              item.name = "Son Teslim Noktası Tamamlandı";
            } else if (item.stateId == 6 && totalWayPoints > 0) {
              item.name = "Son Teslim Noktasına Ulaştı";
            }
            return item;
          });
        })
        .catch((err) => {
          console.log(err);
          this.swalBox(
            "warning",
            "Uyarı !",
            err.response.data.message,
            false,
            this.$t("general.OkayTitle")
          );
          this.load = false;
        })
        .finally(() => {
          this.load = false;
        });
    },
    showErrorAlert(item) {
      if (!(item.isError == 1 && item.errorAnswered == 2)) return;
      this.swalBox(
        "warning",
        "UYARI!",
        "Bu teslim noktasına boşaltılan ürün miktarı  boşaltılması gereken  ürün miktarından farklı lütfen sebebini belirtin!",
        true,
        "Tamam",
        "İptal",
        true
      ).then((val) => {
        if (val.isConfirmed) {
          axios
            .post(
              planning.setStateWarning,
              {
                rowId: item.id,
                note: val.value.toString(),
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((result) => {
              const detail = result.data.data;
              this.getVehiceStatus();
              if (!detail.status) {
                this.swalBox(
                  "warning",
                  this.$t("general.warningTitle"),
                  result.data.message,
                  false,
                  this.$t("general.OkayTitle")
                );
              } else {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
              }
            })
            .catch((err) => {
              this.errorBox(err.response);
            })
            .finally(() => {
              this.load = false;
            });
        }
      });
    },
    showDetail(item) {
      this.swalBox(
        "warning",
        "UYARI!",
        moment(item.answeredDate).format("LLLL") +
          " Tarihinde bu ürün tonaj planlanan tonaj farkından dolayı Bırakılan Not : " +
          item.errorNote
      );
    },
  },
};
</script>

<style>
.modal-content {
  z-index: 9999999999999 !important;
}

.td-black {
  background-color: #3a3a3a !important;
}
</style>
