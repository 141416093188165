<template>
  <div
    class="w-full bg-white border-t border-b"
    v-if="$store.state?.activeTabs?.length > 0"
  >
    <div
      class="flex overflow-x-auto w-screen"
      style="width: 100% !important; max-width: 100%"
    >
      <draggable v-model="myList">
        <transition-group
          type="transition"
          class="flex items-center justify-between py-1"
        >
          <div
            class="pr-4 w-48 h-9 ml-4 flex items-center justify-between cursor-pointer select-none z-30"
            v-for="item in $store.state.activeTabs"
            :key="item.key"
            :class="
              item.url == $route.path
                ? 'border-b-0 border-t-0 border-l-0 border-r text-red-700 border-red-700  '
                : 'bg-white border-r  border-opacity-5 border-black'
            "
          >
            <button
              @click="goRoute(item)"
              class="text-sm pr-8 h-full w-11/12 block text-left"
            >
              {{ item.label }}
            </button>

            <button
              v-if="$store.state.activeTabs?.length > 1"
              class="w-1/12"
              @click="removeItem(item)"
            >
              <i class="fas fa-times text-sm"></i>
            </button>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "section-types",
  components: {
    draggable,
  },
  methods: {
    goRoute(item) {
      if (item.url != this.$route.path) this.$router.push(item.url);
    },
    removeItem(item) {
      const params = {
        item,
        currentRoute: this.$route.path,
      };
      this.$store.commit("removeTab", params);
      this.$store.commit("pages/removePage", { id: item.key });
    },
  },
  computed: {
    myList: {
      get() {
        return this.$store.state.activeTabs;
      },
      set(value) {
        this.$store.commit("updateActiveTabsList", value);
      },
    },
  },
};
</script>

<style></style>
