<template>
  <modal
    name="notes-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
    @opened="show"
    style="margin-top: 40px"
  >
    <div class="items-center" style="z-index: 999999 !important">
      <NoteModal
        :title="mode === 'add' ? 'Not Ekle' : 'Not Düzenle'"
        :note="selected ? selected.message : undefined"
        :on-submit="mode === 'add' ? addNote : editNote"
      />
      <div
        class="pb-2 pt-3 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
      >
        <h4 class="inline-block w-11/12 font-bold">Durum Geçmişi</h4>
        <button class="p-2 w-1/12" @click="$modal.hide('notes-modal')">
          <i class="fas fa-times text-black text-2xl"></i>
        </button>
      </div>
      <div class="w-full">
        <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
          <div class="w-full">
            <LyrausTable
              :data="List"
              :columns="columns"
              :isLoading="load"
              :alternateRowBg="['white', 'gray-100']"
              :totalCount="totalCount"
              :enablePagination="true"
              :pageCount="pageCount"
              :pageProp="currentPage"
              @update-current-page="handleCurrentPage"
              :batchOperations="true"
              :buttonsColumn="{ label: 'İşlem', width: 120 }"
              :paginationButtonColor="'red-600'"
              :paginationHoverColor="'red-700'"
              :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
              :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
              :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
            >
              <template v-slot:colButtons="{ item }">
                <div class="flex items-center justify-end px-2">
                  <button class="mr-2 relative" @click="showEditModal(item)">
                    <i class="fas fa-pen text-gray-700"></i>
                  </button>
                </div>

                <div class="flex items-center justify-end px-2">
                  <button class="mr-2" @click="handleDelete(item)">
                    <i class="fas fa-trash text-gray-700"></i>
                  </button>
                </div>
              </template>

              <template v-slot:batchOperations>
                <asyncBtn
                  icon="fas fa-plus "
                  text="Yeni Not"
                  class="w-36 btn"
                  @click="openAddModal"
                />
              </template>
            </LyrausTable>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";

// network
import { orderNotes } from "../../../networking/urlmanager";

//global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import NoteModal from "@/components/modals/addEditNoteModal/index.vue";

export default {
  components: {
    asyncBtn,
    NoteModal,
  },
  name: "notes-modal",
  props: ["orderId", "orderStatus", "isBulk"],
  data() {
    return {
      load: false,
      isLoading: false,
      selected: undefined,
      mode: "add",
      List: [],
      totalCount: 0,
      currentPage: 0,
      pageCount: 0,
      columns: [
        {
          key: "operatorName",
          label: "Operatör",
        },
        {
          key: "createDate",
          label: "Oluşturulma Zamanı",
          isDate: true,
        },
        {
          key: "orderStatus",
          label: "Sipariş Durumu",
        },
        {
          key: "message",
          label: "Not",
        },
      ],
    };
  },
  methods: {
    getAll() {
      const params = {
        page: this.currentPage,
        orderId: this.orderId,
      };
      axios
        .get(orderNotes.getAll, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    addNote(note) {
      this.isLoading = true;
      const params = {
        orderId: this.orderId,
        orderStatus: this.orderStatus,
        isBulk: 0,
        message: note,
      };
      axios
        .post(orderNotes.addNote, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla eklendi",
            false,
            "Tamam"
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
          this.getAll();
          this.$modal.hide("add-edit-note-modal");
        });
    },
    editNote(note) {
      this.isLoading = true;
      const params = {
        orderId: this.orderId,
        orderStatus: this.orderStatus,
        rowId: this.selected.id,
        isBulk: this.isBulk,
        message: note,
      };
      axios
        .post(orderNotes.editNote, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla düzenlendi",
            false,
            "Tamam"
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
          this.getAll();
          this.$modal.hide("add-edit-note-modal");
        });
    },
    deleteNote(item) {
      this.isLoading = true;
      const params = {
        rowId: item.id,
      };
      axios
        .post(orderNotes.deleteNote, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            "BAŞARILI",
            "Not başarıyla silindi",
            false,
            "Tamam"
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
          this.getAll();
          this.$modal.hide("add-edit-note-modal");
        });
    },
    handleDelete(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Notu silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.deleteNote(item);
        }
      });
    },

    showEditModal(item) {
      this.mode = "edit";
      this.selected = item;
      this.$modal.show("add-edit-note-modal");
    },
    hide() {
      this.$modal.hide("notes-modal");
      this.$emit("refresh", true);
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    openAddModal() {
      this.mode = "add";
      this.$modal.show("add-edit-note-modal");
    },
    show() {
      this.load = true;
      this.List = [];
      setTimeout(() => {
        this.getAll();
      }, 50);
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>

<style>
.modal-content {
  z-index: 9999999999999 !important;
}

.td-black {
  background-color: #3a3a3a !important;
}
</style>
