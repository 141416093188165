<template>
  <modal
    name="vehicle-list-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '78%'"
    @closed="$emit('refresh')"
  >
    <div
      class="pb-2 pr-4 pt-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="w-full flex items-center justify-center">
        <button
          @click="active = 1"
          class="w-5/12 py-2"
          :class="active == 1 ? 'border-b-2 border-black' : ''"
        >
          ARAÇ LİSTESİ
        </button>
        <button
          @click="active = 2"
          class="w-5/12 py-2"
          :class="active == 2 ? 'border-b-2 border-black' : ''"
        >
          HARİTA
        </button>
        <button
          @click="active = 3"
          class="w-5/12 py-2"
          :class="active == 3 ? 'border-b-2 border-black' : ''"
        >
          DURUM
        </button>

        <div class="w-2/12 text-right">
          <button class="pb-1 px-2" @click="$modal.hide('vehicle-list-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

      <!-- <h4 class="inline-block w-11/12">ARAÇ LİSTESİ</h4> -->
    </div>
    <locationDetail
      v-if="active == 2"
      :rowId="rowId"
      :activeAxios="activeAxios"
    />
    <actionDetail
      v-if="active == 3"
      :rowId="rowId"
      :activeAxios="activeAxios"
    />
    <vehicleList
      v-if="active == 1"
      :orderNo="orderNo"
      :rowId="rowId"
      :isDisabled="isDisabled"
      :isOrderStopped="isOrderStopped"
      :order="order"
      @refresh="() => getAll()"
    />
  </modal>
</template>

<script>
// import addModal from "./add.vue";

//Global Components
import locationDetail from "@/components/generate/map-location/index.vue";
import actionDetail from "@/components/generate/action-detail/index.vue";

//Local Components
import vehicleList from "./components/vehicleList.vue";

export default {
  props: ["rowId", "isDisabled", "orderNo", "order", "isOrderStopped"],
  components: {
    // addModal,
    vehicleList,
    locationDetail,
    actionDetail,
  },
  data() {
    return {
      active: 1,
      activeAxios: 2,
    };
  },
};
</script>
