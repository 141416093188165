<template>
  <div class="block px-2 text-black mt-2 relative z-50" ref="menu">
    <button @click="showModal()" class="flex py-1 items-center text-sm z-50">
      <!-- :class="activeState && !show ? 'text-red-700 font-bold' : 'text-black'" -->
      <i class="fas mr-2" v-if="!show" :class="icon"></i>
      <i v-if="show" class="fas fa-minus mr-2"></i>
      <span> {{ title }}</span>
    </button>
    <ul
      v-if="show && opened == id"
      class="absolute left-0 top-10 bg-white shadow-xl pt-2 rounded-b"
      style="width: 200px !important"
    >
      <li
        v-for="(item, index) in list"
        :key="index"
        class="ml-3 text-left px-2"
        :class="index != list.length - 1 ? 'border-b mb-1 pb-1' : 'mb-1 pb-1'"
      >
        <button
          @click="() => ($router.push(item.url), (show = false))"
          class="w-full text-left mt-1"
          :class="$route.path == item.url ? 'text-red-700 font-bold' : 'text-black'"
        >
          <i class="fas mr-2" :class="item.icon" v-if="!item.customIcon"></i>
          <img :src="item.icon" v-if="item.customIcon" class="h-4 mr-3" alt="" />
          <span class="text-xs"> {{ item.title }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "navbar-item",
  components: {},
  props: ["list", "title", "icon", "isMenu", "routeName", "id", "opened"],
  data() {
    return {
      show: false,
      activeState: false,
    };
  },

  methods: {
    showModal() {
      if (this.isMenu) {
        this.show = !this.show;
        this.$emit("changeOpened", this.id);
      } else {
        // this.$store.commit("addTab", {
        //   id: this.id,
        //   title: this.title,
        //   url: this.routeName,
        // });
        this.$emit("changeOpened", this.id);

        this.$router.push(this.routeName);
      }
    },

    showSubItem() {
      this.$store.commit(
        "addTab",
        this.list?.find((r) => r.url == this.$route.path)
      );
    },
    hideModal() {
      this.show = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.menu.contains(event.target)) {
        this.show = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.activeState = this.list?.find((r) => r.url == this.$route.path)
      ? true
      : this.$route.path == this.routeName;
  },
  watch: {
    opened(val) {
      if (val != this.id) {
        this.show = false;
      }
    },
    "$route.path"() {
      this.activeState = this.list?.find((r) => r.url == this.$route.path)
        ? true
        : this.$route.path == this.routeName;
    },
  },
};
</script>

<style scoped>
.active {
  border-left: 3px solid #fc0101 !important;
  color: rgb(255, 255, 255) !important;
}
</style>
