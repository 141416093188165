// const baseURL = "http://localhost:3000";
const baseURL = "https://api.global.route.siriusaitech.com";
// const baseURL = "https://test.api.router.siriusaitech.com";
// const baseURL = "http://35.208.134.237:1994";

export const routeEngine = {
  generate: baseURL + "/route/generate",
  getAvoidPoints: baseURL + "/route/get/avoid/points",
  getVehicleTypes: baseURL + "/route/get/vehicle/types",
};
