<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <addModal ref="addItem" @refresh="getAll()" />
    <editModal @refresh="getAll()" :detail="selected" />

    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
        <div class="w-full">
          <LyrausTable
            :data="List"
            :columns="columns"
            :enablePagination="true"
            :isLoading="load"
            :batchOperations="true"
            :searchBar="true"
            :searchProp="search"
            @update-search-value="(val) => handleSearchValue(val)"
            :handleSearch="handleSearch"
            :pageCount="pageCount"
            :pageProp="currentPage"
            @update-current-page="handleCurrentPage"
            :totalCount="totalCount"
            :alternateRowBg="['white', 'gray-100']"
            :rightExtraSlot="true"
            :buttonsColumn="{ label: 'İşlem' }"
            :paginationButtonColor="'red-600'"
            :paginationHoverColor="'red-700'"
            :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
            :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
            :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
          >
            <template v-slot:batchOperations>
              <asyncBtn
                icon="fas fa-plus "
                :text="$t('operations.products.newProduct')"
                class="w-36 btn"
                @click="$refs.addItem.show()"
              />
            </template>
            <template v-slot:colButtons="{ item }">
              <div class="flex items-center justify-end space-x-2 px-2">
                <button class=" mr-2" @click="showEditModal(item)">
                  <i class="fas fa-pen text-gray-700"></i>
                </button>
                <button class="" @click="deleteItem(item)">
                  <i class="fas fa-trash text-gray-700"></i>
                </button>
              </div>
            </template>
          </LyrausTable>
        </div>
    </div>
  </div>
</template>

<script>
// Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// Netoworking
import axios from "axios";
import { product } from "@/networking/urlmanager";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
export default {
  name: "product-module",
  components: {
    asyncBtn,
    addModal,
    editModal,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      columns: [
        {
          key: "name",
          label: "Ürün Adı",
        },
        {
          key: "typeName",
          label: "Ürün Türü",
        },
      ],
    };
  },
  methods: {
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-product-modal");
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
      this.getAll(true);
    },
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;

      this.List = [];
      this.load = true;
      axios
        .get(
          product.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Ürünü silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              product.delete,
              {
                rowId: item.id.toString(),
                name: item.name,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
