<template>
  <div class="overflow-y-auto h-full xl:pb-1 pb-4">
    <div
      class="shadow h-40 overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 270px !important"
            >
              Sipariş No
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Müşteri
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Miktar
            </th>
            <!-- <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 70px !important"
            >
              Başlangıç Tarihi / Teslim Tarihi
            </th>-->
            <th
              scope="col"
              class="table-th text-center border-r border-opacity-20"
              style="min-width: 80px !important"
            >
              #
            </th>
          </tr>
        </thead>
        <tbody
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <tr v-if="!List.length && !multiple">
            <td style="width: 350px !important">
              <div
                class="flex items-center justify-center px-2 py-1 border-r border-opacity-20"
              >
                <orderInput
                  :hideContainer="true"
                  :disabled="isLoading"
                  :fillingAndDelivery="fillingAndDelivery"
                  title="1"
                  v-model="orderId"
                  :isBulk="true"
                  @resetSelect="resetItem()"
                  @changeObject="
                    (val) => {
                      selected = val;
                    }
                  "
                />
              </div>
            </td>
            <td>
              <div
                class="flex items-center justify-start text-left text-xs px-3 py-1 text-white border-r border-opacity-20"
              >
                {{ selected.customer }}
              </div>
            </td>
            <td>
              <div
                class="flex items-center justify-center text-xs font-bold px-2 py-1 text-white border-r border-opacity-20"
              >
                {{ selected.tonnage }} TON
              </div>
            </td>
            <!-- <td >
            {{ selected.estimatedComplateDate }}
          </td> -->
            <td>
              <div class="flex items-center justify-center px-2 py-1">
                <button
                  type="button"
                  :disabled="load"
                  class="process-btn mr-2"
                  @click="save()"
                >
                  <i class="fas fa-check"></i>
                </button>

                <button
                  :disabled="load"
                  type="button"
                  class="process-btn"
                  @click="resetItem()"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>

          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td border-r border-opacity-20">
              <span># {{ item.orderNo }} </span>
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.customer }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.tonnage }} TON
            </td>

            <td class="">
              <div class="flex justify-center items-center">
                <button
                  type="button"
                  class="process-btn"
                  @click="deleteItem(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          <tableLoader :load="load" colspan="8" />
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
//npm
import moment from "moment";

//global components
import orderInput from "@/components/devItem/bulkPlanningInput.vue";
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "steptwoPage",
  props: ["fillingAndDelivery", "isLoading", "multiple"],
  components: {
    orderInput,
    tableLoader,
    diffInput,
  },
  data() {
    return {
      load: false,
      orderId: "",
      selected: {
        amount: "",
        deliveryDate: "",
        targetAdress: "",
        customer: "",
        kilometer: "",
        recomendedCount: "",
        fillingTime: "",
        deliveryTime: "",
      },

      List: [],
    };
  },

  methods: {
    deleteItem(index) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "SİPARİŞİ LİSTEDEN ÇIKARMAK İSTEDİĞİNİZE EMİN MİSİNİZ?",
        true,
        this.$t("general.confirmText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          if (index > -1 && index < this.List.length) {
            this.List.splice(index, 1); // Belirtilen indeksteki öğeyi listeden kaldırır
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "Sipariş başarılı bir şekilde kaldırıldı...",
              false,
              this.$t("general.OkayTitle")
            );
          } else {
            this.swalBox(
              "warning",
              "HATA",
              "Sipariş kaldırılırken  bir hata oluştu lütfen tekrar deneyin...",
              false,
              this.$t("general.OkayTitle")
            );
          }
        }
      });
    },
    async save() {
      if (!this.selected.orderNo) {
        this.swalBox(
          "warning",
          "HATA",
          "Lütfen Birleştirmek istediğiniz Siparişi Önce Seçiniz",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      if (this.selected.fillingTime == "" || this.selected.deliveryTime == "") {
        this.swalBox(
          "warning",
          "HATA",
          "Dolum ve teslim sürelerini giriniz...",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      this.List.push(this.selected);
      this.resetItem();
    },
    resetItem() {
      this.orderId = "";
      this.selected = {
        amount: "",
        deliveryDate: "",
      };
    },
  },
  watch: {
    List(val) {
      this.$emit("changeList", val);
    },
  },
  filters: {
    dateFilter(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>
