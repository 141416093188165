<template>
  <modal
    name="tally-details-modal"
    :height="isMobile ? '100%' : '90vh'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="false"
  >
    <!-- Fixed Header -->
    <div
      class="sticky top-0 z-10 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-600"
    >
      <div class="pb-2 pt-4 pr-4 pl-4 flex items-center justify-between">
        <h4 class="text-xl font-semibold text-gray-800 dark:text-gray-200">
          Puantaj Raporu
        </h4>
        <button
          type="button"
          class="px-3 text-lg hover:text-gray-600 transition-colors"
          @click="$modal.hide('tally-details-modal')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Scrollable Content Container -->
    <div v-else class="flex flex-col h-full">
      <!-- Fixed Summary Section -->
      <div class="sticky top-0 z-10 bg-white dark:bg-gray-900 px-4">
        <div class="my-4 bg-gray-50 rounded-lg p-4 dark:bg-gray-800">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div class="text-sm">
              <span class="text-gray-600 dark:text-gray-400"
                >Toplam Sipariş:</span
              >
              <span class="font-semibold ml-2">{{ detail.allOrders }}</span>
            </div>

            <div class="text-sm">
              <span class="text-gray-600 dark:text-gray-400"
                >ADR Siparişler:</span
              >
              <span class="font-semibold ml-2">{{ detail.adrOrders }}</span>
            </div>

            <div class="text-sm">
              <span class="text-gray-600 dark:text-gray-400">Tarih:</span>
              <span class="font-semibold ml-2">{{ getDateText }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Scrollable Tables Section -->
      <div class="flex-1 overflow-y-auto px-4 pb-4">
        <div v-for="(orders, date) in groupedOrders" :key="date" class="mb-8">
          <h2
            class="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4 sticky top-0 bg-white dark:bg-gray-900 py-2"
          >
            {{ formatDate(date) }}
            <span class="text-sm font-normal text-gray-500 ml-2">
              ({{ orders.length }} sipariş)
            </span>
          </h2>

          <LyrausTable
            :data="orders"
            :columns="columns"
            :enablePagination="false"
            :isLoading="isLoading"
            :alternateRowBg="['white', 'gray-100']"
            :tableHeight="`${orders.length * 4 + 4}vh`"
            :containerClass="'bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden'"
            :thClass="'py-2 text-left text-xs font-medium bg-gray-50 text-gray-500 tracking-wider border-gray-200'"
            :tdClass="'whitespace-nowrap py-2 border-gray-200 text-xs'"
            :tableContainerClass="'max-h-[400px] overflow-auto'"
          >
            <template v-slot:rowButtons="{ item, columnKey }">
              <template
                v-if="columnKey === 'isBulkOperation' || columnKey === 'isAdr'"
              >
                <span
                  class="px-2 py-1 rounded-full text-xs"
                  :class="
                    item[columnKey]
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                  "
                >
                  {{ item[columnKey] ? "Evet" : "Hayır" }}
                </span>
              </template>
            </template>
          </LyrausTable>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

import moment from "moment";
import { driverTally } from "../../../../networking/urlmanager";

export default {
  name: "tally-details-modal",
  props: ["detail", "startDate", "endDate"],

  data() {
    return {
      isLoading: false,
      List: [],
      columns: [
        {
          key: "orderNo",
          label: "Order No",
        },
        {
          key: "productType",
          label: "Ürün",
        },
        {
          key: "km",
          label: "KM",
        },
        {
          key: "tripDate",
          label: "Sefer Tarihi",
          isDate: true,
        },
        {
          key: "isBulkOperation",
          label: "Toplu Op.",
          dontDisplay: true,
        },
        {
          key: "isAdr",
          label: "ADR",
          dontDisplay: true,
        },
      ],
    };
  },

  computed: {
    groupedOrders() {
      return this.List.reduce((groups, order) => {
        const date = this.getDateOnly(order.tallyDate);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(order);
        return groups;
      }, {});
    },
    getDateText() {
      return moment(this.startDate).format("MMMM YYYY");
    },
  },

  methods: {
    async getDetails() {
      this.isLoading = true;

      const params = {
        startDate: moment(this.startDate)
          .utc()
          .format("YYYY-MM-DDT21:00:00.000[Z]"),
        endDate: moment(this.endDate)
          .utc()
          .format("YYYY-MM-DDT20:59:59.999[Z]"),
        tc: this.detail.tc,
      };
      console.log("params", params);

      try {
        const res = await axios.get(driverTally.getTallyDetail, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });
        this.List = res.data.data;
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.isLoading = false;
      }
    },

    getDateOnly(dateString) {
      return dateString.split("T")[0];
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("tr-TR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },

  mounted() {
    this.getDetails();
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  watch: {
    detail: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
};
</script>
