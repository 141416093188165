<template>
  <div class="w-full flex items-center justify-center">
    <button
      @click="activeMenu = 1"
      class="text-xs shadow-sm rounded-l p-2 w-6/12"
      :class="activeMenu == 1 && 'bg-red-500 text-white'"
    >
      GENEL
    </button>
    <button
      @click="activeMenu = 2"
      class="text-xs shadow-sm rounded-r p-2 w-6/12"
      :class="activeMenu == 2 && 'bg-red-500 text-white'"
    >
      MÜDAHALE({{ totalWarningCount }})
    </button>
  </div>
</template>

<script>
export default {
  name: "sidebar-menu",
  props: ["totalWarningCount", "value"],
  model: {
    event: "change",
    prop: "value",
  },
  data() {
    return {
      activeMenu: 1,
    };
  },
  created() {
    this.activeMenu = this.value;
  },
  watch: {
    value(val) {
      this.activeMenu = val;
    },
    activeMenu(val) {
      this.$emit("change", val);
    },
  },
};
</script>
