<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <LyrausTable
        :columns="columns"
        :data="List"
        :isLoading="load"
        :alternateRowBg="['white', 'gray-100']"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :batchOperations="true"
        :searchBar="true"
        @update-search-value="(val) => handleSearchValue(val)"
        :handleSearch="handleSearch"
      >
        <template v-slot:batchOperations>
          <form
            @submit.prevent="getAll"
            class="p-2 flex items-center justify-between bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
          >
            <div class="w-full">
              <diffInput
                v-model="startDate"
                :max="endDate"
                title="Başlangıç tarihi"
                type="date"
                :hideContainer="true"
              />
            </div>

            <div class="w-full mx-4">
              <diffInput
                v-model="endDate"
                :min="startDate"
                title="Bitiş Tarihi"
                type="date"
                :hideContainer="true"
              />
            </div>
            <div class="w-full">
              <asyncBtn
                icon="fas fa-search"
                text="Ara"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-32"
                size="py-2"
              />
            </div>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </form>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import axios from "axios";
import moment from "moment";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

import { driverHomeVisitReport } from "@/networking/urlmanager";

export default {
  name: "driverHomeVisitReport",
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      load: false,
      columns: [
        { label: "Ev Ziyaret Sayısı", key: "visitCount" },
        { label: "Sürücü İsmi", key: "driverName" },
        { label: "Sürücü Tc", key: "driverTc" },
      ],
      List: [],
      realList: [],
      startDate: "",
      search: "",
      endDate: "",
    };
  },
  methods: {
    getThisMonthsStartDate() {
      const startOfMonth = moment().startOf("month").utc().format("YYYY-MM-DD");
      return startOfMonth;
    },
    getThisMonthsEndDate() {
      const endOfMonth = moment().endOf("month").utc().format("YYYY-MM-DD");
      return endOfMonth;
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      const searchTerm = this.search.toLowerCase();
      const filteredList = this.realList.filter((item) =>
        item.vehicle_plate.toLowerCase().includes(searchTerm)
      );
      this.List = filteredList;
    },
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;
      this.List = [];
      this.load = true;
      const params = {
        startDate: moment(this.startDate)
          .utc()
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        endDate: moment(this.endDate)
          .utc()
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      };
      axios
        .get(driverHomeVisitReport.getAll, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },

    async excelExport() {
      const workbook = XLSX.utils.book_new();

      const headers = {
        visitCount: "Ev Ziyaret Sayısı",
        driverName: "Sürücü İsmi",
        driverTc: "Sürücü Tc",
      };

      const detail = [
        Object.values(headers), // Başlıklar
        ...this.List.map((item) => {
          return Object.keys(headers).map((key) => {
            if (key === "startDate") return this.startDate;
            if (key === "endDate") return this.endDate;
            return item[key] || "";
          });
        }), // Veriler
      ];

      // Verileri worksheet'e dönüştür
      const worksheet = XLSX.utils.json_to_sheet(detail);

      XLSX.utils.sheet_add_aoa(worksheet, [["", "", ""]], { origin: "A1" });

      // Worksheet'i workbook'a ekle
      XLSX.utils.book_append_sheet(workbook, worksheet, "kilometerRaports");

      // Excel dosyasını oluştur
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Dosyayı kaydet
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(data, "vehicle-kilometer.xlsx");
    },
  },
  created() {
    this.startDate = this.getThisMonthsStartDate();
    this.endDate = this.getThisMonthsEndDate();

    this.getAll();
  },
};
</script>
