<template>
  <div
    class="p-4 bg-white dark:bg-gray-900 overflow-y-auto pb-14 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    style="height: 67vh !important"
  >
    <div v-if="!load">
      <div v-if="detail?.totalAmount !== undefined">
        <p class="border-b pb-2 px-2 mb-5 font-bold">GENEL</p>

        <div class="mt-5">
          <div class="w-full">
            <diffInput
              :value="detail?.totalAmount + ' TON'"
              title="Toplam Taşınması Gereken Miktar"
              type="text"
              disabled="true"
            />
          </div>
          <div class="w-full mt-5">
            <diffInput
              :value="detail?.totalShipAmount + ' TON'"
              title="Toplam Taşınan Miktar"
              type="text"
              disabled="true"
            />
          </div>
          <div class="w-full mt-5">
            <diffInput
              :value="detail?.remainingAmount + ' TON'"
              title="Toplam Kalan Miktar"
              type="text"
              disabled="true"
            />
          </div>
        </div>

        <p class="border-b pb-2 px-2 mb-5 mt-5 font-bold">SAATLİK</p>
        <div class="">
          <div class="w-full">
            <diffInput
              :value="
                detail?.daily?.perDayEstimatedAmount == null ||  detail?.daily?.perDayEstimatedAmount == 0
                  ? this.showAlert()
                  : detail?.daily?.perDayEstimatedAmount + ' TON'
              "
              title="Hedeflenen Taşıma Miktarı"
              type="text"
              disabled="true"
            />
          </div>
          <div class="w-full mt-5">
            <diffInput
              :value="
                detail?.daily?.perDayEstimatedAmount
                  ? detail?.daily?.dailyShipmentAmount + ' TON'
                  : '-'
              "
              title="Gerçekleşen Taşıma Miktarı"
              type="text"
              disabled="true"
            />
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col items-center justify-center h-64 px-40">
        <i class="fas fa-spinner fa-spin fa-3x mb-10"></i>
        <p class="text-center">
          Otomatik görevlendirme sürüyor. Filo içerisindeki araçlar
          görevlendirildikten sonra rapor otomatik üretilecektir.<br />
          Bu işlem 1-5 dakika kadar sürebilir.
        </p>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full">
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>
  </div>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//networking
import axios from "axios";
import { bulkShipPlanning, bulkPlanning } from "@/networking/urlmanager";
export default {
  name: "tonnage-detail-report-modal",
  props: ["rowId", "activeAxios"],
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      load: false,
      detail: {
        totalAmount: undefined,
        totalShipAmount: 0,
        remainingAmount: 0,
        daily: {
          perDayEstimatedAmount: 0,
          dailyShipmentAmount: 0,
        },
      },
    };
  },
  methods: {
    showAlert() {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Siparişin Tahmini Teslim Tarihi 1 saatin altında olduğundan Hedeflenen Taşıma Miktarı oluşturulamamaktadır !"
      );
      return "-";
    },
    getDetail() {
      this.load = true;
      const endpoint =
        this.activeAxios === 2
          ? bulkShipPlanning.getTonnageContoller + "?shipId=" + this.rowId
          : bulkPlanning.getTonnageContoller + "?bulkId=" + this.rowId;

      axios
        .get(endpoint, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.detail = result.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
  },
};
</script>

<style></style>