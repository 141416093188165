<template>
  <modal
    name="storage-process-modal"
    :height="isMobile ? '100%' : '100%'"
    :width="isMobile ? '100%' : '60%'"
    @closed="$emit('refresh')"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">TAHLİYEYİ YENİDEN BAŞLAT</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('storage-process-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="py-4 mx-4">
      <diffInput
        type="number"
        v-model="recomendedVehicleCount"
        :disabled="load || ordersLoading"
        :required="true"
        title="Araç Sayısı"
        :step="1"
        :min="0"
        :max="1000"
      />

      <bulkVehicle
        v-if="recomendedVehicleCount"
        :totalCount="String(recomendedVehicleCount)"
        @removeVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) - 1)
        "
        @addVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) + 1)
        "
        @change="(r) => (vehicleList = r)"
        :recomendedCount="orderList[0].recomendedCount"
        :deliveryDate="orderList[0].complatedDate"
        :center="calculateCenter()"
        :order="orderList[0]"
        :hiddenInsert="false"
        :rowId="rowId"
        network="ship"
        :isLoading="load || ordersLoading"
      />

      <div class="w-full mt-4 text-right">
        <asyncBtn
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load || ordersLoading"
          class="w-full"
          @click="changeProcess()"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import bulkVehicle from "@/components/general/vehicleTable/vehicles.vue";

//Axios
import { bulkShipPlanning } from "@/networking/urlmanager";
import { getLocationAll } from "@/utils/detailData";

import calculatorModel from "@/utils/vehecleCountRecomendation.js";

import axios from "axios";
export default {
  name: "storage-process-modal",
  props: ["rowId", "orderNo", "capacity", "orderItem"],
  components: {
    diffInput,
    asyncBtn,
    bulkVehicle,
  },
  data() {
    return {
      load: false,
      ordersLoading: false,
      vehicleList: [],
      orderList: [],
      recomendedCount: 0,
      recomendedVehicleCount: 0,
      vehicleLoad: false,
      vehicle: "",
      driverName: "",
      driverTC: "",
      longitude: "",
      latitude: "",
    };
  },
  methods: {
    async calculateTotalTime() {
      this.orderList.forEach(async (item) => {
        const startDate = moment().format("YYYY-MM-DDTHH:mm");
        const receivedTonnage = item.receivedTonnage ?? 0;
        const totalTonnage = item.tonnage - receivedTonnage;

        let { requiredVehicles, errorMessage } =
          await new calculatorModel().calculateRecomendedCount(
            startDate,
            1,
            item.totalMinutes,
            this.orderItem.estimatedFillingTime,
            this.orderItem.estimatedDeliveryTime,
            item.estimatedComplateDate,
            totalTonnage,
            26,
            540,
            1
          );
        this.recomendedError = errorMessage;

        this.recomendedCount += requiredVehicles;
        this.recomendedVehicleCount += requiredVehicles;
      });
    },
    getAll() {
      this.orderList = [];
      this.ordersLoading = true;
      axios
        .get(
          bulkShipPlanning.orderdersgetAll +
            "?shipId=" +
            this.orderItem.id +
            "?isAll=" +
            true,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.orderList = res.data.data.detail;
          this.calculateTotalTime();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.ordersLoading = false;
        });
    },
    calculateCenter() {
      return {
        lat:
          (this.orderItem.fillingLatitude + this.orderItem.deliveryLatitude) /
          2,
        lng:
          (this.orderItem.fillingLongitude + this.orderItem.deliveryLongitude) /
          2,
      };
    },
    bulkSave() {
      const invalidVehicles = this.vehicleList?.filter((item) => {
        return !(item.driverName && item.driverTC);
      });

      if (invalidVehicles?.length > 0) {
        const vehicles = invalidVehicles.map((item) => item.vehicle).join(", ");
        this.swalBox(
          "warning",
          "Uyarı!",
          `Planlama yapılamaz !!! Aşağıdaki ${
            invalidVehicles.length > 1 ? "araçlar" : "araç"
          } için sürücü bilgisi veya TC kimlik bilgisi eksik:${vehicles} Plakalı ${
            invalidVehicles.length > 1 ? "araçlara" : "araca"
          } Lütfen sürücü atayınız veya mevcut sürücünün TC kimlik bilgisini giriniz.`
        );
        return;
      }

      this.load = true;
      axios
        .post(
          bulkShipPlanning.vehicleAdd,
          {
            shipId: this.rowId,
            vehicleList: this.vehicleList.map((item) => {
              return {
                vehicle: item.vehicle,
                plate: item.vehicle,
                dorse: item.dorse,
                driverName: item.driverName,
                isCustom: item.isCustom || 1,
                driverTC: item.driverTC,
                capacity: item.capacity,
                longitude: item.longitude,
                latitude: item.latitude,
                isWashing: item.recomendation?.isWashing || 3,
              };
            }),
            rowId: this.rowId,
            orderNo: this.orderNo,
            shipId: this.rowId,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.$modal.hide("storage-process-modal");
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async changeProcess() {
      const params = {
        typeId: 2,
        shipId: this.rowId,
      };
      axios
        .post(bulkShipPlanning.process, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.bulkSave();
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          capacity: item.capacity,
          longitude: item.longitude,
          latitude: item.latitude,
          orderNos: item.orderNos, //ORDERNOS EKLENDİ
        };
      });
    },
  },
  async created() {
    this.vehicleLoad = true;
    this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    this.vehicleLoad = false;
  },
  watch: {
    rowId() {
      this.vehicleList = [];
      this.orderList = [];
      this.recomendedCount = 0;
      this.recomendedVehicleCount = 0;
      this.getAll();
    },
  },
};
</script>
