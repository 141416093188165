<template>
  <div>
    <button
      class="h-11 w-14 bg-white shadow ml-2 mr-2 rounded-lg justify-center items-center flex"
      @mousedown.stop="toggleUserFilter()"
    >
      <div v-if="!userFilter" class="flex gap-2">
        <img
          class="w-5 h-5 mt-1"
          src="@/assets/planing/filteredit.svg"
          alt=""
        />
      </div>
      <div v-if="userFilter" class="text-red-700 font-bold text-xl">
        <i class="fas fa-times"></i>
      </div>
    </button>
    <div
      style="width: 24%"
      @mousedown.stop="userFilter = true"
      :class="{
        'invisible opacity-0 transition-all ease-in duration-100 ': !userFilter,
      }"
      class="h-40 overflow-y-scroll z-50 right-6 rounded mt-40 text-xs absolute bg-white top-7 border border-gray-300 text-black shadow-md"
    >
      <div>
        <span
          v-if="userLoad || load"
          class="w-full h-40 flex items-center justify-center"
        >
          <i class="fas fa-spinner fa-spin text-2xl font-bold px-2"></i>
          Yükleniyor...</span
        >
        <div class="h-24" v-if="!userLoad && !load">
          <div
            v-for="(item, index) in sortUserList(userList)"
            :key="index"
            class="border-b border-gray-100"
          >
            <label
              class="w-full py-2 hover:bg-red-600 hover:text-white text-left px-2 cursor-pointer flex"
            >
              <input
                type="checkbox"
                @change="() => updateSelection(item)"
                class="mr-3"
                :checked="selectedItems.includes(item.id)"
              />
              <p>{{ item.namesurname }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";

export default {
  name: "userFllter",
  props: ["load", "selectedItems"],
  data() {
    return {
      userList: [],
      userFilter: false,
      userLoad: false,
    };
  },
  methods: {
    show() {
      this.userFilter = false;
    },
    toggleUserFilter() {
      this.userFilter = !this.userFilter;
    },
    sortUserList(userList) {
      const newUserList = [];
      const remainingUsers = [];
      userList.forEach((item) => {
        if (this.selectedItems.includes(item.id)) {
          newUserList.push(item);
        } else {
          remainingUsers.push(item);
        }
      });
      const sortedList = newUserList.concat(remainingUsers);
      return sortedList;
    },
    userGetAll() {
      this.userList = [];
      this.userLoad = true;
      axios
        .get(managementUser.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          if (res.data.data.detail.length > 0) {
            this.userList = res.data.data.detail;
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.userLoad = false;
        });
    },
    updateSelection(item) {
      const selectedUsers = this.selectedItems;
      if (!selectedUsers.includes(item.id)) {
        selectedUsers.push(item.id);
      } else {
        const index = selectedUsers.indexOf(item.id);
        if (index !== -1) {
          selectedUsers.splice(index, 1);
        }
      }
      this.$emit("selected-items-changed", selectedUsers);
    },
  },
  created() {
    this.userGetAll();
  },
};
</script>
