const similarity = require("similarity");
const isAdr = 2;

const moment = require("moment");
const { calculator } = require("./cost");

// let blockedVehicles = []

const washingStatus = {
  yes: 1,
  no: 2,
  driverAction: 3,
};

const loadPerVehicle = 26;
/**
 * Araç kullanılabilirliğini günceller.
 * @param {number} vehicleId - Güncellenecek aracın ID'si.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {boolean} isAvailable - Araç için kullanılabilirlik durumu.
 */

function updateVehicleAvailability(topVehicles, vehicles, order) {
  topVehicles.forEach((vehicle) => {
    const vehicleIndex = vehicles.findIndex((v) => v.id === vehicle.id);
    if (vehicleIndex !== -1) {
      vehicles[vehicleIndex].orderNos.push(order.orderNo);
    }
  });
}

/**
 * Haversine formülünü kullanarak iki koordinat arasındaki mesafeyi hesaplar.
 * @param {Object} coords1 - İlk koordinat (lat, lng şeklinde).
 * @param {Object} coords2 - İkinci koordinat (lat, lng şeklinde).
 * @returns {number} İki koordinat arasındaki mesafe (Kilometre cinsinden).
 */
function haversineDistance(coords1, coords2) {
  const R = 6371e3;
  const lat1Rad = (Math.PI * coords1.lat) / 180;
  const lat2Rad = (Math.PI * coords2.lat) / 180;
  const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
  const deltaLon = ((coords2.lng - coords1.lng) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return (R * c) / 1000;
}

/**
 * Sipariş ve araç için uygunluk kontrolü yapar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {boolean} Araçın sipariş için uygun olup olmadığı bilgisi.
 */

function isWashStatus(customers, products, vehicle, order) {
  const customer = customers.find(
    (c) => similarity(c.name, order.customer) > 0.85
  );

  let scoreTypes = {
    status: 0,
    score: 0,
  };
  if (customer?.requiredWashingStatus == washingStatus.yes) {
    // müşteri yıkamayı zorunlu tutmuş
    // if(vehicle.vehicle=="68KK566" || vehicle.name=="68KK566"){
    //   console.log("girdi 1")
    // }
    scoreTypes.score = 1;
    scoreTypes.status = washingStatus.yes;
  } else {
    if (vehicle?.lastProduct) {
      if (vehicle.lastProduct == (order?.productType || order?.productName)) {
        // ürün aynı ise yıkamaya gerek yok
        scoreTypes.score = 1;
        scoreTypes.status = washingStatus.no;
      } else {
        const findProduct = products.find((pValue) => {
          return (
            similarity(
              pValue?.name,
              order?.productName ? order?.productName : order?.productType
            ) >= 0.9
          );
        });

        // aracın en son taşıdığı yük ile mevcut ürünün birlikte yıkama yapmadan taşınabileceği ürünler kontrol edilir
        if (
          findProduct?.movableProducs?.some(
            (r) => similarity(r.name, vehicle.lastProduct) > 0.8
          )
        ) {
          // aracı yıkamaya gerek yok
          scoreTypes.score = 0.5;
          scoreTypes.status = washingStatus.no;
        } else {
          scoreTypes.score = 1;
          scoreTypes.status = washingStatus.yes;
        }
      }
    } else {
      scoreTypes.score = 1;
      scoreTypes.status = washingStatus.driverAction;
      // son ürün yok ise sürücüye sormalısın
    }
  }
  return scoreTypes;
}

function isSequential(vehicle, order) {
  let betweenPlanningDistance = 0;
  let orderDistance = 0;
  let hourDiff = 0;

  if (vehicle?.planning?.taskCounter == 1) {
    betweenPlanningDistance = calculateDistancePriority(
      {
        startLat: Number(vehicle.targettLat),
        startLng: Number(vehicle.targettLng),
      },
      {
        latitude: Number(order.startLat),
        longitude: Number(order.startLng),
      }
    );

    orderDistance = calculateDistancePriority(
      {
        startLat: Number(order.startLat),
        startLng: Number(order.startLng),
      },
      {
        latitude: Number(order.endLat),
        longitude: Number(order.endLng),
      }
    );

    hourDiff = moment(vehicle?.planning?.deliveryDate).diff(
      moment(order.deliveryDate),
      "minutes"
    );

    if (vehicle?.vehicle == "27ATF604" || vehicle?.name == "27ATF604") {
    }
  }

  if (!Array.isArray(vehicle?.orderNos)) {
    vehicle.orderNos = [];
  }

  if (vehicle?.orderNos?.length > 1) {
    orderCheck =
      vehicle?.orderNos?.filter((r) => r != order.orderNo).length > 0;
  }

  return (
    vehicle?.orderNos.length == 0 ||
    (vehicle?.planning?.taskCounter == 1 &&
      vehicle?.orderNos?.filter((r) => r != order.orderNo).length == 0) ||
    (300 - betweenPlanningDistance >= 0 && // 30 km uzaklığa göre işi bitmek üzere olan araçlar gelebilir.
      hourDiff + (60 + 60) < // 1. 60 dk ortalama tahliye süresi ve 2. 60 dk garantiye alma sürem.
        (orderDistance / 60) * 60)
  );
  // Araç üzerinde bir spot iş olabilir.

  //Siparişin teslim tarihinin diğer işin teslim tarihi farkı,
  //Bana lazım olan hedeflenen süreden küçük olmalı.
  //Ve bunu dakika cinsinden hesaplıyoruz.
}
/**
 * ---------------
 * Belirli bir aracın, verilen bir siparişi alıp alamayacağını kontrol eder. Örneğin, aracın 18 Nisan'da
 * bir iş aldığını ve 19 Nisan'da 12:30'da teslim edeceğini varsayalım. Bu işlev, 19 Nisan 12:30'dan sonraki
 * siparişler için aracın mevcut konumunu ve sonraki siparişlerin teslim noktalarını kontrol ederek, aracı uygun
 * hale getirir (örneğin, zincirleme siparişler gibi).
 * ---------------
 * @param {Object} vehicle - Kontrol edilecek araç objesi.
 * @param {Object} order - Kontrol edilecek sipariş objesi.
 * @returns {boolean} - Eğer araç belirli bir siparişi alabilirse true, aksi halde false döner.
 */

function isVehicleSuitableForOrderDetail(
  order,
  vehicle,
  products,
  isBulk = false,
  skipTheTonnage = false,
  isClose = false
) {
  let capacity = vehicle?.capacity / 1000;
  if (capacity >= 26 && capacity <= 30) {
    capacity = 30.5;
  }
  let isTonnageSuitable = order.tonnage <= capacity || isBulk || skipTheTonnage;
  const isVehicleAvailable =
    (Array.isArray(vehicle?.orderNos)
      ? vehicle.orderNos.filter((r) => r != order.orderNo).length == 0
      : true) ||
    // (vehicle?.planning?.isStopped == 1 && isSequential(vehicle, order));
    vehicle?.planning?.isStopped == 1 ||
    isSequential(vehicle, order);

  const findProduct = products.find((pValue) => {
    const orderProduct = order?.productName
      ? order?.productName
      : order?.productType;
    return (
      similarity(
        pValue?.name?.toString().toLocaleLowerCase().replace(/\s/g, ""),
        orderProduct?.toString().toLocaleLowerCase().replace(/\s/g, "")
      ) >= 0.8
    );
  });
  if (!findProduct) {
    console.log("ürün uygun değil");
    return {
      isTonnageSuitable: false,
      isVehicleAvailable: false,
      isInspectionAvailable: false,
      dorseAvailable: false,
      isAdrAvailable: false,
      isADRCompatible: false,
      isLastproduct: false,
    }; // Ürün bulunamazsa, araç uygun değildir.
  }

  // Ürünün ADR'li olup olmadığını kontrol et
  //Muayenesi bitmiş araçların ve siparişin teslim tarihinden önce bitecek araçları false döndürüyoruz.
  const vehicleIsRent = vehicle.haveType ? vehicle.haveType : vehicle.isRent;

  let isInspectionAvailable =
    moment(vehicle.inspectionEndDate).diff(moment(), "days") > 0 &&
    moment(vehicle.inspectionEndDate).diff(
      moment(order?.deliveryDate),
      "days"
    ) > 0 &&
    (vehicleIsRent == 1
      ? true
      : moment(vehicle.vehicleInspectionEndDate).diff(moment(), "days") > 0 &&
        moment(vehicle.vehicleInspectionEndDate).diff(
          moment(order?.deliveryDate),
          "days"
        ) > 0)
      ? true
      : false;

  let isAdrAvailable =
    moment(vehicle.vehicleAdrEndDate).diff(moment(), "days") > 0 &&
    moment(vehicle.vehicleAdrEndDate).diff(
      moment(order?.deliveryDate),
      "days"
    ) > 0 &&
    (vehicleIsRent == 1
      ? true
      : moment(vehicle.dorseAdrEndDate).diff(moment(), "days") > 0 &&
        moment(vehicle.dorseAdrEndDate).diff(
          moment(order?.deliveryDate),
          "days"
        ) > 0)
      ? true
      : false;
  let isSrcAvailable =
    vehicleIsRent == 1
      ? true
      : moment(vehicle.srcEndTime).diff(moment(), "days") > 0 &&
        moment(vehicle.srcEndTime).diff(moment(order?.deliveryDate), "days") > 0
      ? true
      : false;

  const productRequiresADR = findProduct.typeId === isAdr;
  const ADRVehicle = vehicle?.transportPurpose?.includes("TEHLİKELİ");

  // Eğer Tehlikeli ürün değilse adr uygunluğuna bakmasın
  isAdrAvailable = productRequiresADR ? isAdrAvailable : true;

  let haveTypeAvailable = true;

  if (order.haveType) {
    // kiralık
    haveTypeAvailable = order.haveType == vehicle.haveType;

    // 1 ise kiralık
    // 2 ise özmal olmalı
  }

  if (order.haveType == "2") {
    // özmal araç önermesi koşulu eklenmeli
  }

  // eğer araç ihtiyacı kırk ayak ise buradaki öneri yapısı çalışarak uygun arabaları döndürür
  if (order.tonnage <= 20 && isClose) {
    const isTonnageSuitable =
      vehicle.capacity >= 18000 && vehicle.capacity <= 22000;

    const productName = order?.productName
      ? order?.productName
      : order?.productType;

    return {
      haveTypeAvailable: true, //kırkayak engel olmasın diye true olarak eklettim
      isTonnageSuitable,
      isVehicleAvailable,
      dorseAvailable: true,
      isADRCompatible:
        !productRequiresADR || (productRequiresADR && ADRVehicle),
      isInspectionAvailable,
      isAdrAvailable,
      isSrcAvailable,
      lastProductAvailable: !vehicle?.lastProduct
        ? 3
        : similarity(vehicle?.lastProduct, productName) > 0.8
        ? 1
        : 2,
    };
  }

  //  Araçta bu dorse türü olup olmadığını kontrol et
  const findDorseType = findProduct.dorseTypes.filter((find) => {
    if (typeof find.name == "string" && typeof vehicle.romorkType == "string") {
      if (
        similarity(
          find?.name.toLowerCase().replace(/\s/g, ""),
          vehicle?.romorkType?.toLowerCase().replace(/\s/g, "")
        ) > 0.8
      ) {
        // console.log("Benzer dorse:",find,"romork:",vehicle.romorkType);
        return find;
      }
    }
  });

  // const allADRDorse = findDorseType.some((dorse) => dorse.name.includes("ADR"));

  const allADRDorse = vehicle?.romorkType?.includes("ADR");

  const productName = order?.productName
    ? order?.productName
    : order?.productType;

  const isADRCompatible =
    !productRequiresADR || (productRequiresADR && allADRDorse && ADRVehicle);

  // if (vehicle.name =='27ANM001') {
  //   console.log({
  //     isTonnageSuitable,
  //     isVehicleAvailable,
  //     haveTypeAvailable,
  //     dorseAvailable: findDorseType.length > 0,
  //     findDorseType:findDorseType,
  //     isADRCompatible,
  //     isSrcAvailable,
  //     isInspectionAvailable,
  //     isAdrAvailable,
  //     vehicle
  //   });
  // }

  return {
    isTonnageSuitable,
    isVehicleAvailable,
    haveTypeAvailable,
    dorseAvailable: findDorseType.length > 0,
    isADRCompatible,
    isSrcAvailable,
    isInspectionAvailable,
    isAdrAvailable,
    lastProductAvailable: !vehicle?.lastProduct
      ? 3
      : similarity(vehicle?.lastProduct, productName) > 0.8
      ? 1
      : 2,
  };
}
function isVehicleSuitableForOrder(
  order,
  vehicle,
  products,
  isBulk = false,
  skipTheTonnage = false,
  isClose = false
) {
  const {
    isTonnageSuitable,
    isVehicleAvailable,
    dorseAvailable,
    isADRCompatible,
    isSrcAvailable,
    isInspectionAvailable,
    isAdrAvailable,
    haveTypeAvailable,
  } = isVehicleSuitableForOrderDetail(
    order,
    vehicle,
    products,
    isBulk,
    skipTheTonnage,
    isClose
  );
  return (
    isTonnageSuitable &&
    isVehicleAvailable &&
    dorseAvailable &&
    isADRCompatible &&
    isSrcAvailable &&
    isInspectionAvailable &&
    isAdrAvailable &&
    haveTypeAvailable
  );
}

/**
 * Sipariş ve araç arasındaki mesafeyi hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @returns {number} Sipariş ve araç arasındaki mesafe (metre cinsinden).
 */
function calculateDistancePriority(order, vehicle) {
  const fromLocation =
    vehicle?.orderNos?.length && !vehicle.orderNos.includes(order.orderNo)
      ? {
          lat: parseFloat(vehicle.planning.targetPointLat),
          lng: parseFloat(vehicle.planning.targetPointLng),
        }
      : {
          lat: parseFloat(vehicle.latitude),
          lng: parseFloat(vehicle.longitude),
        };
  const distance = haversineDistance(
    { lat: parseFloat(order.startLat), lng: parseFloat(order.startLng) },
    fromLocation
  );
  return distance;
}

/**
 * Müşteri önceliğini hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {number} Müşteri önceliği.
 */
function calculateCustomerPriority(order, customers) {
  const customer = customers.find(
    (c) => similarity(c.name, order.customer) > 0.8
  );
  return customer ? customer.priority : 1;
}

function calculateProductTypePriority(order, vehicle) {
  const productName = order?.productName
    ? order?.productName
    : order?.productType;

  const lastProductVehicle =
    similarity(vehicle?.lastProduct, productName) > 0.8;
  return lastProductVehicle ? 0.9 : 1;
}

/**
 * Tek bir araç için öneri oluşturur.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {Object|null} Önerilen araç veya null.
 */

function recommendSingleVehicleForOrder(
  order,
  vehicles,
  customers,
  products,
  totalCount,
  isBulk = false,
  skipTheTonnage = false,
  setAvailable = true,
  blockedVehicles = [],
  isClose = false
) {
  // console.log("order", order);
  // console.log("vehicles", vehicles);
  // console.log("customers", customers);
  // console.log(vehicles.filter(r=>r.name='01AES909' && r.dorse=='01 CSN 62'));
  const isSuitable = (vehicle) => {
    return isVehicleSuitableForOrder(
      order,
      vehicle,
      products,
      isBulk,
      skipTheTonnage,
      isClose // kırkayak icin true gönderilmesi lazım false gönderilir ise tüm araçlar önerilir.
    );
  };
  let suitableVehicles = vehicles?.filter(isSuitable); // Kırkayaklar..
  // Eğer taşıancak miktar 20 ton altındaysa suitableVehicles'ta KırkAayaklı araçlar olacaktır.
  if (order.tonnage <= 20) {
    let areaSensitivity = 200.0;
    // Kırkayaklı araçlardan siparişe en yakın olan aracın mesafesi alındı lowestDistance değişkeninde
    suitableVehicles.filter((vehicle, index) => {
      const distancePriority = calculateDistancePriority(order, vehicle);
      return distancePriority < areaSensitivity; // 200 km üzerinde uzaklıkta ise kırkayak, listeden cıkarıyoruz.
    });
  }
  if (suitableVehicles.length === 0) {
    console.log("uygun araç yok");
    return null;
  }

  let prioritizedVehicles = suitableVehicles.map((vehicle) => {
    const distancePriority = calculateDistancePriority(order, vehicle);
    const customerPriority = calculateCustomerPriority(order, customers);
    const productTypePriority = calculateProductTypePriority(order, vehicle);

    const washPriority = isWashStatus(customers, products, vehicle, order);

    // Yıkama durumuna göre öncelik değeri ayarla
    let washPriorityValue = washPriority.status == 2 ? 0.9 : 1.0;

    const combinedPriority =
      distancePriority *
      customerPriority *
      washPriorityValue *
      productTypePriority;

    const isCentipede =
      vehicle.capacity >= 18000 && vehicle.capacity <= 22000 ? true : false;

    const productName = order?.productName
      ? order?.productName
      : order?.productType;

    let isCloseBranch = false;
    if (vehicle.fleetBranch && vehicle.fleetBranch.length > 0) {
      const branch = vehicle.fleetBranch[0];
      const distance = haversineDistance(
        { lat: branch.latitude, lng: branch.longitude },
        { lat: order.endLat, lng: order.endLng }
      );
      if (distance < 250.0) {
        isCloseBranch = true;
      }
    }

    return {
      ...vehicle,
      recomendation: {
        ...vehicle,
        ...isVehicleSuitableForOrderDetail(
          order,
          vehicle,
          products,
          isBulk,
          skipTheTonnage,
          isCentipede //Eğer araç kırkayak ise kırkayak returnune girmeli.
        ),
        distancePriority: distancePriority.toFixed(2),
        customerPriority,
        capacity: (vehicle.capacity / 1000).toFixed(2),
        isWashing: washPriority.status,
      },
      isCloseBranch: isCloseBranch,
      priority: combinedPriority,
      distance: parseFloat(distancePriority).toFixed(2),
      lastProductAvailable: !vehicle?.lastProduct
        ? 3
        : similarity(vehicle?.lastProduct, productName) > 0.8
        ? 1
        : 2,
    };
  });
  if (blockedVehicles?.length > 0) {
    const blockPlaque = blockedVehicles.map((r) => r?.plate);
    prioritizedVehicles = prioritizedVehicles.filter(
      (x) => !blockPlaque.includes(x?.plate)
    );
  }

  let topVehicles = selectOptimalVehicles(
    prioritizedVehicles.sort((a, b) => a.priority - b.priority),
    order.tonnage,
    totalCount
  );

  if (topVehicles.length < totalCount) {
    const remainingCount = totalCount - topVehicles.length;
    const additionalVehicles = prioritizedVehicles
      .filter((v) => !topVehicles.some((tv) => tv.id === v.id))
      .slice(0, remainingCount);

    topVehicles = [...topVehicles, ...additionalVehicles];
  }

  if (setAvailable) {
    updateVehicleAvailability(topVehicles, vehicles, order);
  }

  const closeBranchVehicle = prioritizedVehicles.find(
    (item) => item.isCloseBranch
  );

  if (closeBranchVehicle) {
    topVehicles = [...topVehicles, closeBranchVehicle];
  }

  return topVehicles;
}

function selectOptimalVehicles(vehicles, totalTonnage, maxVehicleCount) {
  let remainingTonnage = totalTonnage;
  const selectedVehicles = [];

  for (const vehicle of vehicles) {
    if (selectedVehicles.length >= maxVehicleCount || remainingTonnage <= 0)
      break;

    const vehicleCapacity = vehicle.capacity / 1000; // ton cinsinden
    if (vehicleCapacity <= remainingTonnage) {
      selectedVehicles.push(vehicle);
      remainingTonnage -= vehicleCapacity;
    } else if (selectedVehicles.length < maxVehicleCount) {
      // Kalan tonaj için en uygun aracı seç
      selectedVehicles.push(vehicle);
      remainingTonnage = 0;
    }
  }

  return selectedVehicles;
}

/**
 * Recommends multiple vehicles for an order and sorts them by total cost
 * @param {Object} order - Order details
 * @param {Array} vehicles - Available vehicles
 * @param {Array} customers - Customer information
 * @param {Array} products - Product information
 * @param {number} totalCount - Total count value
 * @param {Array} blockedVehicles - Vehicles to exclude from recommendations
 * @param {string} token - Authentication token
 * @returns {Promise<Array>} Sorted array of recommended vehicles with costs
 */
async function recommendMultipleVehiclesForOrder(
  order,
  vehicles,
  customers,
  products,
  totalCount,
  blockedVehicles = [],
  token
) {
  try {
    const recommendedVehicles = recommendSingleVehicleForOrder(
      order,
      vehicles,
      customers,
      products,
      totalCount,
      true,
      false,
      false,
      blockedVehicles
    );

    if (!Array.isArray(recommendedVehicles)) {
      throw new Error("Invalid recommendations received");
    }

    const vehiclesWithCosts = await Promise.all(
      recommendedVehicles.map(async (vehicle) => {
        try {
          const cost = await calculator(
            token,
            1,
            1,
            vehicle,
            order,
            Number(vehicle.distance),
            0,
            true,
            true,
            1,
            Number(vehicle.distance) / 50
          );

          return {
            ...vehicle,
            recomendationTotalCost:
              Number(cost.total) + Number(cost.totalWashPriceCost),
          };
        } catch (error) {
          console.error(
            `Failed to calculate cost for vehicle ${vehicle.id}:`,
            error
          );
          return {
            ...vehicle,
            recomendationTotalCost: Number.MAX_SAFE_INTEGER,
          };
        }
      })
    );

    return vehiclesWithCosts.sort(
      (a, b) => a.recomendationTotalCost - b.recomendationTotalCost
    );
  } catch (error) {
    console.error("Error in vehicle recommendation:", error);
    return [];
  }
}
/**
 * Sipariş için en uygun araç veya araçları önerir.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {Array} Önerilen araç veya araçlar listesi.
 */
function recommendVehicleForOrder(
  order,
  vehicles,
  products,
  customers,
  totalCount
) {
  // console.log("order", order);
  // console.log("vehicles", vehicles);
  if (order.tonnage <= 30) {
    const vehicle = recommendSingleVehicleForOrder(
      order,
      vehicles,
      customers,
      products,
      false
    );
    if (vehicle) return [vehicle];
    else return [];
  } else {
    const recommendedVehicles = recommendMultipleVehiclesForOrder(
      order,
      vehicles,
      customers,
      products,
      totalCount,
      token
    );

    return recommendedVehicles;
  }
}

module.exports = {
  isWashStatus,
  isSequential,
  recommendSingleVehicleForOrder,
  recommendVehicleForOrder,
  recommendMultipleVehiclesForOrder,
  haversineDistance,
  isVehicleSuitableForOrder,
  calculateDistancePriority,
  calculateCustomerPriority,
  updateVehicleAvailability,
  isVehicleSuitableForOrderDetail,
};
