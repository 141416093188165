<template>
  <modal
    name="add-customer-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Header -->

    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.customers.modal.newCustomer") }}
      </h4>
      <button class="p-2 w-1/12" @click="closeModal">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Header -->

    <!-- Body -->
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('operations.customers.modal.customerName')"
          v-model="name"
          maxlength="500"
          minlength="2"
          required
          type="Text"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          title="Öncelik Puanı (1-10 arasında)"
          v-model="priorty"
          :min="1"
          :max="10"
          required
          type="number"
          :step="1"
        />
      </div>

      <div class="mt-4">
        <diffInput
          title="Hassasiyet (metre)"
          v-model="sensibility"
          min="10"
          max="20000"
          required
          type="number"
          :step="1"
        />
      </div>

      <div class="mt-4">
        <diffInput
          title="Boşaltma Noktası Sayısı"
          v-model="unloadingPointCount"
          min="1"
          max="100"
          required
          type="number"
          :step="1"
        />
      </div>

      <div class="mt-4">
        <diffInput
          title="Zorunlu Yıkatma Durumu"
          v-model="requiredWashingStatus"
          required
          type="select"
          :optList="[
            {
              id: '2',
              name: 'Hayır',
            },
            {
              id: '1',
              name: 'Evet',
            },
          ]"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :draggable="true"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="mt-4">
        <workDays v-model="workDayList" />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
//Local Components
import workDays from "./workDays.vue";
// networking
import axios from "axios";
import { customer } from "@/networking/urlmanager";
export default {
  name: "add-customer-modal",
  components: {
    diffInput,
    asyncBtn,
    workDays,
    GmapItem,
  },
  data() {
    return {
      name: "",
      priorty: 1,
      sensibility: 0,
      unloadingPointCount: "",
      requiredWashingStatus: 2,
      load: false,
      workDayList: [],
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
    };
  },
  methods: {
    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen müşteri ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      this.load = true;
      axios
        .post(
          customer.add,
          {
            name: this.name,
            priorty: this.priorty,
            sensibility: this.sensibility,
            unloadingPointCount: this.unloadingPointCount,
            requiredWashingStatus: this.requiredWashingStatus,
            shiftHours: this.workDayList,
            address: this.position.adress,
            latitude: this.position.lat,
            longitude: this.position.lng,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.priorty = "1";
            this.sensibility = "1";
            this.unloadingPointCount = "1";
            this.requiredWashingStatus = "2";

            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-customer-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
    closeModal() {
      this.name = "";
      this.priorty = 1;
      this.sensibility = 0;
      this.unloadingPointCount = "";
      this.requiredWashingStatus = 2;
      this.load = false;
      this.workDayList = [];
      this.position = {
        lat: 0,
        lng: 0,
        adress: "",
      };
      this.$modal.hide("add-customer-modal");
    },
  },
};
</script>
