<template>
  <div
    class="shadow   overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    style="height:85vh !important"
  >

 
    <table class="min-w-full divide-y divide-gray-200">
      <thead
        class="bg-white border-black border-dotted border-b-2 border-opacity-20"
      >
        <tr>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          >
            Sefer No
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 150px !important"
          >
            Araç
          </th>

          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 120px !important"
          >
            Dolum
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 120px !important"
          >
            Teslim
          </th>

          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Durum
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20 "
            style="min-width: 200px !important"
          >
          <select v-model="sortBy" id="sortBy" class="p-2 rounded w-7/12">
            <option value="orderNo">Sefer No</option>
            <option value="date">Tarih</option>
            <option value="status">Durum</option>
          </select>
          <button @click="toggleSortDirection" class="p-2  text-black w-5/12 text-center bg-gray-50 ">
            {{ sortDirection === 'asc' ? 'A-Z' : 'Z-A' }}
          </button>
          </th>
        </tr>
      </thead>
      <tbody
        class="divide-y-2 divide-gray-500"
        style="background-color: #3a3a3a"
      >
        <tr v-for="(item, index) in getDetailList" :key="index">
          <td class="table-td border-r border-opacity-20">
            {{ item.orderNo }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              {{ item.plate }}
            </p>
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item.planning.receivedName}}
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item.planning.deliveredName}}
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item.planning.stateName }}
          </td>
          <td class="table-td border-r border-opacity-20">
            {{item.planning.lastStatusChangeDate!='Invalid date' ?  item.planning.lastStatusChangeDate:(item.planning.addDate) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "detail-components",
  props: ["list", "search"],
  data() {
    return {
       sortBy: "orderNo",
        sortDirection: 'asc'
    }
  },
  methods: {
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    isValidDate(date) {
      return date && date !== 'Invalid date' && moment(date).isValid();
    },
    isValidOrderNo(orderNo) {
      return orderNo !== null && orderNo !== undefined && orderNo !== '';
    },
    isValidState(state) {
      return state !== null && state !== undefined && state !== '' && state!=0;
    }
  },
  computed: {
    getDetailList() {
      const filteredList = this.list.filter((item) => {
        const searchTerm = this.search.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        
        const plate = (item.plate || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const receivedName = (item.planning.receivedName || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const deliveredName = (item.planning.deliveredName || "").toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const orderNo = (item.orderNo || "").toString().toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");

        return plate.includes(searchTerm) || 
               receivedName.includes(searchTerm) || 
               deliveredName.includes(searchTerm) || 
               orderNo.includes(searchTerm);
      });

      return filteredList.sort((a, b) => {
        let comparison = 0;
        switch (this.sortBy) {
          case 'date':
            const dateA = this.isValidDate(a.planning.lastStatusChangeDate) ? a.planning.lastStatusChangeDate : a.planning.addDate;
            const dateB = this.isValidDate(b.planning.lastStatusChangeDate) ? b.planning.lastStatusChangeDate : b.planning.addDate;
            if (this.isValidDate(dateA) && this.isValidDate(dateB)) {
              comparison = moment(dateA).diff(moment(dateB));
            } else if (this.isValidDate(dateA)) {
              return -1;
            } else if (this.isValidDate(dateB)) {
              return 1;
            }
            break;
            case 'status':
            if (this.isValidState(a.planning.state) && this.isValidState(b.planning.state)) {
              comparison = a.planning.state - b.planning.state;
            } else if (this.isValidState(a.planning.state)) {
              return -1;
            } else if (this.isValidState(b.planning.state)) {
              return 1;
            }
            break;
          case 'orderNo':
          default:
            if (this.isValidOrderNo(a.orderNo) && this.isValidOrderNo(b.orderNo)) {
              comparison = a.orderNo - b.orderNo;
            } else if (this.isValidOrderNo(a.orderNo)) {
              return -1;
            } else if (this.isValidOrderNo(b.orderNo)) {
              return 1;
            }
            break;
        }
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });
    },
  },
};
</script>
