<template>
  <diffInput
    :optList="filterType"
    v-model="select"
    type="select"
    title="Görev Durumu"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "approve-input",
  props: ["value", "totalUnapprovedCount", "totalWontMakeIt"],
  model: {
    event: "change",
    prop: "value",
  },
  components: {
    diffInput,
  },
  data() {
    return {
      select: "noFilter",
      filterType: [
        {
          id: 1,
          name: `Onay Bekliyor (${this.totalUnapprovedCount})`,
        },
        {
          id: 2,
          name: `Yetişemeyecek (${this.totalWontMakeIt})`,
        },
      ],
    };
  },
  created() {
    this.select = this.value;
  },
  watch: {
    select(val) {
      if (val === 0) {
        this.$emit("change", "noFilter");
      } else if (val) {
        this.$emit("change", val);
      }
    },
  },
};
</script>
