<template>
  <modal
    name="zone-creation-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Bölge Oluştur</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('zone-creation-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Modal Content -->
    <div v-if="!isLoading" class="pb-4 px-4 pt-2">
      <div>

        <div class="mb-4">
          <diffInput
            type="text"
            v-model="zoneData.name"
            title="Bölge Adı"
            :required="true"
            class="text-base"
            maxlength="255"
          />
        </div>

        <!-- Location Selection -->
        <div class="mb-4">
          <gmapInputVue
            @change="handleLocationChange"
            :defaultAdress="zoneData.address"
            :lat="zoneData.latitude"
            :lng="zoneData.longitude"
            height="150px"
            placeHolder="Konum Seç"
            class="text-base"
             :hideMap="true"
          />
        </div>




        <!-- Map Component -->
        <div class="w-full mb-4" v-if="zoneData.latitude && zoneData.longitude">
          <zoneMap
            :polygon="zoneData.polyline"
            :lat="zoneData.latitude"
            :lng="zoneData.longitude"
            @handlePolygon="handlePolygon"
            :height="'400px'"
          />
        </div>

        <!-- Footer -->
        <div class="flex justify-end space-x-2">
          <button
            type="button"
            @click="$modal.hide('zone-creation-modal')"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            İptal
          </button>
          <button
            type="submit"
            class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            :class="{'opacity-50 cursor-not-allowed': loading}"
            :disabled="loading || !isValid"
            @click="saveZone"
          >
            <i v-if="loading" class="fas fa-spinner fa-spin mr-2"></i>
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from 'axios';
import gmapInputVue from '@/components/general/gmapInput.vue';
import zoneMap from './zoneMap.vue';
import { encode } from '@/utils/encoder';
import { routeZone } from '@/networking/urlmanager';
import diffInput from '@/components/general/diffInput.vue';
export default {
  name: 'ZoneCreationModal',
  components: {
    gmapInputVue,
    zoneMap,
    diffInput
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      zoneData: {
        name:'',
        address: '',
        latitude: '',
        longitude: '',
        polyline: []
      }
    };
  },
  computed: {
    isValid() {
      return (
        this.zoneData.address &&
        this.zoneData.latitude &&
        this.zoneData.longitude &&
        this.zoneData.polyline.length > 0
      );
    }
  },
  methods: {
    handleLocationChange(location) {
      this.zoneData.address = location.adress;
      this.zoneData.latitude = location.lat;
      this.zoneData.longitude = location.lng;
    },
    handlePolygon(coordinates) {
      const polyline = coordinates.map(coord => [coord.lat, coord.lng]);
      this.zoneData.polyline = encode({ polyline });
    },
    async saveZone() {
      if (!this.isValid) {
        this.$swal.fire({
          icon: 'warning',
          title: 'Uyarı',
          text: 'Lütfen tüm alanları doldurun ve haritadan bölge seçin',
          confirmButtonText: 'Tamam'
        });
        return;
      }

      this.loading = true;
      try {
        await axios.post(
          routeZone.add,
          this.zoneData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.userData.token}`
            }
          }
        );

        await this.$swal.fire({
          icon: 'success',
          title: this.$t('general.successTitle'),
          confirmButtonText: this.$t('general.OkayTitle')
        });

        this.$emit('refresh', true);
        this.$modal.hide('zone-creation-modal');
      } catch (error) {
        this.errorBox(error.response);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.zoneData = {
        address: '',
        latitude: '',
        longitude: '',
        polyline: []
      };
    }
  }
};
</script>