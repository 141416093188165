<template>
  <modal
    name="planning-state-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    style="z-index: 99999999999 !important"
    :scrollable="true"
  >
    <div class="items-center">
      <!-- Header -->
      <div
        class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 flex w-full"
      >
        <h4 class="inline-block font-bold w-7/12">DETAY</h4>
        <div class="w-5/12 flex">
          <searchInput
            placeholder="Çekici Plakası Yazarak Arama Yapabilirsiniz..."
            :isFull="true"
            v-model="search"
          />
          <button
            class="p-2 w-1/12"
            @click="$modal.hide('planning-state-modal')"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <!-- Header -->

      <!-- Body -->
      <div class="w-full">
        <generalStatus :list="detailList" :search="search" />
      </div>
      <!-- Body -->
    </div>
  </modal>
</template>
<script>
//global components
import searchInput from "@/components/general/search-input.vue";

// local component
import generalStatus from "./components/status.vue";

export default {
  name: "detail-table",
  props: ["detailList"],
  components: {
    generalStatus,
    searchInput,
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>
