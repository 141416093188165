var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex items-center justify-between mb-5"},[_c('button',{staticClass:"text-xs shadow-sm rounded-l p-2",class:{
      'bg-red-500 text-white': _vm.activeMenu == 1,
      'w-4/12 bg-gray-200': Number(_vm.detail?.state) == 1,
      'w-6/12 bg-gray-200': Number(_vm.detail?.state) == 0,
    },on:{"click":function($event){_vm.activeMenu = 1}}},[_vm._v(" Genel ")]),(Number(_vm.detail?.state) == 1)?_c('button',{staticClass:"text-xs shadow-sm w-4/12 p-2",class:_vm.activeMenu == 2
        ? 'bg-red-500 text-white'
        : Number(_vm.detail?.state) == 0
        ? 'bg-gray-50 bg-opacity-60 text-opacity-40 text-black'
        : 'bg-gray-200',on:{"click":function($event){_vm.activeMenu = 2}}},[_vm._v(" Mevcut ")]):_vm._e(),_c('button',{staticClass:"text-xs shadow-sm rounded-r w-4/12 p-2",class:{
      'bg-red-500 text-white': _vm.activeMenu == 3,
      'w-4/12 bg-gray-200': Number(_vm.detail?.state) == 1,
      'w-6/12 bg-gray-200': Number(_vm.detail?.state) == 0,
    },on:{"click":function($event){_vm.activeMenu = 3}}},[_vm._v(" Geçmiş ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }