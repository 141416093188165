<template>
  <div class="w-full">
    <!-- Fleet Items -->
    <div class="w-full select-none" v-for="item in List" :key="item.fleetId">
      <div
        @click="() => getDetail(item)"
        class="flex items-center px-4 py-3 my-1 rounded-lg transition-all duration-200 text-xs hover:bg-gray-50 cursor-pointer"
        :class="[
          selected == item && selectedGroup
            ? 'bg-gray-100 shadow-sm'
            : (selected == item || selected == item.fleetId) &&
              !openSub &&
              !selectedGroup
            ? 'border-l-4 border-red-500 bg-gray-100 shadow-sm'
            : selected == item && openSub && 'bg-gray-100 shadow-sm',
        ]"
      >
        <i
          v-if="item.fleetId !== null"
          class="fas text-gray-500 mr-3 w-4 text-center transition-transform duration-200"
          :class="
            item == selected && openSub
              ? 'fa-minus transform rotate-0'
              : 'fa-plus'
          "
        ></i>
        <span class="font-medium text-gray-700">{{ item.fleetName }}</span>
      </div>

      <!-- Subgroups -->
      <div
        class="pl-4 mt-2 space-y-1"
        v-if="openSub && item == selected && item.fleetId != null"
      >
        <!-- Loading State -->
        <div class="flex items-center text-gray-500 px-4 py-2" v-if="load">
          <i class="fas fa-spinner fa-spin mr-2"></i>
          <span>Yükleniyor</span>
        </div>

        <!-- Group Items -->
        <div :key="index" v-for="(groupItem, index) in subList">
          <div class="group-container">
            <div
              @click="selectGroupItem(groupItem)"
              class="flex text-xs items-center px-4 py-2 rounded-lg transition-all duration-200 hover:bg-gray-50 cursor-pointer"
              :class="[
                (selectedGroup == groupItem ||
                  (selectedGroup &&
                    selectedGroup.parentGroupId === groupItem.groupId)) &&
                  'border-l-4 border-red-500 bg-gray-50',
              ]"
            >
              <span
                v-if="groupItem.hasSubGroups"
                @click.stop="toggleSubGroups(groupItem)"
                class="mr-3 w-4 text-center"
              >
                <i
                  class="fas text-gray-500 transition-transform duration-200"
                  :class="groupItem.subGroupsOpen ? 'fa-minus' : 'fa-plus'"
                ></i>
              </span>
              <span class="text-gray-700">{{ groupItem.groupName }}</span>
            </div>

            <!-- Nested Subgroups -->
            <div
              v-if="groupItem.subGroupsOpen && groupItem.subGroups"
              class="pl-8 mt-1 space-y-1"
            >
              <div
                v-for="(subGroup, subIndex) in groupItem.subGroups"
                :key="subIndex"
                @click="selectGroupItem(subGroup)"
                class="px-4 text-xs py-2 rounded-lg transition-all duration-200 hover:bg-gray-50 cursor-pointer"
                :class="[
                  selectedGroup == subGroup &&
                    'border-l-4 border-red-500 bg-gray-50',
                ]"
              >
                <span class="text-gray-700">{{ subGroup.groupName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div class="text-center py-8" v-if="List.length <= 0 && !load">
      <p class="text-gray-500 mb-3">Herhangi bir grup bilgisi bulunamadı</p>
      <button
        class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200 font-medium"
        @click="getAll()"
      >
        Yeniden Dene
      </button>
    </div>

    <!-- Loading State -->
    <div
      class="flex items-center justify-center text-gray-500 py-8"
      v-else-if="List.length <= 0 && load"
    >
      <i class="fas fa-spinner fa-spin mr-2"></i>
      <span>Yükleniyor</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";

export default {
  name: "group-panel",
  data() {
    return {
      List: [],
      subList: [],
      load: false,
      openSub: false,
      selectedGroup: null,
      selected: null,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(tracking.getFleetAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.List = [
            {
              fleetId: null,
              fleetName: "Tümü",
            },
          ];
          this.List.push(...response.data.data);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
        });
    },
    getDetail(item) {
      this.subList = [];
      this.selected = item;

      this.$emit("changeFleet", item?.fleetId);

      if (item.fleetId == null) return;

      this.load = true;
      this.openSub = !this.openSub;
      this.selectedGroup = null;

      axios
        .get(tracking.getGroupAll + "?fleetId=" + this.selected.fleetId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          const groupsWithSubGroups = response.data.data.map((group) => ({
            ...group,
            hasSubGroups: response.data.data.some(
              (g) => g.parentGroupId === group.groupId
            ),
            subGroups: response.data.data.filter(
              (g) => g.parentGroupId === group.groupId
            ),
            subGroupsOpen: false,
          }));

          this.subList = groupsWithSubGroups.filter((g) => !g.parentGroupId);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    selectGroupItem(groupItem) {
      console.log("groupItem", groupItem);

      this.selectedGroup = groupItem;
      this.$emit("changeGroup", groupItem.groupId);
    },
    toggleSubGroups(group) {
      group.subGroupsOpen = !group.subGroupsOpen;
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    selectedGroup(val) {
      this.$emit("changeGroup", val?.groupId);
    },
  },
};
</script>
