<template>
  <div class="rounded w-full relative">
    <multiselect
      :multiple="true"
      :options="List"
      v-model="selected"
      placeholder="Filo seçiniz"
      label="fleetName"
      track-by="fleetId"
      selectLabel="EKLE"
      deselectLabel="KALDIR"
      :close-on-select="false"
      :clear-on-select="false"
      @select="onSelect"
      @remove="onRemove"
    >
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
          {{ values.length }} Filo Seçildi
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import axios from "axios";
import { fleetManagement } from "@/networking/urlmanager";

export default {
  name: "fleet-select",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: [],
      List: [],
      load: false,
      // Track original fleets to check if a fleet was previously saved
      originalFleets: new Set(),
    };
  },
  methods: {
    onSelect(selectedFleet) {
      // If this fleet was previously removed (but not saved), we should find it
      const existingFleet = this.selectedList.find(
        (fleet) => fleet.fleetId === selectedFleet.fleetId
      );

      if (existingFleet) {
        // If it was a previously saved fleet (in originalFleets), restore it with status 1
        if (this.originalFleets.has(selectedFleet.fleetId)) {
          const restoredFleet = {
            ...existingFleet,
            status: 1, // Restore to original status
          };
          this.$emit("update:fleets", [restoredFleet]);
        } else {
          // If it wasn't saved before, set as new (status 2)
          const newFleet = {
            ...selectedFleet,
            status: 2,
          };
          this.$emit("update:fleets", [newFleet]);
        }
      } else {
        // Completely new selection
        const newFleet = {
          ...selectedFleet,
          status: 2,
        };
        this.$emit("update:fleets", [newFleet]);
      }

      this.$emit("change", this.selected);
    },

    onRemove(removedFleet) {
      const existingFleet = this.selectedList.find(
        (fleet) => fleet.fleetId === removedFleet.fleetId
      );

      if (existingFleet) {
        // Only mark as removed (status 3) if it was originally saved
        if (this.originalFleets.has(removedFleet.fleetId)) {
          const updatedFleet = {
            ...existingFleet,
            status: 3,
          };
          this.$emit("update:fleets", [updatedFleet]);
        } else {
          // If it wasn't originally saved, just remove it completely
          // We don't emit any update since it wasn't saved in the first place
          const updatedFleets = this.selectedList.filter(
            (fleet) => fleet.fleetId !== removedFleet.fleetId
          );
          this.$emit("update:fleets", updatedFleets);
        }
      }

      this.$emit("change", this.selected);
    },

    async getAll() {
      try {
        this.load = true;
        const response = await axios.get(fleetManagement.getAll, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });
        this.List = response.data.data.detail;
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },

    initializeOriginalFleets() {
      // Store the IDs of originally selected fleets
      this.originalFleets = new Set(
        this.selectedList
          .filter((fleet) => fleet.status === 1)
          .map((fleet) => fleet.fleetId)
      );
    },
  },
  created() {
    this.selected = this.selectedList
      .filter((fleet) => fleet.status !== 3)
      .map((fleet) => ({
        fleetId: fleet.fleetId,
        fleetName: fleet.fleetName,
      }));
    this.getAll();
    this.initializeOriginalFleets();
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal && !this.load) {
          this.selected = Array.isArray(newVal) ? [...newVal] : [];
        }
      },
      deep: true,
    },
    selectedList: {
      handler() {
        this.initializeOriginalFleets();
      },
      immediate: true,
    },
  },
};
</script>
