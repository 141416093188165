<template>
  <modal
    :name="modalName ?? 'noteDetail-modal'"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '50%' : '50%'"
  >
    <div
      class="pt-3 pb-2 px-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="md:flex items-center justify-center w-full mt-4">
        <div class="w-full text-left">
          <div class="w-full md:flex items-center justify-between">
            <h4 class="inline-block w-6/12">
              {{ title ? title : "Tahliye Notu" }}
            </h4>
          </div>
        </div>
        <div class="flex gap-4">
          <button
            class="p-2 w-1/12"
            @click="$modal.hide(modalName ?? 'noteDetail-modal')"
          >
            <i class="fas fa-times float-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="pt-3 pb-2 px-4 mb-4">
      <div class="w-full mt-4 grid grid-cols-1">
        <diffInput
          type="textarea"
          v-model="note"
          :title="$t('general.note')"
          maxlength="2800"
          :required="false"
          disabled
        />
      </div>
    </div>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
export default {
  components: {
    diffInput,
  },
  props: ["note", "title", "modalName"],
};
</script>
