<template>
  <div class="bg-gray-200 w-full min-h-screen">
    <div class="w-full">
      <div class="md:flex h-full">
        <!-- Side Nav Bar-->
        <div class="w-full min-h-full md:w-3/12 xl:w-2/12 relative">
          <aside class="bg-gray-100 dark:bg-gray-800 text-gray-700 h-full w-full">
            <!-- Side Nav Bar-->

            <!-- eslint-disable -->

            <div class="w-full flex justify-center items-center">
              <div class="pt-3 border-white">
                <imageAvatarUpload
                  sizeText="250X250"
                  :isCircle="true"
                  :loadState="loadImage"
                  :defaultUrl="
                    $store.state.userData.profileImage
                      ? $store.state.userData.profileImage
                      : getProfileImage
                  "
                  @getFile="(r) => changeImage(r)"
                />
              </div>
            </div>
            <sidebarBtn />
          </aside>
        </div>

        <div class="w-full md:w-9/12 xl:w-10/12 h-screen">
          <div v-if="currentTab == 0">
            <profilePanel />
          </div>
          <div v-if="currentTab == 1">
            <profileForm />
          </div>
          <div v-if="currentTab == 2">
            <changePasswordForm />
          </div>
          <div v-if="currentTab == 3">
            <feedbackMenu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import imageAvatarUpload from "@/components/general/imageAvatarUpload";

// Networking
import axios from "axios";
import { user } from "@/networking/urlmanager";

// Local Components
import sidebarBtn from "./components/sidebar.btn.group.vue";
import profilePanel from "./components/profil.panel.vue";
import profileForm from "./components/profile.form.vue";
import changePasswordForm from "./components/change.password.form.vue";
import feedbackMenu from "./components/feedback.panel.vue";

export default {
  components: {
    imageAvatarUpload,
    sidebarBtn,
    feedbackMenu,

    profilePanel,
    profileForm,
    changePasswordForm,
  },
  data() {
    return {
      loadImage: false,
      currentTab: 0,
      loadState: false,
    };
  },
  methods: {
    changeImage(file) {
      this.loadImage = true;
      const formItem = new FormData();
      formItem.append("profileImage", file);
      axios
        .post(user.setProfileImage, formItem, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.$store.commit("setProfileImage", result.data.data);
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            this.$t("user.changeProfileImage"),
            false,
            this.$t("general.OkayTitle")
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.loadImage = false;
        });
    },
  },
};
</script>
