<template>
  <div class="rounded w-full relative">
    <multiselect
      :multiple="true"
      :options="getList"
      :value="selected"
      @select="onSelect"
      @remove="onRemove"
      placeholder="Gün seçiniz"
      label="name"
      track-by="dayId"
      selectLabel="EKLE"
      deselectLabel="KALDIR"
      :close-on-select="false"
      :clear-on-select="false"
    >
      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span class="multiselect__single" v-if="values.length" v-show="!isOpen"
          >{{ values.length }} Gün Seçildi</span
        ></template
      >
    </multiselect>
  </div>
</template>

<script>
//npm
import multiselect from "vue-multiselect";

//networking
export default {
  name: "days-of-week-select",
  props: {
    value: {
      type: Array,
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: [],
      List: [
        { dayId: 1, name: "Pazartesi" },
        { dayId: 2, name: "Salı" },
        { dayId: 3, name: "Çarşamba" },
        { dayId: 4, name: "Perşembe" },
        { dayId: 5, name: "Cuma" },
        { dayId: 6, name: "Cumartesi" },
        { dayId: 7, name: "Pazar" },
      ],
      load: false,
    };
  },
  methods: {
    onSelect(val) {
      this.selected.push(val);
    },
    onRemove(val) {
      const findIndex = this.selected.findIndex((r) => r.dayId == val.dayId);
      this.selected.splice(findIndex, 1);
    },
  },
  created() {
    this.selected = this.value;
  },
  computed: {
    getList() {
      const newList = this.List.map((item) => {
        const productIndex = this.selectedList.findIndex(
          (selected) => selected.dayId === item.dayId
        );
        return { ...item, $isDisabled: productIndex >= 0 && true };
      });
      return newList;
    },
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.$emit("change", val);
      }
    },
    value(val) {
      if (val) this.selected = val;
    },
  },
};
</script>
