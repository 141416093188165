<template>
  <modal
    name="ueserEdit-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Headers -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Aracın Sürücüsünü Değiştir</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('ueserEdit-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Headers -->

    <!-- Body -->
    <form
      @submit.prevent="handleSave"
      ref="formItem"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <personnelInputVue
          title="Ad Soyad"
          @changeObject="(val) => changeItem(val)"
        />
      </div>
      <div class="w-full md:mt-4">
        <diffInput
          :title="$t('humanResources.staff.modal.turkishIdentityNumber')"
          v-model="tcno"
          type="number"
          :disabled="true"
          required="true"
        />
      </div>
      <div class="w-full md:mt-4">
        <diffInput
          :title="$t('humanResources.staff.phoneNumber')"
          v-model="phone"
          type="text"
          :disabled="true"
          required="true"
        />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>
<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import personnelInputVue from "@/components/devItem/personnelInput.vue";

// networking
import axios from "axios";
import { userEditVehicle, bulkShipPlanning } from "@/networking/urlmanager";

export default {
  name: "personnel-add-modal",
  props: ["rowId", "orderNo", "vehicle", "activeSpot", "isShip", "isStore"],
  components: {
    diffInput,
    asyncBtn,
    personnelInputVue,
  },

  data() {
    return {
      fullname: "",
      tcno: "",
      phone: "",
      load: false,
    };
  },
  methods: {
    changeItem(val) {
      this.fullname = val?.name;
      this.tcno = val?.id;
      this.phone = val?.phone;
    },

    handleSave() {
      this.isShip ? this.saveShip() : this.save();
    },

    save() {
      this.load = true;
      axios
        .post(
          userEditVehicle.edit,
          {
            driverName: this.fullname,
            driverTC: this.tcno,
            vehicle: this.vehicle,
            bulkId: this.rowId,
            orderNo: this.orderNo,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.fullname = "";
            this.tcno = "";
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("ueserEdit-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },

    saveShip() {
      this.load = true;
      axios
        .post(
          bulkShipPlanning.changeDriver,
          {
            driverName: this.fullname,
            driverTC: this.tcno,
            vehicle: this.vehicle,
            shipId: this.rowId,
            isStore: this.isStore ? 1 : 0,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.fullname = "";
            this.tcno = "";
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("ueserEdit-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>
