<template>
  <modal
    name="add-edit-note-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '50%' : '50%'"
  >
    <div
      class="pt-3 pb-2 px-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="md:flex items-center justify-center w-full mt-4">
        <div class="w-full text-left">
          <div class="w-full md:flex items-center justify-between">
            <h4 class="inline-block w-6/12">{{ title }}</h4>
          </div>
        </div>

        <div class="flex gap-4">
          <button class="p-2 w-1/12" @click="closeModal">
            <i class="fas fa-times float-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="pt-3 pb-2 px-4 mb-4">
      <div class="w-full mt-4 grid grid-cols-1">
        <diffInput
          type="textarea"
          v-model="localNote"
          :title="$t('general.note')"
          maxlength="2800"
          :required="false"
        />
      </div>
      <div class="mt-4">
        <asyncBtn
          icon="fas fa-check"
          text="Kaydet"
          class="w-36 btn"
          @click="handleSubmit"
          :loading="loading"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "NoteModal",
  components: {
    diffInput,
    asyncBtn,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    note: {
      type: String,
      default: "",
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      localNote: this.note,
      loading: false,
    };
  },

  watch: {
    note(newValue) {
      this.localNote = newValue;
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        await this.onSubmit(this.localNote);
        this.closeModal();
      } catch (error) {
        this.errorBox(err.response);
      } finally {
        this.loading = false;
        this.localNote = "";
      }
    },

    closeModal() {
      this.$modal.hide("add-edit-note-modal");
      this.$emit("close");
    },
  },
};
</script>
