<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <LyrausTable
        :columns="columns"
        :data="List"
        :isLoading="load"
        :alternateRowBg="['white', 'gray-100']"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :batchOperations="true"
        :searchBar="true"
        @update-search-value="(val) => handleSearchValue(val)"
        :handleSearch="handleSearch"
      >
        <template v-slot:rowButtons="{ item, columnKey }">
          <div v-if="columnKey === 'periodicKilometerColum'">
            <span>
              {{ Math.round(item.periodicKilometer) }}
            </span>
          </div>
        </template>
        <template v-slot:batchOperations>
          <form
            @submit.prevent="getAll"
            class="p-2 flex items-center justify-between bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
          >
            <div class="flex items-center space-x-4 flex-grow">
              <div class="inline-flex rounded-md shadow-sm" role="group">
                <button
                  @click="useMonthSelector = false"
                  :class="[
                    'py-2 px-4 text-sm font-medium border-b-2',
                    !useMonthSelector
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  ]"
                >
                  Tarih
                </button>
                <button
                  @click="useMonthSelector = true"
                  :class="[
                    'py-2 px-4 text-sm font-medium border-b-2',
                    useMonthSelector
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  ]"
                >
                  Aylık
                </button>
              </div>

              <div
                v-if="!useMonthSelector"
                class="flex items-center flex-grow space-x-4"
              >
                <div class="w-full">
                  <diffInput
                    v-model="startDate"
                    :max="endDate"
                    title="Başlangıç tarihi"
                    type="date"
                    :hideContainer="true"
                  />
                </div>
                <div class="w-full">
                  <diffInput
                    v-model="endDate"
                    :min="startDate"
                    title="Bitiş Tarihi"
                    type="date"
                    :hideContainer="true"
                    :max="getNowDate"
                  />
                </div>
              </div>

              <SelectMonth
                v-else
                :loading="load"
                @update:month="handleMonthUpdate"
                @update:year="handleYearUpdate"
                class="flex-grow"
              />
            </div>

            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-search"
                text="Ara"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-32"
                size="py-2"
              />
            </div>

            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </form>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import moment from "moment";
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import SelectMonth from "@/components/filters/selectMonth.vue";
import { getLocationAll } from "../../../utils/detailData";
import { userEditVehicle } from "../../../networking/urlmanager";

export default {
  name: "VehicleKilometers",
  components: {
    diffInput,
    asyncBtn,
    SelectMonth,
  },
  data() {
    return {
      load: false,
      useMonthSelector: false,
      columns: [
        { label: "Araç Plakası", key: "vehicle_plate" },
        {
          label: "Yapılan Kilometre",
          key: "periodicKilometerColum",
          dontDisplay: true,
        },
        { label: "Başlangıç Kilometresi", key: "startKilometer" },
        { label: "Bitiş Kilometresi", key: "endKilometer" },
        { label: "Varlık Durumu", key: "haveType" },
        { label: "Araç Türü", key: "vehicleType" },
      ],
      List: [],
      realList: [],
      startDate: "",
      endDate: "",
      search: "",
    };
  },
  computed: {
    getNowDate() {
      return moment().format("YYYY-MM-DDTHH:mm");
    },
  },
  methods: {
    handleMonthUpdate(month) {
      if (this.useMonthSelector) {
        this.startDate = moment()
          .month(month)
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm");
        this.endDate = moment()
          .month(month)
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm");
      }
    },
    handleYearUpdate(year) {
      if (this.useMonthSelector) {
        this.startDate = moment()
          .year(year)
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm");
        this.endDate = moment()
          .year(year)
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm");
      }
    },
    getThisMonthsStartDate() {
      return moment().startOf("month").utc().format("YYYY-MM-DDTHH:mm");
    },
    getThisMonthsEndDate() {
      return moment().endOf("month").utc().format("YYYY-MM-DDTHH:mm");
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      const searchTerm = this.search.toLowerCase();
      const filteredList = this.realList.filter((item) =>
        item.vehicle_plate.toLowerCase().includes(searchTerm)
      );
      this.List = filteredList;
    },
    async vehicleAll(){
      const result=await axios.get(userEditVehicle.getTmsVehicleAll,{
        headers: {
          Authorization: "Bareer " + this.$store.state.userData.token,
        }
      });

      return result.data.data;
    },
    async getAll() {
      if (!this.startDate || !this.endDate) {
        const dateTitle =
          !this.startDate && !this.endDate
            ? "Başlangıç ve bitiş"
            : !this.endDate
            ? "Bitiş"
            : "Başlangıç";
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          `${dateTitle} tarihi boş bırakılamaz`,
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      this.load = true;

      const endDate = this.useMonthSelector
        ? moment(this.endDate).utc().endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : moment(this.endDate)
            .utc()
            .add(1, "day")
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss");

      const params = {
        startDate: moment(this.startDate)
          .utc()
          .add(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate,
      };
      try {
        const res = await axios.get(
          `https://tracking.auto.siriusaitech.com/get/kilometers`,
          {
            params,
            headers: {
              Authorization: "Bareer fhfierffhxc12342+",
            },
          }
        );
        const list = await this.vehicleAll();
        const currentMonth = moment().month();
        const currentYear = moment().year();
        const selectedMonth = moment(this.endDate).month();
        const selectedYear = moment(this.endDate).year();
        
        const isCurrentMonth = currentMonth === selectedMonth && currentYear === selectedYear;
        const turkPlakaRegex = /^(0[1-9]|[1-7][0-9]|8[0-1])[A-Z]{1,3}\d{2,4}$/;

        const newList = res.data.data
          .filter((item) => !item.vehicle_plate.endsWith(".") && turkPlakaRegex.test(item.vehicle_plate))
          .map((item) => {

            const findItem = list.find((r) => r.plakano === item.vehicle_plate);

            if (findItem) {
              return {
                ...item,
                haveType: findItem.aracsahipad,
                vehicleType: findItem.aractipad,
              };
            } else if (!isCurrentMonth) { // Sadece geçmiş aylarda çalışacak
              if (turkPlakaRegex.test(item.vehicle_plate)) {
                return {
                  ...item,
                  haveType: "Bilgi Bulunamadı",
                  vehicleType: "Bilgi Bulunamadı",
                };
              }
            }
          }).filter((r) => r != undefined);

        this.List = newList;
        this.realList = newList;
      } catch (err) {
        console.log(err)
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    async excelExport() {
      const workbook = XLSX.utils.book_new();
      const headers = {
        vehicle_plate: "Plaka",
        periodicKilometer: "Alınan Yol",
        startDate: "Başlangıç Tarihi",
        endDate: "Bitiş Tarihi",
        startKilometer: "Başlangıç Kilometresi",
        endKilometer: "Bitiş Kilometresi",
        haveType: "Varlık Durumu",
        vehicleType: "Araç Türü",
      };

      const detail = [
        Object.values(headers),
        ...this.List.map((item) => {
          return Object.keys(headers).map((key) => {
            if (key === "startDate") return this.startDate;
            if (key === "endDate") return this.endDate;
            if (key === "periodicKilometer")
              return Math.round(item.periodicKilometer);
            if (key === "startKilometer")
              return Math.round(item.startKilometer);
            if (key === "endKilometer") return Math.round(item.endKilometer);
            if (key === "vehicleType") return item.vehicleType;
            if (key === "haveType") return item.haveType;
            return item[key] || "";
          });
        }),
      ];

      const worksheet = XLSX.utils.json_to_sheet(detail);

      XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", "", "", ""]], {
        origin: "A1",
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, "kilometerRaports");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(data, "vehicle-kilometer.xlsx");
    },
  },
  created() {
    this.startDate = this.startDate
      ? this.startDate
      : this.getThisMonthsStartDate();
    this.endDate = this.endDate ? this.endDate : this.getThisMonthsEndDate();
    this.getAll();
  },
};
</script>
