<template>
  <div class="h-screen bg-white md:py-24 py-8" align="center">
    <img
      class="md:w-4/12 max-sm:w-full pb-4 sm:px-10 md:px-0"
      src="@/assets/auth/güneyLogo.png"
      alt=""
    />
    <h2
      class="md:text-4xl text-3xl font-bold mb-2 mt-20"
      style="color: #261f53"
    >
      PAROLAMI UNUTTUM
    </h2>
    <h6 class="text-sm mt-4" style="color: #261f53">
      Lütfen tüm bilgileri eksiksiz olarak doldurunuz
    </h6>
    <form class="md:px-20 px-10 mt-6 sm:w-full md:w-7/12">
      <div class="w-full text-left mt-5" align="center">
        <label style="color: #a8a8a8"> E-posta </label>
        <input
          v-model="email"
          type="email"
          class="form-password focus:ring-blue-500 focus:border-blue-500 w-full p-2 mt-2 rounded shadow border-indigo-900 border"
          required
        />
      </div>
      <div class="w-full mt-2">
        <div v-if="!isCountdownActive">
          <asynBtn
            type="submit"
            :bgDark="true"
            :loadState="load"
            loadTitle="Gönderiliyor"
            containerClass="w-full mt-5 asynBtn"
            icon="fas fa-sign-in-alt"
            text="Gönder"
            style="background-color: #261f53"
            @click="save"
          />
        </div>
        <div
          v-else
          class="w-full mt-5 p-3 rounded text-white text-center"
          style="background-color: #261f53"
        >
          Yeniden göndermek için bekleyin: {{ formatTime(countdown) }}
        </div>
      </div>
    </form>
    <div class="w-full mt-5 mb-5 text-center">
      <button
        class="hover:text-blue-900"
        type="button"
        @click="$emit('signIn')"
      >
        <i class="fas fa-arrow-left"></i>
        Geri Dön
      </button>
    </div>
  </div>
</template>

<script>
import asynBtn from "@/components/general/asyncBtn";
import { user } from "../../../networking/urlmanager";
import axios from "axios";

export default {
  name: "forgatForm",
  components: {
    asynBtn,
  },
  data() {
    return {
      email: "",
      companyCode: 6,
      load: false,
      isCountdownActive: false,
      countdown: 60,
      countdownInterval: null,
    };
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    startCountdown() {
      this.isCountdownActive = true;
      this.countdown = 60;

      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
      this.isCountdownActive = false;
      this.countdown = 60;
    },
    async save() {
      if (this.email && this.companyCode) {
        try {
          this.load = true;
          await axios.post(user.fargotPasswordCreate, {
            email: this.email,
            companyId: this.companyCode,
          });
          this.load = false;
          this.swalBox(
            "success",
            "Başarılı",
            "Şifre değiştirme linki başarıyla mail adresinize yollanmıştır",
            false,
            "Tamam"
          );
          this.startCountdown();
        } catch (err) {
          this.errorBox(err.response);
          this.load = false;
        }
      } else {
        this.swalBox(
          "warning",
          "UYARI",
          "Lütfen tüm alanları eksiksiz olarak doldurunuz",
          false,
          "Tamam"
        );
      }
    },
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
};
</script>

<style>
.asynBtn:hover {
  opacity: 0.5;
}
</style>
