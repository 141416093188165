<template>
  <div class="w-full">
    <detailItem
      title="Görev Durumu"
      :value="detail?.state != 0 ? 'Evet ' : 'Hayır'"
    />

    <detailItem
      title="Sürücü"
      :value="detail?.driverName ? detail?.driverName : 'Belirlenmedi'"
    />
    <detailItem title="Sürüş Süresi" :value="detail?.takoDate" />
    <detailItem title="Araç Tipi" :value="detail?.vehicleType" />
    <detailItem
      title="Dorse"
      :value="detail?.dorse ? detail?.dorse : 'Belirlenmedi'"
    />
    <detailItem
      title="Taşıma Kapasitesi"
      :value="detail?.capacity ? detail?.capacity + ' Ton' : 'Dorse Yok'"
    />
    <detailItem
      title="Yakıt Seviyesi"
      :value="
        detail?.canbusFuelLevel > 0 ? detail?.canbusFuelLevel + ' %' : '0%'
      "
    />
    <detailItem title="Araç Hızı" :value="detail?.speed + 'km / h'" />
    <detailItem title="Bölge" :value="detail?.region" />
  </div>
</template>

<script>
// Local Compoenents
import detailItem from "./components/detailItem.vue";

export default {
  name: "current-detail",
  props: ["detail"],
  components: {
    detailItem,
  },
};
</script>
