<template>
  <modal
    name="add-branch-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ NOKTA</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-branch-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Body -->
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Adı"
          v-model="name"
          max="1024"
          min="4"
          required
          type="Text"
        />
      </div>
      <div class="w-full my-5">
        <fleetSelect
          v-model="fleets"
          :selectedList="fleetsList"
          @update:fleets="updateFleets"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :draggable="true"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
import fleetSelect from "@/components/general/fleet.select.vue";
// Networking
import axios from "axios";
import { branches } from "@/networking/urlmanager";

export default {
  name: "add-branch-modal",
  components: {
    diffInput,
    asyncBtn,
    GmapItem,
    fleetSelect,
  },
  data() {
    return {
      name: "",
      fleets: [],
      fleetsList: [],
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
      load: false,
    };
  },
  methods: {
    updateFleets(newFleets) {
      const addedFleets = newFleets.map((fleet) => ({
        ...fleet,
        status: 2, // All fleets in add modal are new
      }));

      this.fleetsList = [...this.fleetsList, ...addedFleets];
    },

    removeFleet(index) {
      this.fleetsList.splice(index, 1);
    },

    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen Şube ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      if (!this.position?.lat) {
        this.swalBox("warning", "UYARI!", "Lütfen ilk önce bir adres seçin");
        return;
      }

      const newFleetList = this.fleetsList.map((item) => ({
        status: 2,
        rowId: item.id,
        fleetId: item.fleetId,
        fleetName: item.fleetName,
      }));

      this.load = true;
      axios
        .post(
          branches.add,
          {
            name: this.name,
            address: this.position.adress,
            lat: this.position.lat,
            lng: this.position.lng,
            fleets: newFleetList,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetForm();
            this.$emit("refresh", true);
            this.$modal.hide("add-branch-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },

    resetForm() {
      this.name = "";
      this.position = {
        lat: 0,
        lng: 0,
        adress: "",
      };
      this.fleetsList = [];
      this.fleets = [];
      this.load = false;
    },
  },
};
</script>
