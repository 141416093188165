<template>
  <modal name="detail-feedback-modal" height="auto">
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("profile.modal.feedbackDetail") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('detail-feedback-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div class="w-full">
        <diffInput
          title="Açıklama"
          :title="$t('general.explanation')"
          type="textarea"
          v-model="explanation"
          maxLength="1000"
          minLength="1"
          :required="true"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "feedback-detial-modal",
  props: ["explanation"],
  components: {
    diffInput,
  },
};
</script>
