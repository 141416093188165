<template></template>
<script>
// Networking
import axios from "axios";
import { bulkPlanning } from "../../../networking/urlmanager";

export default {
  name: "tonnageController",
  methods: {
    handleCompletePlanning(params) {
      axios
        .post(bulkPlanning.completePlanning, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startInterval() {
      this.$store.commit(
        "setLTTIntervalId",
        setInterval(() => {
          this.getLastTenTonnage();
        }, 60000)
      );
    },
    getLastTenTonnage() {
      axios
        .get(bulkPlanning.lastTenTonnageController, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          // state de ongoingOrders ı kontrol edip ip'lerden herhangi biri
          // api dan dönen orderların arasında yoksa ongoingOrders dan id yi siler
          this.$store.state.ongoingOrders.forEach((ongoingOrder) => {
            const orderIndex = res.data.data.findIndex(
              (order) => ongoingOrder === order.id
            );
            if (orderIndex === -1) {
              this.$store.commit("deleteOngoingOrder", ongoingOrder);
            }
          });
          res.data.data.forEach((order) => {
            const orderIndex = this.$store.state.ongoingOrders.findIndex(
              (ongoingOrder) => ongoingOrder === order.id
            );
            if (orderIndex === -1) {
              clearInterval(this.$store.state.LTTIntervalId);
              this.swalBox(
                "warning",
                this.$t("general.warningTitle"),
                `${order.orderNo} numaralı siparişin kalan tonajı 10 tonun altında siparişi durdurmak ister misiniz?`,
                true,
                this.$t("general.yes"),
                this.$t("general.no")
              ).then((acc) => {
                if (acc.isConfirmed) {
                  const params = {
                    isCopmletedOrder: 1,
                    rowId: order.id,
                    tonnage: order.remainingTonnage,
                    orderNo: order.orderNo,
                  };
                  this.handleCompletePlanning(params);
                } else {
                  const params = {
                    isCopmletedOrder: 2,
                    rowId: order.id,
                    orderNo: order.orderNo,
                  };
                  this.handleCompletePlanning(params);
                  this.$store.commit("setOngoingOrder", order.id);
                }
                this.startInterval();
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.$store.state.userData.token) {
      this.startInterval();
    }
  },
};
</script>
