<template>
  <modal
    name="fleet-customers-modal"
    :width="isMobile ? '100%' : '40%'"
    :height="isMobile ? '100%' : 'auto'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="16" :rowId="rowId" />

    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-center items-center"
    >
      <h4 class="inline-block w-10/12">{{ name }}</h4>
      <div class="w-2/12 flex items-center justify-end mr-1">
        <logBtn />
        <button
          class="p-2 w-1/12"
          @click="$modal.hide('fleet-customers-modal')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="mx-4 items-center justify-center">
      <CustomersSelect v-model="List" />
    </div>
    <div
      v-if="List.length"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto pb-20 xl:h-auto xl:pb-3"
    >
      <LyrausTable :columns="columns" :data="List" :indexColumn="true">
        <template v-slot:rowButtons="{ item, columnKey }">
          <div v-if="columnKey === 'customerName'">
            <span
              :class="item.status === 3 ? 'text-red-500 line-through' : ''"
              >{{ item.customerName }}</span
            >
          </div>
        </template>
      </LyrausTable>
    </div>
    <div v-else class="p-4 text-center">
      <span>Bu filoya bağlı müşteri bulunmamaktadır</span>
    </div>
    <div class="p-4 text-right">
      <asyncBtn
        @click="() => handleSubmit()"
        :text="'KAYDET'"
        :loadTitle="$t('general.saveButtonLoadTitle')"
        :loadState="load"
        class="w-full"
      />
    </div>
  </modal>
</template>
<script>
//networking
import axios from "axios";
import { fleetManagement } from "@/networking/urlmanager";

import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

import CustomersSelect from "./customers.select.vue";
export default {
  components: {
    asyncBtn,
    CustomersSelect,
    logModule,
    logBtn,
  },
  name: "fleet-customers-modal",
  props: ["detail"],
  data() {
    return {
      load: false,
      List: [],
      name: "",
      rowId: null,
      columns: [
        {
          key: "customerName",
          label: "Müşteri",
          dontDisplay: true,
        },
      ],
    };
  },
  methods: {
    getDetail() {
      setTimeout(() => {
        this.List =
          this.detail.customers[0] === null
            ? []
            : this.detail.customers.map((item) => ({
                ...item,
                status: 1,
                rowId: item.id,
              }));
        this.name = this.detail.fleetName;
        this.rowId = this.detail.id;
      }, 50);
    },
    async handleSubmit() {
      this.load = true;
      const params = {
        rowId: this.rowId,
        customers: this.List,
      };
      try {
        await axios.post(fleetManagement.edit, params, {
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        });
        this.swalBox(
          "success",
          this.$t("general.successTitle"),
          "",
          false,
          this.$t("general.OkayTitle")
        );
        this.$emit("refresh", true);
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
