import Vue from "vue";
import NavIcon from "../assets/marker/navIcon.vue";

// create a constructor from a Vue component
const LocationIconConstructor = Vue.extend(NavIcon);

export const getRotationIcon = (rotation) => {
  // create a Vue element with required props
  const iconComponent = new LocationIconConstructor({
    propsData: { rotation },
  });
  // mount the component shadow DOM
  iconComponent.$mount();
  // get icon DOM element
  const iconDom = iconComponent.$el;
  // generate an html string from an element
  const iconString = new XMLSerializer().serializeToString(iconComponent.$el);
  // finally, generate a data url from a string
  return "data:image/svg+xml;charset=UTF-8;base64," + btoa(iconString);
};
