<template>
  <div
    class="fixed h-auto w-full bg-black blur-3xl bg-opacity-60 bg-transparent border-red-500 border-r border-b py-5 pl-6 pr-5 shadow xl:w-56"
    style="z-index: 21 !important"
  >
    <!-- Header -->
    <div
      class="w-full px-3 text-white border-opacity-5 py-2 relative flex justify-between items-center"
    >
      <h5 class="text-xs font-bold">GRUP DETAYI</h5>

      <button @click="$emit('close', true)">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Header -->

    <!-- Body -->

    <div class="w-full py-2 h-72 overflow-y-scroll">
      <div
        class="w-full py-3 select-none cursor-pointer px-2 my-2 text-xs rounded bg-gray-100 shadow hover:bg-gray-300 duration-200"
        v-for="(item, index) in List"
        :key="index"
      >
        <div class="w-full flex items-center justify-between">
          {{ item.label.text }}
          <i class="fas fa-map-marker opacity-50"></i>
        </div>
      </div>
    </div>

    <!-- Body -->
  </div>
</template>

<script>
export default {
  name: "vehicle-tracking",
  props: ["List"],
};
</script>
