<template>
  <div class="w-full text-2xl dark:text-white mt-2 dark:bg-gray-900 px-2">
    <div class="dark:bg-gray-800 shadow-sm rounded">
      <addModal @refresh="getAll()" />
      <editModal ref="editCalculateroute" @refresh="getAll()" :detail="selectedItem" />
      <div
        class="h-full bg-white dark:text-gray-400 dark:bg-gray-800 rounded justify-between py-2 px-2"
      >
        <div>
          <div class="flex justify-between items-center">
            <asyncBtn
              @click="$modal.show('addCalculateroute-modal')"
              icon="fa fa-plus"
              text="Yeni Hesap"
              class="px-4 py-1 text-base btn"
            />

            <div class="w-3/12 flex">
              <searchInput
                v-model="search"
                @searcing="() => getAll(true)"
                inputClass="w-full text-lg"
              />
            </div>
          </div>
          <div
            :class="{
              'fixed top-0 w-full z-50 left-0 h-full bg-white': isFullScreen,
            }"
          >
            <div
              :class="{
                'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4': !isFullScreen,
                'w-full overflow-y-scroll h-full': isFullScreen,
              }"
            >
              <div class="relative">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="border-black border-dotted border-b-2 border-opacity-20">
                    <tr>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 px-2 z-20"
                        style="min-width: 100px !important"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 px-2 z-20"
                        style="min-width: 150px !important"
                      >
                        HESAP ADI
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 px-2 z-20"
                        style="min-width: 200px !important"
                      >
                        BAŞLANGIÇ- BİTİŞ NOKTALARI
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 px-2 z-20"
                        style="min-width: 200px !important"
                      >
                        TEK YÖN KİLOMETRESİ
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 px-2 z-20"
                        style="min-width: 200px !important"
                      >
                        İŞLEM

                        <tableResizeBtn v-model="isFullScreen" />
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="divide-y-2 divide-gray-500 bg-gray-800 opacity-90 z-10"
                    style="background-color: #3a3a3a"
                  >
                    <tr v-for="(item, index) in List" :key="index">
                      <td class="table-td border-r border-opacity-20 px-2">
                        {{ index + 1 + currentPage * 15 }}
                      </td>
                      <td class="table-td border-r border-opacity-20 px-2">
                        {{ item.name }}
                      </td>
                      <td class="table-td border-r border-opacity-20 px-2">
                        {{ item.startPosition }} -- {{ item.finishPosition }}
                      </td>
                      <td class="table-td border-r border-opacity-20 px-2">
                        {{ item.oneWayKm }} Km
                      </td>
                      <td class="table-td text-center w-42">
                        <button @click="() => editItem(item)" class="process-btn mr-2">
                          <i class="fas fa-search"></i>
                        </button>
                        <button @click="() => deleteItem(item)" class="process-btn">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tableLoader
                      :load="load"
                      :length="List.length"
                      colspan="5"
                      class="text-lg"
                    />
                  </tbody>
                </table>
              </div>
            </div>
            <div :class="isFullScreen && 'absolute bottom-4 w-full bg-white'">
              <PaginationComponents
                v-if="totalCount > 0"
                :totalRowCount="totalCount"
                :pageCount="pageCount"
                :currentPage="currentPage + 1"
                @changePage="(r) => (currentPage = r - 1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import searchInput from "@/components/general/search-input.vue";
import tableResizeBtn from "@/components/general/table.resize.btn.vue";

//networking
import axios from "axios";
import { calculate } from "@/networking/urlmanager";

//local components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
export default {
  name: "calculatorPage",
  components: {
    asyncBtn,
    tableLoader,
    addModal,
    editModal,
    PaginationComponents,
    searchInput,
    tableResizeBtn,
  },
  data() {
    return {
      isFullScreen: false,
      load: false,
      selectedItem: "",
      search: "",
      List: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
    };
  },
  methods: {
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;
      this.load = true;
      this.List = [];
      axios
        .get(calculate.getAll + "?search=" + this.search + "&page=" + this.currentPage, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    editItem(item) {
      this.$modal.show("editCalculateroute-modal");
      this.selectedItem = item;
      this.$refs.editCalculateroute.show();
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              calculate.delete,
              {
                rowId: item.id,
                name: item.name,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.userData.token,
                },
              }
            )
            .then((res) => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.res);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
