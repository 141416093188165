<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <tallyDetailsModal
      v-if="selected"
      :detail="selected"
      :startDate="startDate"
      :endDate="endDate"
    />
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <LyrausTable
        v-if="isTableReady"
        :key="tableKey"
        :columns="columns"
        :data="List"
        :isLoading="load"
        :alternateRowBg="['white', 'gray-100']"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :stickyLeft="['fullName']"
        :buttonsColumn="{ label: 'İşlem', width: 40 }"
        :batchOperations="true"
        :searchBar="true"
        @update-search-value="handleSearchValue"
        :handleSearch="handleSearch"
      >
        <template v-slot:colButtons="{ item }">
          <div class="flex items-center justify-end px-2">
            <button @click="showDetails(item)">
              <i class="fas fa-book text-gray-700"></i>
            </button>
          </div>
        </template>
        <template v-slot:batchOperations>
          <form
            @submit.prevent="getAll"
            class="p-2 flex items-center justify-between bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
          >
            <div class="flex-grow flex items-center mr-2">
              <SelectMonth
                :loading="load"
                @update:month="selectedMonth = $event"
                @update:year="selectedYear = $event"
              />
            </div>
            <div class="w-full">
              <asyncBtn
                icon="fas fa-search"
                text="Ara"
                loadTitle="Yükleniyor"
                class="w-full md:w-32"
                size="py-2"
              />
            </div>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport"
                :disabled="!List.length"
              />
            </div>
          </form>
        </template>
        <template v-slot:rowButtons="{ item, columnKey }">
          <div
            v-if="
              !['fullName', 'tc', 'totalKm', 'adrOrders', 'allOrders'].includes(
                columnKey
              )
            "
          >
            <template
              v-if="
                item[columnKey]?.workingStatus !== undefined &&
                item[columnKey]?.workingStatus !== null
              "
            >
              <i
                v-if="Number(item[columnKey]?.workingStatus) === 1"
                class="fas fa-check text-green-400"
              ></i>
              <i
                v-else-if="Number(item[columnKey]?.workingStatus) === 0"
                class="fas fa-times text-red-400"
              ></i>
            </template>
            <span v-else>-</span>
          </div>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { LyrausTable } from "lyraus-ui";
import * as XLSX from "xlsx";
import axios from "axios";
import moment from "moment";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import { driverTally } from "@/networking/urlmanager";
import tallyDetailsModal from "./components/tallyDetailsModal.vue";
import SelectMonth from "@/components/filters/selectMonth.vue";

export default {
  name: "driverTally",
  components: {
    diffInput,
    asyncBtn,
    tallyDetailsModal,
    SelectMonth,
  },
  data() {
    return {
      load: true,
      columns: [],
      List: [],
      realList: [],
      startDate: undefined,
      endDate: undefined,
      selected: undefined,
      selectedMonth: "",
      selectedYear: "",
      search: "",
      tableKey: 0,
      isTableReady: false,
    };
  },
  methods: {
    showDetails(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("tally-details-modal");
      }, 50);
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      const searchTerm = this.search.toLowerCase();
      this.List = this.realList.filter(
        (item) =>
          item.fullName.toLowerCase().includes(searchTerm) ||
          item.tc.includes(searchTerm)
      );
    },
    getAllDatesInMonth(year, month) {
      const startDate = moment([year, month]).startOf("month");
      const endDate = moment(startDate).endOf("month");
      const dates = [];

      let currentDate = startDate.clone();
      while (currentDate <= endDate) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "days");
      }

      return dates;
    },
    transformData(inputData) {
      const allDates = this.getAllDatesInMonth(
        this.selectedYear,
        this.selectedMonth
      );

      this.columns = [
        { key: "fullName", label: "Ad Soyad" },
        { key: "tc", label: "TC Kimlik" },
        { key: "totalKm", label: "Toplam Kilometre" },
        { key: "adrOrders", label: "Toplam ADR Sipariş" },
        { key: "allOrders", label: "Toplam Sipariş" },
        ...allDates.map((date) => ({
          key: date.replace(/-/g, ""),
          label: date.split("-")[2],
          dontDisplay: true,
        })),
      ];

      if (!inputData || !inputData.length) {
        this.List = this.realList = [];
        return;
      }

      this.List = this.realList = inputData.map((person) => {
        const result = {
          fullName: `${person.name} ${person.surname}`,
          tc: person.tc,
          totalKm: person.totalKm,
          adrOrders: person.adrOrders,
          allOrders: person.allOrders,
        };

        const tallyMap = new Map(
          person.tally.map((record) => [
            moment(record.date).format("YYYY-MM-DD"),
            record,
          ])
        );

        allDates.forEach((date) => {
          const dateKey = date.replace(/-/g, "");
          const record = tallyMap.get(date);

          if (record) {
            result[dateKey] = {
              id: record.id,
              date: record.date,
              workingStatus: record.workingStatus,
            };
          } else {
            result[dateKey] = {
              id: null,
              date: date,
              workingStatus: undefined,
            };
          }
        });

        return result;
      });
    },
    async getAll() {
      this.load = true;
      this.List = [];
      try {
        const startDate = moment([
          this.selectedYear,
          this.selectedMonth,
        ]).startOf("month");
        const endDate = moment(startDate).endOf("month");

        this.startDate = startDate;
        this.endDate = endDate;

        const { data } = await axios.get(driverTally.getAll, {
          params: {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
          },
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        });

        this.transformData(data.data);
        this.tableKey++;
        this.isTableReady = true;
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    async excelExport() {
      const workbook = XLSX.utils.book_new();

      const headers = [
        "Ad Soyad",
        "TC Kimlik",
        "Toplam Kilometre",
        "Toplam ADR Sipariş",
        "Toplam Sipariş",
      ];

      this.columns
        .filter(
          (col) =>
            !["fullName", "tc", "totalKm", "adrOrders", "allOrders"].includes(
              col.key
            )
        )
        .forEach((col) => {
          headers.push(col.label);
        });

      const rows = this.List.map((item) => {
        const row = [
          item.fullName,
          item.tc,
          item.totalKm,
          item.adrOrders,
          item.allOrders,
        ];

        this.columns
          .filter(
            (col) =>
              !["fullName", "tc", "totalKm", "adrOrders", "allOrders"].includes(
                col.key
              )
          )
          .forEach((col) => {
            const status = item[col.key]?.workingStatus;
            if (status === undefined || status === null) {
              row.push("-");
            } else {
              row.push(status === 1 ? "Çalıştı" : "Çalışmadı");
            }
          });
        return row;
      });

      const detail = [headers, ...rows];

      const worksheet = XLSX.utils.aoa_to_sheet(detail);
      XLSX.utils.book_append_sheet(workbook, worksheet, "driverTally");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(data, "driver-tally.xlsx");
    },
  },
  created() {
    this.getAll();
  },
};
</script>
