<template>
  <div id="app" class="flex min-h-screen w-full bg-gray-100 relative">
    <checkInternet
      successText="İnternet Bağlantınız Tekrar Sağlandı"
      errorText="İnternet bağlanıtınız Koptu"
    />
    <tonnageController />
    <serviceRequestController />
    <div
      class="w-full max-h-screen overflow-y-auto bg-gray-200 dark:bg-gray-900"
    >
      <headerItem v-if="$store.state.isAuth" />

      <router-view ref="activePage" />
    </div>
  </div>
</template>

<script>
import LZString from "lz-string";
import checkInternet from "check-to-network";
import headerItem from "../src/partition/header.vue";
import tonnageController from "./components/controller/tonnageController/index.vue";
import serviceRequestController from "./components/controller/serviceRequestController/index.vue";

//Networking
import axios from "axios";
import { user } from "./networking/urlmanager";
export default {
  name: "app",
  components: {
    headerItem,
    checkInternet,
    tonnageController,
    serviceRequestController,
  },
  data() {
    return {
      currentPath: "",
      intervalId: null,
      timeoutId: null,
    };
  },
  methods: {
    saveCurrentPageData() {
      if (this.$route.name === "loginPage") return;
      this.$store.commit("pages/addPagesDetail", {
        id: this.$route.name,
        data: this.$refs.activePage.$data,
      });
    },
    handleInterval() {
      this.timeoutId = setTimeout(() => {
        if (this.$refs.activePage.needInterval) {
          this.intervalId = setInterval(() => {
            this.$refs.activePage.getAll(false);
          }, 10000);
        } else {
          clearTimeout(this.timeoutId);
        }
      }, 10000);
    },
  },
  created() {
    if (this.$route.name !== "newPassword") {
      axios
        .get(user.signControl, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("activeLightMode");
          document.documentElement.classList.remove("dark");
          this.$store.commit("signOut");
          this.$router.push("/");
        });
    }

    window.addEventListener("beforeunload", this.saveCurrentPageData);

    if (this.$route.name !== "loginPage") {
      setTimeout(() => {
        const newData = this.$store.state.pages.List.find(
          (item) => item.id == this.$route.name
        )?.data;
        const decompressedData = newData
          ? JSON.parse(LZString.decompress(newData))
          : null;
        if (decompressedData) {
          Object.keys(decompressedData).forEach((key) => {
            this.$refs.activePage.$data[key] = decompressedData[key];
          });
        }
      }, 100);
    }

    this.handleInterval();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforePageRefresh);
  },

  watch: {
    $route(to, from) {
      this.currentPath = to.path;

      if (to.name != "loginPage") {
        this.$store.commit("addTab", {
          id: to.name,
          title: to.meta.name,
          url: to.path,
        });
      }
      const tabIndex = this.$store.state.activeTabs.findIndex(
        (i) => i.url === from.path
      );
      if (tabIndex !== -1) {
        if (from.name == "loginPage" || to.name == "loginPage") return;
        this.$store.commit("pages/addPagesDetail", {
          id: from.name,
          data: this.$refs.activePage.$data,
        });
      }
      setTimeout(() => {
        const newData = this.$store.state.pages.List.find(
          (item) => item.id == to.name
        )?.data;
        const decompressedData = newData
          ? JSON.parse(LZString.decompress(newData))
          : null;
        if (decompressedData) {
          Object.keys(decompressedData).forEach((key) => {
            this.$refs.activePage.$data[key] = decompressedData[key];
          });
        }
      }, 100);
    },
    currentPath() {
      clearInterval(this.intervalId);
      clearTimeout(this.timeoutId);
      this.handleInterval();
    },
  },
};
</script>
