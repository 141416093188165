<template>
  <div class="relative">
    <!-- Buttons for drawing actions -->
    <div class="absolute top-2 right-2 z-10 flex space-x-2">
      <button
        @click="toggleDrawMode"
        class="px-4 py-2 rounded-md shadow-sm text-sm font-medium"
        :class="
          drawingMode ? 'bg-red-500 text-white' : 'bg-white text-gray-700'
        "
      >
        {{ drawingMode ? "Çizim Modunu Kapat" : "Çizim Modunu Aç" }}
      </button>

      <button
        @click="resetPath"
        class="px-4 py-2 rounded-md shadow-sm text-sm font-medium"
        :class="
          pathCoordinates.length ? 'bg-blue-600 text-white' : 'bg-gray-300'
        "
        :disabled="!pathCoordinates.length"
      >
        Temizle
      </button>

      <!-- New Undo Button -->
      <button
        v-if="drawingMode && pathCoordinates.length"
        @click="undoLastPoint"
        class="px-4 py-2 rounded-md shadow-sm text-sm font-medium bg-yellow-500 text-white"
      >
        Son Noktayı Kaldır
      </button>

      <button
        v-if="!isPolygonComplete && pathCoordinates.length > 2"
        @click="() => completePolygon(true)"
        class="px-4 py-2 rounded-md shadow-sm text-sm font-medium bg-green-500 text-white"
      >
        Poligonu Tamamla
      </button>
    </div>

    <GmapMap
      :center="{ lat: Number(lat), lng: Number(lng) }"
      ref="gmap"
      :zoom="12"
      :style="{ width: '100%', height: '70vh' }"
      :options="{
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        clickableIcons: false,
      }"
      @click="handleMapClick"
    >
      <!-- Draw the polyline while clicking points -->
      <GmapPolyline
        v-if="pathCoordinates.length > 1"
        :path="pathCoordinates"
        :options="{
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
          clickable: false,
        }"
      />

      <!-- Line preview while drawing -->
      <GmapPolyline
        v-if="drawingMode && pathCoordinates.length && mousePosition"
        :path="[pathCoordinates[pathCoordinates.length - 1], mousePosition]"
        :options="{
          strokeColor: '#FF0000',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          clickable: false,
        }"
      />

      <!-- Completed polygon -->
      <GmapPolygon
        v-if="isPolygonComplete"
        :paths="pathCoordinates"
        :options="{
          fillColor: '#00FF00',
          fillOpacity: 0.3,
          strokeColor: '#0000FF',
          strokeOpacity: 1.0,
          strokeWeight: 2,
          editable: true,
        }"
      />

      <GmapMarker
        title="Güzergah Noktası"
        :label="{
          text: 'Güzergah Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="{ lat: Number(lat), lng: Number(lng) }"
        :icon="require('@/assets/marker/waypoint.png')"
      />
    </GmapMap>
  </div>
</template>

<script>
import { decode } from "../../../../utils/encoder";

export default {
  name: "route-report-map",
  props: ["polygon", "lat", "lng"],

  data() {
    return {
      pathCoordinates: [],
      drawingMode: false,
      isPolygonComplete: false,
      mousePosition: null,
      mapInstance: null,
      clickListener: null,
      mouseMoveListener: null,
      closeDistance: 0.0001, // Approximately 11 meters at the equator
    };
  },

  methods: {
    // Add new method for undoing last point
    undoLastPoint() {
      if (this.pathCoordinates.length) {
        this.pathCoordinates = this.pathCoordinates.slice(0, -1);
      }
    },

    calculateDistance(point1, point2) {
      const lat1 = point1.lat;
      const lon1 = point1.lng;
      const lat2 = point2.lat;
      const lon2 = point2.lng;

      // Using the Haversine formula
      const R = 6371; // Radius of the Earth in km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in km
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    isCloseToStart(point) {
      if (this.pathCoordinates.length < 3) return false;
      const startPoint = this.pathCoordinates[0];
      const distance = this.calculateDistance(startPoint, point);
      return distance < 0.005; // 5 meters threshold
    },

    toggleDrawMode() {
      this.drawingMode = !this.drawingMode;

      if (!this.drawingMode) {
        this.mousePosition = null;
      }

      if (this.mapInstance) {
        this.mapInstance.setOptions({
          draggableCursor: this.drawingMode ? "crosshair" : null,
        });
      }
    },

    handleMapClick(event) {
      if (!this.drawingMode || this.isPolygonComplete) return;

      const clickedPoint = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      // Check if the clicked point is close to the starting point
      if (
        this.pathCoordinates.length >= 3 &&
        this.isCloseToStart(clickedPoint)
      ) {
        // Complete the polygon using the first point instead of the clicked point
        this.pathCoordinates = [
          ...this.pathCoordinates,
          this.pathCoordinates[0],
        ];
        this.completePolygon(false);
        return;
      }

      // Create a new array instead of mutating the existing one
      this.pathCoordinates = [...this.pathCoordinates, clickedPoint];
    },

    updateMousePosition(event) {
      if (!this.drawingMode || this.isPolygonComplete) return;

      this.mousePosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },

    resetPath() {
      this.pathCoordinates = [];
      this.isPolygonComplete = false;
      this.mousePosition = null;
    },

    completePolygon(isButton) {
      if (this.pathCoordinates.length > 2) {
        if (isButton) {
          this.pathCoordinates = [
            ...this.pathCoordinates,
            this.pathCoordinates[0],
          ];
        }
        this.isPolygonComplete = true;
        this.drawingMode = false;
        this.mousePosition = null;
        this.$emit("handlePolygon", this.pathCoordinates);
      }
    },

    initializeMap() {
      if (!this.$refs.gmap) return;

      this.mapInstance = this.$refs.gmap.$mapObject;

      // Remove any existing listeners
      if (this.clickListener) {
        google.maps.event.removeListener(this.clickListener);
      }
      if (this.mouseMoveListener) {
        google.maps.event.removeListener(this.mouseMoveListener);
      }

      // Add new listeners
      // this.clickListener = this.mapInstance.addListener(
      //   "click",
      //   this.handleMapClick
      // );
      this.mouseMoveListener = this.mapInstance.addListener(
        "mousemove",
        this.updateMousePosition
      );
    },
  },

  mounted() {
    // Wait for map to be ready
    this.$nextTick(() => {
      setTimeout(this.initializeMap, 500);
    });
  },

  beforeDestroy() {
    // Clean up listeners
    if (this.clickListener) {
      google.maps.event.removeListener(this.clickListener);
    }
    if (this.mouseMoveListener) {
      google.maps.event.removeListener(this.mouseMoveListener);
    }
  },

  created() {
    if (this.polygon) {
      try {
        const decodedPolyline = decode(this.polygon).polyline;

        this.pathCoordinates = decodedPolyline;
        this.isPolygonComplete = true;
      } catch (err) {
        console.log("poler", err);
      }
    } else {
      this.$emit("handlePolygon", []);
    }
  },
};
</script>
