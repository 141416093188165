<template>
  <div>
    <div
      class="w-full flex relative"
      :class="
        title &&
        !hideContainer &&
        'border border-gray-300 p-3 pt-4 rounded dark:border-gray-500'
      "
    >
      <h6
        v-if="title || placeHolder"
        class="absolute -top-3 bg-white pr-2 pl-2 dark:text-gray-300 rounded text-black"
        :class="
          lightDark
            ? 'dark:bg-gray-800'
            : placeHolder
            ? 'text-xs mb-5'
            : 'dark:bg-gray-900'
        "
      >
        {{ placeHolder }}
      </h6>

      <gmap-autocomplete
        class="w-full border-gray-200 rounded dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4 bg-white dark:bg-gray-700"
        @place_changed="setPlace"
        :value="adress"
        v-if="!hideAutoComplate && !disabled"
      >
      </gmap-autocomplete>

      <input
        type="text"
        v-if="disabled"
        :disabled="true"
        :value="adress"
        class="w-full bg-gray-100 border-gray-200 rounded dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4 dark:bg-gray-700"
      />
    </div>

    <GmapMap
      v-if="!hideMap"
      :center="position"
      :zoom="12"
      style="width: 100%"
      :style="'height:' + height"
    >
      <GmapMarker
        :position="position"
        :draggable="draggable"
        @dragend="(r) => setPlace(r, true)"
        :clickable="true"
        @click="center = position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "google-maps-input",
  props: {
    hideMap: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideAutoComplate: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeHolder: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "400px",
      required: false,
    },
    lat: {
      type: Number,
      default: 0,
      required: false,
    },
    lng: {
      type: Number,
      default: 0,
      required: false,
    },
    defaultAdress: {
      type: String,
      default: "",
      required: false,
    },
    lightDark: {
      type: Boolean,
      default: false,
      required: false,
    },
    draggable: {
      type: Boolean,
      default: false,
      required: false,
    },

    title: {
      type: String,
      default: "Adres",
      required: false,
    },
    load: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideContainer: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      adress: "",
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
    };
  },
  methods: {
    setPlace(val, dragged = false) {
      const adress = dragged ? this.defaultAdress : val.formatted_address;

      const lat = parseFloat(dragged ? val.latLng.lat() : val.geometry.location.lat());
      const lng = parseFloat(dragged ? val.latLng.lng() : val.geometry.location.lng());

      this.$emit("change", {
        adress: adress,
        lat: lat,
        lng: lng,
      });

      this.adress = adress;
      this.position = {
        lat: lat,
        lng: lng,
      };

      this.center = {
        lat: lat,
        lng: lng,
      };
    },
  },
  created() {
    this.position = { lat: Number(this.lat), lng: Number(this.lng) };
    this.adress = this.defaultAdress;
  },
  watch: {
    defaultAdress(val) {
      this.adress = val;
    },
    lat(val) {
      this.position.lat = Number(val);
    },
    lng(val) {
      this.position.lng = Number(val);
    },
  },
};
</script>

<style></style>
