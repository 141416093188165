<template>
  <diffInput
    v-model="selected"
    :optList="List"
    :title="$t('operations.products.modal.type')"
    type="select"
  />
</template>

<script>
//global components
import diffInput from "@/components/general/diffInput.vue";

//networking
import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "product-types-select",
  props: ["value"],
  components: {
    diffInput,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: "",
      List: [],
      load: false,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(product.getProductTypes, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.List = result.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value ? this.value : "";
    this.getAll();
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.$emit("change", val);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
