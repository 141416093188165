<template>
  <diffInput
    :optList="filterType"
    v-model="select"
    type="select"
    title="İş Durumu"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "geo-filter-input",
  props: ["value"],
  model: {
    event: "change",
    prop: "value",
  },
  components: {
    diffInput,
  },
  data() {
    return {
      select: "",
      filterType: [
        {
          id: 1,
          name: "Tümü",
        },
        {
          id: 2,
          name: "Boşta",
        },
        {
          id: 3,
          name: "Çalışıyor",
        },
      ],
    };
  },
  created() {
    this.select = this.value;
  },
  watch: {
    value(val) {
      this.select = val;
    },
    select(val) {
      if (val) {
        this.$emit("change", val);
      }
    },
  },
};
</script>
