<template>
  <div class="w-full h-screen">
    <form @submit.prevent="save" class="p-10 h-full bg-white dark:bg-gray-900 shadow-sm">
      <div class="w-full">
        <div class="w-full border-b border-gray-400 dark:border-gray-200 p-2 pb-3">
          <h4 class="text-black dark:text-gray-100">
            {{ $t("profile.setPassword") }}
          </h4>
        </div>
      </div>
      <div class="w-full mt-6">
        <diffInput
          :title="$t('profile.modal.oldPassword')"
          v-model="oldpassword"
          type="password"
          :required="true"
        />
      </div>
      <div class="md:flex">
        <div class="w-full md:w-6/12 md:mr-2 mt-4">
          <diffInput
            :title="$t('profile.modal.newPassword')"
            v-model="newpassword"
            type="password"
            :required="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            :title="$t('profile.modal.newPassword')"
            v-model="newRepassword"
            type="password"
            :required="true"
          />
        </div>
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          icon="fas fa-check"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </div>
</template>

<script>
//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";

//Networking
import axios from "axios";
import { user } from "@/networking/urlmanager";
export default {
  name: "password-change-form",
  components: {
    asyncBtn,
    diffInput,
  },
  data() {
    return {
      load: false,
      oldpassword: "",
      newpassword: "",
      newRepassword: "",
    };
  },
  methods: {
    save() {
      this.load = true;
      if (this.newpassword == this.newRepassword) {
        axios
          .post(
            user.setPassword,
            {
              oldpassword: this.oldpassword,
              password: this.newpassword,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.oldpassword = "";
            this.newpassword = "";
            this.newRepassword = "";
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              this.$t("user.saveSetPasswordMessage"),
              false,
              this.$t("general.OkayTitle")
            );
            this.load = false;
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
      } else {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          this.$t("user.passwordNotMatch"),
          false,
          this.$t("general.OkayTitle")
        );
        this.load = false;
      }
    },
  },
};
</script>
