<template>
  <modal
    name="vehicleStatuModal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Headers -->
    <div class="pt-3 pb-2 pr-4 pl-4 text-black">
      <h4 class="inline-block w-11/12 text-xl">Evraklar</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('vehicleStatuModal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Headers -->

    <!-- Body -->
    <form
      @submit.prevent="handleSave"
      ref="formItem"
      v-if="!isLoading"
      class="p-4 bg-white text-black border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full md:mt-4 flex gap-4">
        <diffInput
          title="Yüklenen Tonaj (kg)"
          :max="35000"
          :min="1"
          type="number"
          :disabled="false"
          :required="true"
          v-model="tonnage"
        />
        <diffInput
          title="Araç Km"
          :min="1"
          type="number"
          :disabled="false"
          :required="true"
          v-model="kilometer"
        />
      </div>
      <div class="w-full md:mt-4 flex gap-4">
        <diffInput
          title="İrsaliye Fişi"
          type="file"
          :disabled="false"
          :required="true"
          @getFile="(r) => (image = r)"
        />
        <diffInput
          title="Kantar Fişi"
          type="file"
          :disabled="false"
          :required="false"
          @getFile="(r) => (imageOne = r)"
        />
      </div>
      <div class="w-full mt-4 text-right flex gap-4">
        <diffInput
          title="Diğer Fişler"
          type="file"
          :disabled="false"
          :required="false"
          @getFile="(r) => (imageTwo = r)"
        />
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load || isLoading"
          class="w-full md:h-12 mt-4"
        />
      </div>
    </form>

    <div class="w-full p-14 flex items-center justify-center" v-else>
      <i class="fas fa-spinner  fa-spin fa-3x"></i>
    </div>
    <!-- Body -->
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";
import { vehicleStatus, tracking } from "@/networking/urlmanager";

export default {
  name: "vehicleStatuModal",
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      isLoading: false,
      load: false,
      tonnage: "",
      kilometer: "",
      isOperator: true,
      image: null,
      imageOne: null,
      imageTwo: null,
      imageTotal: [],
      wayId: 0,
      capacity: 0,
      modalData: {},
    };
  },
  methods: {
    handleSave() {
      if (this.tonnage > this.capacity) {
        this.swalBox(
          "warning",
          "UYARI !",
          "Araç kapasitesinden fazla tonaj girdiniz kaydetmek istediğinize emin misiniz?",
          true,
          "Evet,eminim",
          "Hayır Değilim"
        ).then((acc) => {
          if (acc.isConfirmed) {
            this.save();
          } else {
            this.load = false;
          }
        });
      } else {
        this.save();
      }
    },
    save() {
      if (this.modalData.activeStatu == 1) {
        this.isOperator = true;
        this.load = true;
        let formData = new FormData();
        formData.append("tonnage", this.tonnage);
        formData.append("kilometer", this.kilometer);
        formData.append("plaque", this.modalData.detail.vehicle);
        formData.append("planId", "pn-" + this.modalData.detail.id);
        formData.append("isOperator", this.isOperator);
        formData.append("packingSlip", this.image);
        formData.append("packingSlip", this.imageOne);
        formData.append("packingSlip", this.imageTwo);

        axios
          .post(vehicleStatus.edit, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          })
          .then(() => {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.tonnage = "";
              this.kilometer = "";
              this.packingSlip = {};
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("vehicleStatuModal");
            });
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
        return;
      } else if (this.modalData.activeStatu == 2) {     
        this.wayId = 0;
        this.isOperator = true;
        this.load = true;
        let formData = new FormData();
        formData.append("tonnage", this.tonnage);
        formData.append("kilometer", this.kilometer);
        formData.append("plaque", this.modalData.detail.vehicle);
        formData.append("planId", "pn-" + this.modalData.detail.id);
        formData.append("isOperator", this.isOperator);
        formData.append("deliverySlip", this.image);
        formData.append("deliverySlip", this.imageOne);
        formData.append("deliverySlip", this.imageTwo);
        formData.append("wayId", this.wayId);
        axios
          .post(vehicleStatus.completedEdit, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          })
          .then(() => {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.tonnage = "";
              this.kilometer = "";
              this.packingSlip = {};
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("vehicleStatuModal");
            });
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
        return;
      }
    },
    getVehicleData() {
      this.isLoading = true;
      axios
        .get(
          tracking.getAll + "?targetVehicle=" + this.modalData.detail.vehicle,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.capacity = res.data.data[0].capacity;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    show() {
      this.$modal.show("vehicleStatuModal");
      this.getVehicleData();
    },
  },
};
</script>

<style scoped></style>
