<template>
  <div
    class="pb-2 flex items-center justify-between mt-2"
    :class="!lastItem ? 'border-b' : ''"
  >
    <div class="flex items-center">
      <div :title="title" class="text-sm">{{ label }}</div>
      <span v-if="detail" class="text-sm ml-2">({{ detail }})</span>
    </div>
    <span v-if="value" :class="!date ? 'font-bold' : 'font-normal text-sm'">{{
      value
    }}</span>
    <i v-if="iconClass" :class="iconClass"></i>
  </div>
</template>
<script>
export default {
  name: "logButton",
  props: ["label", "value", "iconClass", "title", "lastItem", "detail", "date"],
};
</script>
