<template>
  <ul class="mt-8">
    <!-- Item List -->
    <li class="hover:bg-gray-100 dark:hover:bg-gray-600">
      <div
        class="md:h-12 flex content-start items-center w-full focus:text-orange-500 cursor-pointer"
      >
        <div class="flex items-center w-full">
          <button
            @click="$parent.currentTab = 0"
            :class="
              $parent.currentTab == 0
                ? 'bg-white hover:shadow dark:bg-gray-900 border-l-4 border-gray-900 dark:border-gray-300 text-gray-900'
                : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-600 dark:text-gray-200 text-gray-900'
            "
            class="pl-5 h-12 w-full flex items-center justify-beetwen"
          >
            <div class="w-1/12">
              <i class="fas fa-user mr-2 dark:text-gray-300"></i>
            </div>
            <span class="text-black dark:text-gray-300 w-11/12 text-left ml-4">
              {{ $t("profile.profile") }}
            </span>
          </button>
        </div>
      </div>
    </li>
    <li class="hover:bg-gray-100 dark:hover:bg-gray-600">
      <div
        class="md:h-12 flex content-start items-center w-full focus:text-orange-500 cursor-pointer"
      >
        <div class="flex items-center w-full">
          <button
            @click="$parent.currentTab = 1"
            :class="
              $parent.currentTab == 1
                ? 'bg-white hover:shadow dark:bg-gray-900 border-l-4 border-gray-900 dark:border-gray-300 text-gray-900'
                : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-600 dark:text-gray-200 text-gray-900'
            "
            class="pl-5 h-12 w-full flex items-center justify-beetwen text-black dark:text-gray-300 hover:shadow"
          >
            <i
              class="fas fa-user-edit mr-2 text-gray-900 dark:text-gray-300"
            ></i>
            <span class="text-black dark:text-gray-300 w-11/12 text-left ml-1">
              {{ $t("profile.setProfile") }}
            </span>
          </button>
        </div>
      </div>
    </li>

    <li class="hover:bg-gray-100 dark:hover:bg-gray-600">
      <div
        class="md:h-12 flex content-start items-center w-full focus:text-orange-500 cursor-pointer"
      >
        <div class="flex items-center w-full">
          <button
            @click="$parent.currentTab = 2"
            :class="
              $parent.currentTab == 2
                ? 'bg-white hover:shadow dark:bg-gray-900 border-l-4 border-gray-900 dark:border-gray-300 text-gray-900'
                : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-600 dark:text-gray-200 text-gray-900'
            "
            class="pl-5 h-12 w-full flex items-center justify-beetwen text-black dark:text-gray-300 hover:shadow"
          >
            <div class="w-1/12">
              <i
                class="fas fa-map-marker-alt mr-2 text-gray-900 dark:text-gray-300"
              ></i>
            </div>
            <span class="text-black dark:text-gray-300 w-11/12 text-left ml-4">
              {{ $t("profile.setPassword") }}
            </span>
          </button>
        </div>
      </div>
    </li>

    <li class="hover:bg-gray-100 dark:hover:bg-gray-600">
      <div
        class="md:h-12 flex content-start items-center w-full focus:text-orange-500 cursor-pointer"
      >
        <div class="flex items-center w-full">
          <button
            @click="$parent.currentTab = 3"
            :class="
              $parent.currentTab == 3
                ? 'bg-white hover:shadow dark:bg-gray-900 border-l-4 border-gray-900 dark:border-gray-300 text-gray-900'
                : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-600 dark:text-gray-200 text-gray-900'
            "
            class="pl-5 h-12 w-full flex items-center justify-beetwen text-black dark:text-gray-300 hover:shadow"
          >
            <div class="w-1/12">
              <i
                class="fas fa-envelope mr-2 text-gray-900 dark:text-gray-300"
              ></i>
            </div>
            <span class="text-black dark:text-gray-300 w-11/12 text-left ml-4">
              {{ $t("profile.getFeedback") }}
            </span>
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {};
</script>
