<template>
  <tr class="text-gray-200 text-center" 
  style="background-color: #3a3a3a"
  >
    <td v-if="load" :colspan="colspan" class="p-4">
      <i class="fas fa-spinner fa-spin"></i>
      {{ $t("general.loadingText") }}
    </td>
    <td v-if="!load && length == 0" :colspan="colspan" class="p-4">
      {{ $t("general.notfoundListText") }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "table-loader",
  props: ["load", "length", "colspan"],
};
</script>

<style></style>
