<template>
  <div class="min-h-80 p-2 bg-gray-200 dark:bg-gray-900">
    <div class="w-full">
   
      <LyrausTable
        :data="List"
        :columns="columns"
        :enablePagination="true"
        :isLoading="load"
        :batchOperations="true"
        :alternateRowBg="['white', 'gray-100']"
        :rightExtraSlot="true"
        :indexColumn="true"
        :paginationButtonColor="'red-600'"
        :paginationHoverColor="'red-700'"
        :containerClass="'p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider'"
        :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
      >
        <template v-slot:rowButtons="{ item, columnKey, index }">
          <div v-if="columnKey === 'price'"  >
            <diffInput
              type="price"
              v-model="item.price"
              :hideContainer="true"
              :required="true"
              class="py-0  "
              :hideBorder="true"
              :isTransparent="true"
      
         
            />
          </div>
          <!-- <div v-if="columnKey === 'id'">
            {{ index + 1 }}
          </div> -->
        </template>
        <template v-slot:batchOperations>
          <asyncBtn
            icon="fas fa-check"
            :text="$t('general.saveButtonTitle')"
            :loadTitle="$t('general.saveButtonLoadTitle')"
            :loadState="load"
            @click="() => save()"
            size="py-0"
            class="w-36 btn"
          />
        </template>
      </LyrausTable>
    </div>
   
  </div>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// Netoworking
import axios from "axios";
import { managementCosting } from "@/networking/urlmanager";
export default {
  name: "manamgenet-costing-page",
  components: {

    diffInput,
    asyncBtn,
  },
  data() {
    return {
      List: [],
      load: false,
      columns: [
        // {
        //   key: "id",
        //   label: "#",
        //   dontDisplay: true,
        // },
        {
          key: "name",
          label: "Etiket Adı",
        },
        {
          key: "price",
          label: "Fiyat",
          dontDisplay: true,
        },
      ],
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(managementCosting.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    save() {
      this.load = true;
      axios
        .post(
          managementCosting.save,
          {
            list: JSON.stringify(this.List),
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.getAll();
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
