<template>
  <div
    class="w-full top-0 text-black px-4 align-middle sticky z-30 bg-white dark:bg-gray-800"
  >
    <div class="w-full flex items-center md:justify-between h-16">
      <div class="md:w-10/12 xl:w-6/12 flex justify-start items-center">
        <NavbarItem />
      </div>

      <div class="relative flex items-center xl:w-5/12 w-10/12 justify-end">
        <div
          class="w-3/12 flex justify-end mb-1 mr-3"
          v-if="$store.state.userData.typeId == 2"
        >
          <darkMode :inline="true" />
        </div>

        <button class="w-6 mr-5" @click="() => loadFunc()">
          <img
            :src="
              require($store.state.darkMode
                ? '@/assets/refresh-dark.png'
                : '@/assets/refresh-light.png')
            "
            :class="load && 'fa-spin'"
            class="w-full"
            alt=""
          />
        </button>

        <!-- <feedbackMenu /> -->
        <!-- <span class="relative mr-3 mb-1 xl:w-7 hidden xl:inline-block">
          <i
            class="fas fa-bell text-xl text-gray-600 dark:text-gray-300 text-opacity-80"
          ></i>
          <i
            class="fas fa-dot-circle text-red-600 rounded-full absolute -top-0 right-1.0"
            style="font-size: 10px"
          ></i>
        </span>
        <languagePanel :value="1" /> -->

        <profilePanel />
      </div>
    </div>

    <sectionItem />
  </div>
</template>

<script>
import darkMode from "./components/darkmode.item.vue";
import profilePanel from "./components/profile.panel.vue";
import languagePanel from "./components/languages.item.vue";
import feedbackMenu from "../components/feedback.vue";

import NavbarItem from "./navbar.vue";
import sectionItem from "./section.vue";

export default {
  name: "header-item",
  components: {
    profilePanel,
    languagePanel,
    darkMode,
    feedbackMenu,
    NavbarItem,
    sectionItem,
  },

  data() {
    return {
      load: false,
    };
  },
  methods: {
    loadFunc() {
      this.load = true;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  },
};
</script>
