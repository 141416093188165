var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(!_vm.show)?_c('button',{staticClass:"text-sm xl:text-lg mr-5 opacity-60 dark:text-white",on:{"click":() => (_vm.show = true)}},[_c('i',{staticClass:"fas fa-comment"})]):_vm._e(),(_vm.show)?_c('div',{staticClass:"bg-black dark:bg-gray-700 dark:bg-opacity-50 bg-opacity-50 fixed h-screen w-screen top-0 left-0 right-0 z-50 flex items-center justify-center",on:{"click":() => _vm.hidePanel()}},[_c('form',{staticClass:"px-4 py-2 bg-white dark:bg-gray-900 shadow xl:rounded xl:w-4/12 w-full h-screen xl:h-auto",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)},"mouseover":function($event){_vm.formOver = true},"mouseleave":function($event){_vm.formOver = false}}},[_c('div',{staticClass:"py-2 bg-light-200 flex items-center justify-between border-b pb-2 border-opacity-50 mb-6"},[_c('h5',{staticClass:"m-0 font-bold dark:text-gray-300"},[_vm._v("GERİ BİLDİRİM OLUŞTUR")]),_c('button',{staticClass:"dark:text-gray-300",staticStyle:{"z-index":"9999 !important"},on:{"click":() => _vm.hidePanel(true)}},[_c('i',{staticClass:"fas fa-times"})])]),_c('diffInput',{attrs:{"title":"İşlem Türü","type":"select","optList":[
          {
            id: '1',
            name: 'Hata Bildirimi',
          },
          {
            id: '2',
            name: 'Öneri',
          },
        ]},model:{value:(_vm.typeId),callback:function ($$v) {_vm.typeId=$$v},expression:"typeId"}}),_c('diffInput',{staticClass:"mt-5",attrs:{"title":"Başlık","type":"text"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('diffInput',{staticClass:"mt-5",attrs:{"title":"Açıklama","type":"textarea"},model:{value:(_vm.explanation),callback:function ($$v) {_vm.explanation=$$v},expression:"explanation"}}),_c('div',{staticClass:"w-full mt-4 text-right"},[_c('asyncBtn',{staticClass:"w-full md:w-56",attrs:{"icon":"fas fa-check","text":_vm.$t('general.saveButtonTitle'),"loadTitle":_vm.$t('general.saveButtonLoadTitle'),"loadState":_vm.load}})],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }