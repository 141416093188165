<template>
  <div class="w-full flex items-center justify-between mb-5">
    <button
      @click="activeMenu = 1"
      :class="{
        'bg-red-500 text-white': activeMenu == 1,
        'w-4/12 bg-gray-200': Number(detail?.state) == 1,
        'w-6/12 bg-gray-200': Number(detail?.state) == 0,
      }"
      class="text-xs shadow-sm rounded-l p-2"
    >
      Genel
    </button>
    <button
      @click="activeMenu = 2"
      v-if="Number(detail?.state) == 1"
      :class="
        activeMenu == 2
          ? 'bg-red-500 text-white'
          : Number(detail?.state) == 0
          ? 'bg-gray-50 bg-opacity-60 text-opacity-40 text-black'
          : 'bg-gray-200'
      "
      class="text-xs shadow-sm w-4/12 p-2"
    >
      Mevcut
    </button>
    <button
      @click="activeMenu = 3"
      :class="{
        'bg-red-500 text-white': activeMenu == 3,
        'w-4/12 bg-gray-200': Number(detail?.state) == 1,
        'w-6/12 bg-gray-200': Number(detail?.state) == 0,
      }"
      class="text-xs shadow-sm rounded-r w-4/12 p-2"
    >
      Geçmiş
    </button>
  </div>
</template>

<script>
export default {
  name: "menu-item",
  props: ["detail", "value"],
  model: {
    event: "change",
    prop: "value",
  },
  data() {
    return {
      activeMenu: 1,
    };
  },
  created() {
    this.activeMenu = this.value;
  },
  watch: {
    value(val) {
      this.activeMenu = val;
    },
    activeMenu(val) {
      return this.$emit("change", val);
    },
  },
};
</script>
