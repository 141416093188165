<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `rotate(${rotation.toFixed(4)}deg)` }"
  >
    <g clip-path="url(#clip0_64_3)">
      <path
        d="M95 50C95 74.8528 74.8528 95 50 95C25.1472 95 5 74.8528 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50Z"
        stroke="#0099FF"
        stroke-width="10"
      />
      <circle cx="49.5" cy="49.5" r="32.5" fill="#0099FF" />
      <path d="M50 29L67.3205 66.5H32.6795L50 29Z" fill="white" />
      <path d="M50 52L67.3205 67H32.6795L50 52Z" fill="#0099FF" />
    </g>
    <defs>
      <clipPath id="clip0_64_3">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
  },
};
</script>
