<template>
  <div class="p-5">
    <label
      v-if="load"
      class="text-black text-center p-5 flex items-center justify-center"
    >
      <i class="fas fa-spinner fa-4x fa-spin"></i>
    </label>
    <div v-else>
      <searchInput :isFull="true" v-model="search" />
      <div class="w-full flex mt-4">
        <div class="w-6/12">
          <ListPanel :disabled="true" :List="getFilter" />
        </div>
        <div class="w-6/12">
          <ListPanel countTitle="Seçilen Araç Sayısı" :List="getSelectedList" />
        </div>
      </div>

      <div class="w-full mt-4 text-right py-2">
        <asyncBtn
          @click="saveSelection()"
          :loadState="isSaveButtonClicked"
          text="Kaydet"
          loadTitle="Kayıt Ediliyor"
          size="py-2 px-10"
        />
      </div>
    </div>
  </div>
</template>
<script>
//Npm

// global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import searchInput from "@/components/general/search-input.vue";

//networking
import axios from "axios";
import { authorityModule } from "@/networking/urlmanager";

//Local Components
import ListPanel from "./selected.vue";

//Kullanılmıyor ?
const selectedType = {
  selected: 1,
  all: 2,
};
export default {
  name: "detail-table",
  props: ["rowId"],
  components: {
    asyncBtn,
    searchInput,
    ListPanel,
  },
  data() {
    return {
      load: false,
      List: [],
      isSaveButtonClicked: false,
      search: "",
      selected: selectedType.all,
    };
  },

  methods: {
    async loadData(isSearch = false) {
      if (isSearch) this.currentPage = 0;
      try {
        this.load = true;

        const response = await axios.get(
          authorityModule.getAll + `?userId=${this.rowId}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );

        this.List = response.data.data.map((item) => ({
          vehicle: item.vehicle,
          status: item.status,
          isSelected: item.status === 1,
        }));
      } catch (error) {
        this.errorBox(error.response);
        this.load = false;
      } finally {
        this.load = false;
      }
    },

    updateSelection(item) {
      if (item.isSelected) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    },
    async saveSelection() {
      if (this.isSaveButtonClicked) {
        // Eğer buton zaten tıklandıysa, işlemi tekrarlamayı engelle
        return;
      }

      this.isSaveButtonClicked = true; // Buton tıklandığında true yap

      const newCheckedItems = this.List.filter(
        (item) => item.isSelected && item.status !== 1
      );

      const removedItems = this.List.filter(
        (item) => !item.isSelected && item.status === 1
      );

      try {
        if (newCheckedItems.length > 0) {
          await axios.post(
            authorityModule.add,
            {
              userId: this.rowId,
              vehicles: newCheckedItems.map((item) => item.vehicle),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          );
        }

        if (removedItems.length > 0) {
          await axios.post(
            authorityModule.delete,
            {
              userId: this.rowId,
              vehicles: removedItems.map((item) => item.vehicle),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          );
        }

        this.loadData(); // Verileri tekrar yükle
      } catch (error) {
        console.error(error);
      } finally {
        this.isSaveButtonClicked = false; // İşlem tamamlandığında false yap
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    getFilter() {
      return this.List.filter((item) => {
        return item.vehicle.toLowerCase().includes(this.search.toLocaleLowerCase());
      });
    },
    getSelectedList() {
      return this.List.filter((item) => item.isSelected);
    },
  },
};
</script>
