<template>
  <button
    @click="$modal.show('log')"
    class="w-10 h-10 flex items-center justify-center"
    type="button"
  >
    <!-- bg-white shadow rounded-md border-gray-400 -->
    <img
      title="Aksiyon geçmişini buradan takip edebilirsiniz."
      class="w-5 h-4"
      src="@/assets/log/logikon.png"
      alt=""
    />
  </button>
</template>
<script>
export default {
  name: "logButton",
};
</script>
