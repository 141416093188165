<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <addModal @refresh="getAll" />
    <editModal @refresh="getAll" :detail="selected" />

    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div class="w-full">
        <LyrausTable
          :data="List"
          :columns="columns"
          :enablePagination="true"
          :isLoading="load"
          :batchOperations="true"
          :searchBar="true"
          :searchProp="search"
          @update-search-value="(val) => handleSearchValue(val)"
          :handleSearch="handleSearch"
          :pageCount="pageCount"
          :pageProp="currentPage"
          @update-current-page="handleCurrentPage"
          :totalCount="totalCount"
          :alternateRowBg="['white', 'gray-100']"
          :rightExtraSlot="true"
          :buttonsColumn="{ label: 'İşlem' }"
          :paginationButtonColor="'red-600'"
          :paginationHoverColor="'red-700'"
          :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
          :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
          :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
        >
          <template v-slot:rowButtons="{ item, columnKey }">
            <div v-if="columnKey === 'plaque'">
              <p>{{ item.plaque || "---------" }}</p>
            </div>
            <div v-if="columnKey === 'notifyDeviceId'">
              <p :class="item.notifyDeviceId ? '' : 'text-red-400'">
                {{ item.notifyDeviceId ? "Aktif" : "Pasif" }}
              </p>
            </div>
          </template>
          <template v-slot:batchOperations>
            <asyncBtn
              icon="fas fa-plus "
              text="Yeni Sürücü"
              class="w-36 btn"
              @click="() => $modal.show('add-personnel-modal')"
            />
          </template>
          <template v-slot:colButtons="{ item }">
            <div class="flex items-center justify-end space-x-2 px-2">
              <button class=" mr-2" @click="showEditModal(item)">
                <i class="fas fa-pen text-gray-700"></i>
              </button>

              <button
                class=""
                @click="deleteItem(item)"
                :title="
                  item.activeState == 2
                    ? 'Tekrar Aktif Hale Getir'
                    : 'Personel Çıkar'
                "
              >
                <i
                  class="fas fa-trash text-gray-700"
                  v-if="item.activeState == 1"
                ></i>
                <i
                  class="fas fa-reply text-gray-700"
                  v-if="item.activeState == 2"
                ></i>
              </button>
            </div>
          </template>
        </LyrausTable>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// networking
import axios from "axios";
import { personnelManagement } from "@/networking/urlmanager";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
export default {
  name: "personnel-module",
  components: {
    asyncBtn,
    addModal,
    editModal,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      columns: [
        {
          key: "fullname",
          label: "Çalışan",
        },
        {
          key: "tcno",
          label: "Tc",
        },
        {
          key: "phone",
          label: "Telefon Numarası",
        },
        {
          key: "plaque",
          label: "Araç Plakası",
          dontDisplay: true,
        },
        {
          key: "notifyDeviceId",
          label: "Sisteme Giriş Durumu",
          dontDisplay: true,
        },
      ],
    };
  },
  methods: {
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-personnel-modal");
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
      this.getAll(true);
    },
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;
      this.List = [];
      this.load = true;
      axios
        .get(
          personnelManagement.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Personeli Silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              personnelManagement.delete,
              {
                rowId: item.id.toString(),
                tcno: item.tcno,
                fullname: item.fullname,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
