<template>
  <modal
    name="edit-user-perm-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="w-full flex items-center justify-center">
        <button
          @click="active = 1"
          class="w-6/12 py-2"
          :class="active == 1 ? 'border-b-2 border-black' : ''"
        >
          GENEL YETKİLER
        </button>
        <button
          @click="active = 2"
          class="w-6/12 py-2"
          :class="active == 2 ? 'border-b-2 border-black' : ''"
        >
          ARAÇ BAZLI YETKİLER
        </button>
        <div class="w-2/12 text-right">
          <button class="p-2 w-1/12" @click="$modal.hide('edit-user-perm-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <vehicleAuthority v-if="active == 2" :rowId="rowId" />
    <authorityPage v-if="active == 1" :rowId="rowId" />
  </modal>
</template>
<script>
//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";

// local components
import vehicleAuthority from "./vehicles/index.vue";
import authorityPage from "./users/index.vue";
export default {
  name: "authority-modal",
  props: ["rowId"],
  components: {
    diffInput,
    asyncBtn,
    vehicleAuthority,
    authorityPage,
  },
  data() {
    return {
      active: 1,
    };
  },
};
</script>
