<template></template>
<script>
// Networking
import axios from "axios";
import { serviceRequests } from "../../../networking/urlmanager";

export default {
  name: "serviceRequestController",
  data() {
    return {
      note: "",
    };
  },
  methods: {
    handleUpdateserviceRequest(params) {
      axios
        .post(serviceRequests.updateserviceRequest, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.checkserviceRequest();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startInterval() {
      this.$store.commit(
        "setserviceRequestIntervalId",
        setInterval(() => {
          this.checkserviceRequest();
        }, 60000)
      );
    },
    getRequestTypeName(requestType) {
      switch (requestType) {
        case "1":
          return "Kaza";
        case "2":
          return "Arıza";
        case "3":
          return "Bakım";
        case "4":
          return "Diğer";
        default:
          return "Bilinmeyen Durum";
      }
    },
    checkserviceRequest() {
      axios
        .get(serviceRequests.getserviceRequests, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          if (res.data.data.length) {
            clearInterval(this.$store.state.serviceRequestIntervalId);
            const serviceRequest = res.data.data[0];
            let noteInput;
            this.$swal
              .fire({
                icon: "warning",
                title: this.$t("general.warningTitle"),
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Onayla",
                cancelButtonText: "Kapat",
                customClass: {
                  popup: "w-1/2",
                },
                html: `
                  <div class="flex flex-col items-center">
                  <div class="p-4">
                      <div class="bg-white rounded-lg shadow-md overflow-hidden">
                        <div class="border-b border-gray-100 p-4 flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                          <h2 class="text-lg font-semibold text-gray-900">Acil Durum Bildirimi</h2>
                        </div>

                        <div class="p-6">
                          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sürücü Adı</p>
                              <p class="text-base font-semibold text-gray-900">${
                                serviceRequest.driverName
                              }</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sürücü TC</p>
                              <p class="text-base font-semibold text-gray-900">${
                                serviceRequest.driverTc
                              }</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sipariş Numarası</p>
                              <p class="text-base font-semibold text-gray-900">${
                                serviceRequest.orderNo
                              }</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Araç Plaka</p>
                              <p class="text-base font-semibold text-gray-900">${
                                serviceRequest.plate
                              }</p>
                            </div>

                            <div class="col-span-full space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sorun</p>
                              <p class="text-base font-semibold text-red-600">${this.getRequestTypeName(
                                serviceRequest.requestType
                              )}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input id="note" class="swal2-input w-full" placeholder="Açıklama">
                  </div>`,
                didOpen: () => {
                  const popup = this.$swal.getPopup();
                  noteInput = popup.querySelector("#note");
                  noteInput.onkeyup = (event) =>
                    event.key === "Enter" && Swal.clickConfirm();
                },
                preConfirm: () => {
                  this.note = noteInput.value;
                },
              })
              .then((acc) => {
                if (acc.isConfirmed) {
                  const params = {
                    rowId: serviceRequest.id,
                    action: this.note,
                  };
                  this.handleUpdateserviceRequest(params);
                }
                this.startInterval();
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.$store.state.userData.token) {
      this.startInterval();
    }
  },
};
</script>
