var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute top-2 right-2 z-10 flex space-x-2"},[_c('button',{staticClass:"px-4 py-2 rounded-md shadow-sm text-sm font-medium",class:_vm.drawingMode ? 'bg-red-500 text-white' : 'bg-white text-gray-700',on:{"click":_vm.toggleDrawMode}},[_vm._v(" "+_vm._s(_vm.drawingMode ? "Çizim Modunu Kapat" : "Çizim Modunu Aç")+" ")]),_c('button',{staticClass:"px-4 py-2 rounded-md shadow-sm text-sm font-medium",class:_vm.pathCoordinates.length ? 'bg-blue-600 text-white' : 'bg-gray-300',attrs:{"disabled":!_vm.pathCoordinates.length},on:{"click":_vm.resetPath}},[_vm._v(" Temizle ")]),(_vm.drawingMode && _vm.pathCoordinates.length)?_c('button',{staticClass:"px-4 py-2 rounded-md shadow-sm text-sm font-medium bg-yellow-500 text-white",on:{"click":_vm.undoLastPoint}},[_vm._v(" Son Noktayı Kaldır ")]):_vm._e(),(!_vm.isPolygonComplete && _vm.pathCoordinates.length > 2)?_c('button',{staticClass:"px-4 py-2 rounded-md shadow-sm text-sm font-medium bg-green-500 text-white",on:{"click":() => _vm.completePolygon(true)}},[_vm._v(" Poligonu Tamamla ")]):_vm._e()]),_c('GmapMap',{ref:"gmap",style:({ width: '100%', height: '70vh' }),attrs:{"center":{ lat: Number(_vm.lat), lng: Number(_vm.lng) },"zoom":12,"options":{
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      clickableIcons: false,
    }},on:{"click":_vm.handleMapClick}},[(_vm.pathCoordinates.length > 1)?_c('GmapPolyline',{attrs:{"path":_vm.pathCoordinates,"options":{
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        clickable: false,
      }}}):_vm._e(),(_vm.drawingMode && _vm.pathCoordinates.length && _vm.mousePosition)?_c('GmapPolyline',{attrs:{"path":[_vm.pathCoordinates[_vm.pathCoordinates.length - 1], _vm.mousePosition],"options":{
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        clickable: false,
      }}}):_vm._e(),(_vm.isPolygonComplete)?_c('GmapPolygon',{attrs:{"paths":_vm.pathCoordinates,"options":{
        fillColor: '#00FF00',
        fillOpacity: 0.3,
        strokeColor: '#0000FF',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        editable: true,
      }}}):_vm._e(),_c('GmapMarker',{attrs:{"title":"Güzergah Noktası","label":{
        text: 'Güzergah Noktası',
        color: 'white',
        className:
          'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      },"position":{ lat: Number(_vm.lat), lng: Number(_vm.lng) },"icon":require('@/assets/marker/waypoint.png')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }