<template>
  <modal
    name="edit-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.planning.modal.editTitle") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-plan-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderNo"
            title="Sipariş Numarası"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderName"
            title="Sipariş Adı"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="complateDate"
            title="Kapanış Tarihi"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
          />
        </div>
      </div>

      <div class="md:flex" v-if="false">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput type="text" title="Gönderici" v-model="receivedName" />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput type="text" title="Alıcı" v-model="deliveredName" />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <gmapInputVue
            @change="(r) => changeFillingPoint(r)"
            :defaultAdress="fillingPoint.adress"
            :lat="fillingPoint.lat"
            :lng="fillingPoint.lng"
            placeHolder="Gönderici"
            height="150px"
            :hideMap="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <gmapInputVue
            @change="(r) => changeDeliveryPoint(r)"
            :defaultAdress="deliveryPoint.adress"
            :lat="deliveryPoint.lat"
            :lng="deliveryPoint.lng"
            placeHolder="Alıcı"
            height="150px"
            :hideMap="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
          />
        </div>
        <div class="w-full mt-4 md:w-4/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            v-model="amount"
            title="Miktar"
          />
        </div>
        <div class="w-full mt-4 md:w-2/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            title="Birim"
            v-model="unitType"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="datetime-local"
            v-model="fillingDate"
            :max="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.fillingTime')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="fillingDate"
            type="datetime-local"
            v-model="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.deliveryTime')"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            // changeLocationState &&
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          @changeStartPosition="(val) => (fillingPoint = val)"
          @changeEndPosition="(val) => (deliveryPoint = val)"
          @changeOutpointId="(r) => (receivedName = r)"
          @changeCustomerId="(r) => (deliveredName = r)"
          @changePolyline="(r) => changePolyline(r)"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'200px'"
          @change="(r) => (costDistribution = r)"
        />
      </div>

      <div class="md:flex mt-5">
        <div class="w-full md:w-full relative">
          <i
            class="fas fa-exchange-alt absolute right-4 bg-white dark:bg-gray-900 px-2 py-1 -top-3 z-50 cursor-pointer"
            @click="() => (vehicleTypeId = vehicleTypeId == 1 ? 2 : 1)"
          ></i>

          <vehicleInput
            :value="vehicle"
            @changeName="(val) => (vehicle = val)"
            :bulkControl="true"
            :center="fillingPoint"
            :recomendedState="true"
            :title="$t('operations.planning.modal.vehicle')"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12 relative">
          <diffInput
            type="text"
            v-model="dorse"
            :required="true"
            :title="$t('operations.planning.modal.trailer')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            v-model="personnel"
            :required="true"
            :title="$t('operations.planning.modal.driver')"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="number"
            v-model="hakedisKilometer"
            :required="true"
            :disabled="true"
            title="Tek yön Kilometresi"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <diffInput
            type="number"
            v-model="transitionCount"
            :required="true"
            title="Ay içi Sefer Sayısı"
            :max="99"
            :min="1"
          />
        </div>
        <!-- v-model="" -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            :value="Money(hakedisKilometer * transitionCount * 2)"
            :required="true"
            :disabled="true"
            title="Toplam Kilometre"
          />
        </div>
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";

// Networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";

// Local Components
import routeViewiver from "@/components/devItem/route-generator/index.vue";

export default {
  name: "edit-plan-modal",
  props: ["rowId"],
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
  },
  data() {
    return {
      orderNo: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      personnel: "",
      vehicle: "",
      dorse: "",
      driver: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      order: "",

      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      polyline: "",

      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 0,
      expenseDetail: {},

      getLoad: false, // gereksiz state
      changeLocationState: false,
      load: false,
    };
  },
  methods: {
    getDetail() {
      this.getLoad = true;
      axios
        .get(planning.getSingle + "?rowId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          const {
            orderNo,
            orderName,
            orderDate,
            complateDate,
            customer,
            invoicedCompany,
            receivedName,
            deliveredName,
            productType,
            amount,
            unitType,
            fillingDate,
            deliveryDate,
            kilometer,
            transitionCount,
            driver,
            vehicle,
            dorse,
            expensePrice,
            totalPrice,
            explanation,
            polyline,
            outpointLat,
            outpointLng,
            outpointAdress,
            targetPointLat,
            targetPointLng,
            targetAdress,
            dayCount,
            isAmortisman,
            isOneWay,
          } = result.data.data;
          // for calculation paramss
          this.order = result.data.data;

          this.orderNo = orderNo;
          this.orderName = orderName;
          this.orderDate = orderDate;
          this.complateDate = complateDate;
          this.customer = customer;
          this.invoicedCompany = invoicedCompany;
          this.receivedName = receivedName;
          this.deliveredName = deliveredName;

          this.productType = productType;
          this.amount = amount;
          this.unitType = unitType;
          this.fillingDate = fillingDate;
          this.deliveryDate = deliveryDate;
          this.transitionCount = transitionCount;
          this.personnel = driver;
          this.vehicle = vehicle;
          this.dorse = dorse;
          this.explanation = explanation;
          this.expensivePrice = expensePrice;
          this.polyline = polyline;
          // this.expenseDetail={};
          this.dayCount = dayCount;
          this.totalPrice = totalPrice;
          this.isAmortisman = isAmortisman;
          this.isOneWay = isOneWay;

          this.costDistribution = { cost: { cash: 0 } };
          this.fillingPoint = {
            lat: parseFloat(outpointLat),
            lng: parseFloat(outpointLng),
            adress: outpointAdress,
          };

          this.deliveryPoint = {
            lat: parseFloat(targetPointLat),
            lng: parseFloat(targetPointLng),
            adress: targetAdress,
          };

          this.hakedisKilometer = kilometer;
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
    resetAll() {
      this.orderNo = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.productType = "";
      this.amount = "";
      this.unitType = "";
      this.fillingDate = "";
      this.deliveryDate = "";
      this.hakedisKilometer = "";
      this.transitionCount = 1;
      this.personnel = "";
      this.vehicle = "";
      this.dorse = "";
      this.costDistribution = { cost: { cash: 0 } };
      this.recomendPrice = "";
      this.realSalePrice = "";
      this.explanation = "";
      this.expensivePrice = 0;
      this.polyline = "";
      this.expenseDetail = {};
    },
    save() {
      this.load = true;
      axios
        .post(
          planning.edit,
          {
            rowId: this.rowId,
            orderNo: this.orderNo,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,
            receivedName: this.receivedName,
            deliveredName: this.deliveredName,
            productType: this.productType,
            amount: this.amount,
            unitType: this.unitType,
            fillingDate: this.fillingDate,
            deliveryDate: this.deliveryDate,

            vehicle: this.vehicle,
            dorse: this.dorse,
            driver: this.personnel,

            kilometer: this.hakedisKilometer,

            expensePrice: this.expensivePrice,
            totalPrice: this.totalPrice,
            explanation: this.explanation,
            polyline: this.polyline,

            outpointAdress: this.fillingPoint.adress,
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetpointAdress: this.deliveryPoint.adress,
            targetpointLat: this.deliveryPoint.lat,
            targetpointLng: this.deliveryPoint.lng,

            // expense detail
            tollsPrice: (
              this.costDistribution.cost.cash *
              this.transitionCount *
              2
            ).toFixed(2),
            fuelPrice: this.expenseDetail.fuelTotalPrice.toFixed(2),
            adbluePrice: this.expenseDetail.totalAdbluePrice.toFixed(2),
            whellPrice: this.expenseDetail.totalWhellPrice.toFixed(2),
            engineOilPrice: this.expenseDetail.totalEngineOilPrice.toFixed(2),
            filtersPrice: this.expenseDetail.TotalfilterPrice.toFixed(2),
            brakepadPrice: this.expenseDetail.totalBrakePadPrice.toFixed(2),
            diskPrice: this.expenseDetail.totalDiskPrice.toFixed(2),
            clutchPrice: this.expenseDetail.totalClutchPrice.toFixed(2),
            periodMainstancePrice:
              this.expenseDetail.totalperiodicMaintenanceCost.toFixed(2),
            TrafficInsuranceAndinsurancePrice:
              this.expenseDetail.totalTrafficInsurancePriceAndinsurancePrice.toFixed(
                2
              ),
            otherCost: this.expenseDetail.otherCost.toFixed(2),

            isAmortisman: this.isAmortisman,
            isOneWay: this.isOneWay,
            dayCount: this.dayCount,
            transitionCount: this.transitionCount,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetAll();
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;

      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    async calculatePrice() {
      let montlyKilometer = this.hakedisKilometer * this.transitionCount * 2;
      let hgsPrice =
        parseFloat(this.costDistribution.cost.cash) * this.transitionCount * 2;
      if (this.isOneWay == 1) {
        montlyKilometer = this.hakedisKilometer * this.transitionCount;
        hgsPrice = parseFloat(this.costDistribution.cost.cash);
      }

      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        this.vehicle,
        this.order,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total } = this.expenseDetail;

      this.expensivePrice = total;
    },
  },
  watch: {
    async transitionCount() {
      await this.calculatePrice();
    },
    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },
    async isAmortisman() {
      await this.calculatePrice();
    },
    isOneWay() {
      this.calculatePrice();
    },
    dayCount() {
      this.calculatePrice();
    },
  },
};
</script>
