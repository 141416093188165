import moment from "moment/moment";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import pages from "./pages";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    raporPeriot: {
      startDate: "",
      endDate: "",
    },

    mapView: false,

    personnelList: [],
    personnelCacheDate: null,
    activeTabs: [],

    navState: true,
    darkMode: false,
    lockNavState: true,
    firstAuth: true,
    isAuth: false,
    sosIntervalId: 0,
    LTTIntervalId: 0,
    serviceRequestIntervalId: 0,
    userData: {
      id: "",
      fullname: "",
      email: "",
      token: "",
      typeId: 1,
      explanation: "",
      profileImage: "",
    },
    ongoingOrders: [],

    routeEngineToken: "us-5YBVJY3CSOIEO72FV7E9DB22JNYNWNEG",
  },
  mutations: {
    updateActiveTabsList(state, val) {
      state.activeTabs = val;
    },
    addTab(state, val) {
      if (val && !state.activeTabs?.find((r) => r.key == val?.id)) {
        state.activeTabs.push({
          label: val.title,
          key: val.id,
          url: val.url,
        });
      }
    },
    removeTab(state, val) {
      const findIndex = state.activeTabs.findIndex((i) => i == val.item);
      if (findIndex > -1) {
        state.activeTabs.splice(findIndex, 1);
        if (val.item.url === val.currentRoute) {
          if (findIndex === 0) {
            router.push(state.activeTabs[findIndex].url);
          } else {
            router.push(state.activeTabs[findIndex - 1].url);
          }
        }
      }
    },
    clearActiveTab(state) {
      state.activeTabs = [];
    },

    setPersonnelList(state, val) {
      state.personnelList = val;
      state.personnelCacheDate = moment();
    },

    toggleMapStatus(state) {
      state.mapView = !state.mapView;
    },
    setSOSIntervalId(state, data) {
      state.sosIntervalId = data;
    },
    setLTTIntervalId(state, data) {
      state.LTTIntervalId = data;
    },
    setserviceRequestIntervalId(state, data) {
      state.serviceRequestIntervalId = data;
    },
    setPeriot(state, data) {
      state.raporPeriot = data;
    },
    setExchangePrice(state, data) {
      state.exchangePrice = data;
    },
    signIn(state, data) {
      state.userData = data;
      state.isAuth = true;
    },
    signOut(state) {
      state.userData = {
        id: "",
        fullname: "",
        email: "",
        token: "",
        typeId: 1,
      };
      state.isAuth = false;
    },
    setProfile(state, data) {
      state.userData.fullname = data.fullname;
      state.userData.email = data.email;
      state.userData.phone = data.phone;
      state.userData.explanation = data.explanation;
    },
    setProfileImage(state, data) {
      state.userData.profileImage = data;
    },

    activeDarkMode(state) {
      state.darkMode = true;
    },
    activeLightMode(state) {
      state.darkMode = false;
    },

    activeFirstAuth(state) {
      state.firstAuth = true;
    },
    complateFirstAuth(state) {
      state.firstAuth = false;
    },

    setNavState(state) {
      state.navState = !state.navState;
      state.lockNavState = state.navState;
    },

    hideNavState(state) {
      state.navState = false;
    },

    setMouseState(state, val) {
      if (!state.lockNavState) state.navState = val;
    },
    setOngoingOrder(state, val) {
      state.ongoingOrders = [...state.ongoingOrders, val];
    },
    deleteOngoingOrder(state, val) {
      const newOngoingOrders = state.ongoingOrders.filter(
        (ongoingOrder) => ongoingOrder !== val
      );
      state.ongoingOrders = newOngoingOrders;
    },
  },
  actions: {},
  modules: {
    pages,
  },
  plugins: [createPersistedState()],
});
