<template>
  <modal
    name="add-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '90%'"
    :scrollable="true"
    @opened="openModal()"
    :clickToClose="false"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ GEMİ TAHLİYESİ</h4>

      <button class="p-2 w-1/12" @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="nextActive">
      <stepOne
        v-if="activePage == 1"
        @estimatedTimes="(val) => (dateValue = val)"
        @minVehicleCount="(val) => (minVehicleCount = val)"
        ref="stepOne"
        @insertId="(r) => insertId(r)"
      />
      <stepTwo
        v-if="activePage == 2"
        ref="stepTwo"
        :orderCount="orderCount"
        :shipId="shipId"
        :isAdd="isNewOrder"
        :minVehicleCount="minVehicleCount"
        @changeList="(val) => (orderList = val)"
        :dates="dateValue"
      />
      <stepThree
        v-if="activePage == 3"
        :shipId="shipId"
        :orders="orderList"
        ref="stepThree"
        :isLaterAddOrder="isLaterAddOrder"
      />

      <div
        class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
      >
        <div class="flex w-full h-16 items-center px-4">
          <div class="w-5/12">
            <div class="w-6/12"></div>
          </div>
          <div class="w-2/12">
            <p class="text-center font-bold textcol">
              {{ isNewOrder ? activePage - 1 : activePage }} /
              {{ isNewOrder ? "2" : "3" }}
            </p>
          </div>
          <div class="w-5/12 flex justify-end">
            <div class="w-6/12">
              <asyncBtn
                icon=""
                :text="activePage ? 'KAYDET VE DEVAM ET' : 'KAYDET'"
                :loadState="load"
                class="w-full py-1 mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// Local Components
import stepOne from "./components/stepOne.vue";
import stepTwo from "./components/stepTwo.vue";
import stepThree from "./components/stepThree/index";
export default {
  name: "add-plan-modal",
  props: ["isNewOrder", "orderCount", "isLaterAddOrder"],
  components: {
    asyncBtn,
    stepOne,
    stepTwo,
    stepThree,
  },

  data() {
    return {
      shipId: "",
      activePage: 1,
      dateValue: {
        filling: 10,
        delivery: 10,
      },
      minVehicleCount: 0,
      orderList: [],
      load: false,
    };
  },

  methods: {
    insertId(insertId) {
      this.shipId = insertId;
    },
    nextActive() {
      if (this.activePage == 1) {
        this.load = true;
        this.$refs.stepOne.save((status) => {
          this.load = false;
          if (status) {
            this.activePage++;
          }
        });
        this.$emit("refresh", true);
      } else if (this.activePage == 2) {
        this.load = true;

        this.$refs.stepTwo.save((status) => {
          if (status) {
            this.activePage++;
          }
          this.load = false;
        });
        this.$emit("refresh", true);
      } else {
        this.load = true;
        this.$refs.stepThree.save((status) => {
          if (status === true) {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "Gemi Başarıyla eklendi",
              false,
              this.$t("general.OkayTitle")
            );
            this.$modal.hide("add-plan-modal");
            this.$emit("refresh", true);
          }
          this.load = false;
        });
      }
    },

    // backActive() {
    //   if (this.activePage <= 3 && this.activePage > 1) {
    //     if (this.activePage == 3) {
    //       this.$refs.stepThree.show();
    //     }
    //     this.activePage--;
    //   }
    // },

    openModal() {
      this.activePage = this.isNewOrder ? 2 : 1;
      this.shipId = this.isNewOrder;
      this.recomendedError = "";
    },
    close() {
      this.$modal.hide("add-plan-modal");
      this.$emit("refresh", true);
    },

    // checkIfValid() {
    //   if (this.recomendedVehicleCount < 0) {
    //     this.swalBox(
    //       "warning",
    //       "HATA",
    //       "Hata: Sayı negatif olamaz!...",
    //       false,
    //       this.$t("general.OkayTitle")
    //     );
    //     // veya başka bir hata mesajı veya işlem yapabilirsiniz
    //   }
    // },
  },
  watch: {
    isNewOrder(val) {
      this.shipId = val;
      this.activePage = 2;
    },
  },
};
</script>
