<template>
  <div class="mr-2 ml-2 flex relative" @mouseover="showModal()" @mouseleave="hideModal()">
    <button class="relative text-right">
      <label class="flex items-center cursor-pointer text-sm dark:text-gray-200">
        <i class="fas fa-user-alt text-xl text-gray-600"></i>
        <div class="mr-1 ml-2">
          <b class="block m-0 mb-0 text-xs text-left">
            {{ $store.state.userData.fullname }}</b
          >
          <span class="block m-0 text-left text-gray-500" style="font-size: 10px">{{
            $store.state.userData.position ? $store.state.userData.position : "Notfound"
          }}</span>
        </div>
        <svg
          class="h-5 w-5 text-black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="black"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </label>
    </button>

    <div
      v-if="show"
      class="absolute top-8 -left-8 bg-white shadow"
      style="z-index: 999 !important; width: 120px"
    >
      <router-link
        to="/profile"
        class="px-2 py-2 w-full text-sm capitalize text-gray-800 dark:text-gray-100 hover-custom-bg hover:text-gray-500 flex justify-between cursor-pointer"
      >
        <i class="fas fa-user"></i> Profil
      </router-link>
      <button
        @click="() => signOut()"
        class="px-2 py-2 w-full text-sm capitalize text-gray-800 dark:text-gray-100 hover-custom-bg hover:text-gray-500 flex justify-between cursor-pointer"
      >
        <i class="fas fa-sign-out-alt"></i>
        Çıkış Yap
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-panel",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
  },
};
</script>

<style></style>
