<template>
  <span>
    <button
      @click="() => (show = true)"
      v-if="!show"
      class="text-sm xl:text-lg mr-5 opacity-60 dark:text-white"
    >
      <i class="fas fa-comment"></i>
    </button>

    <div
      class="bg-black dark:bg-gray-700 dark:bg-opacity-50 bg-opacity-50 fixed h-screen w-screen top-0 left-0 right-0 z-50 flex items-center justify-center"
      v-if="show"
      @click="() => hidePanel()"
    >
      <form
        @submit.prevent="save"
        class="px-4 py-2 bg-white dark:bg-gray-900 shadow xl:rounded xl:w-4/12 w-full h-screen xl:h-auto"
        @mouseover="formOver = true"
        @mouseleave="formOver = false"
      >
        <div
          class="py-2 bg-light-200 flex items-center justify-between border-b pb-2 border-opacity-50 mb-6"
        >
          <h5 class="m-0 font-bold dark:text-gray-300">GERİ BİLDİRİM OLUŞTUR</h5>
          <button
            class="dark:text-gray-300"
            @click="() => hidePanel(true)"
            style="z-index: 9999 !important"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>

        <diffInput
          v-model="typeId"
          title="İşlem Türü"
          type="select"
          :optList="[
            {
              id: '1',
              name: 'Hata Bildirimi',
            },
            {
              id: '2',
              name: 'Öneri',
            },
          ]"
        />
        <diffInput v-model="title" title="Başlık" type="text" class="mt-5" />
        <diffInput v-model="explanation" title="Açıklama" type="textarea" class="mt-5" />

        <div class="w-full mt-4 text-right">
          <asyncBtn
            icon="fas fa-check"
            :text="$t('general.saveButtonTitle')"
            :loadTitle="$t('general.saveButtonLoadTitle')"
            :loadState="load"
            class="w-full md:w-56"
          />
        </div>
      </form>
    </div>
  </span>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";
import { user } from "@/networking/urlmanager";
export default {
  name: "feedback-menu",
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      show: false,
      formOver: false,
      load: false,
      typeId: "",
      title: "",
      explanation: "",
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          user.feedback.add,
          {
            typeId: this.typeId.toString(),
            title: this.title,
            explanation: this.explanation,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.typeId = "";
            this.title = "";
            this.explanation = "";
            this.load = false;
            this.show = false;
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    hidePanel(isClosedBtn = false) {
      if (!this.formOver || isClosedBtn) this.show = false;
    },
  },
};
</script>
