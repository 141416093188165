<template>
  <div class="h-screen bg-white md:py-24 py-8" align="center">
    <img
      class="md:w-4/12 max-sm:w-full pb-4 sm:px-10 md:px-0"
      src="@/assets/auth/güneyLogo.png"
      alt=""
    />
    <template v-if="token">
      <h2
        class="md:text-4xl text-3xl font-bold mb-2 mt-20"
        style="color: #261f53"
      >
        YENİ PAROLA BELİRLE
      </h2>
      <h6 class="text-sm mt-4" style="color: #261f53">
        Lütfen yeni parolanızı belirleyiniz
      </h6>
      <form
        class="md:px-20 px-10 mt-6 sm:w-full md:w-7/12"
        @submit.prevent="save"
      >
        <div class="w-full text-left mt-5" align="center">
          <label style="color: #a8a8a8"> Yeni Parola </label>
          <div class="relative">
            <input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="form-password focus:ring-blue-500 focus:border-blue-500 w-full p-2 mt-2 rounded shadow border-indigo-900 border"
              required
            />
            <button
              type="button"
              class="absolute right-3 top-5 text-gray-500"
              @click="showPassword = !showPassword"
            >
              <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
          <div
            v-if="password"
            class="text-xs mt-1"
            :class="{
              'text-green-500': isStrongPassword,
              'text-red-500': !isStrongPassword,
            }"
          >
            <div>Parola en az:</div>
            <div>- 3 karakter</div>
            <!-- <div>- 1 büyük harf</div>
            <div>- 1 küçük harf</div>
            <div>- 1 rakam içermelidir</div> -->
          </div>
        </div>

        <div class="w-full text-left mt-5" align="center">
          <label style="color: #a8a8a8"> Yeni Parola Tekrar </label>
          <div class="relative">
            <input
              v-model="passwordConfirm"
              :type="showPasswordConfirm ? 'text' : 'password'"
              class="form-password focus:ring-blue-500 focus:border-blue-500 w-full p-2 mt-2 rounded shadow border-indigo-900 border"
              required
            />
            <button
              type="button"
              class="absolute right-3 top-5 text-gray-500"
              @click="showPasswordConfirm = !showPasswordConfirm"
            >
              <i
                :class="showPasswordConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"
              ></i>
            </button>
          </div>
          <div
            v-if="passwordConfirm && password !== passwordConfirm"
            class="text-red-500 text-xs mt-1"
          >
            Parolalar eşleşmiyor
          </div>
        </div>

        <div class="w-full mt-2">
          <asynBtn
            type="submit"
            :bgDark="true"
            :loadState="load"
            loadTitle="Gönderiliyor"
            containerClass="w-full mt-5 asynBtn"
            icon="fas fa-save"
            text="Kaydet"
            style="background-color: #261f53"
            :disabled="!isValidForm"
          />
        </div>
      </form>
    </template>
    <template v-else>
      <div class="mt-20 text-red-500">
        <h2 class="text-2xl font-bold mb-4">
          Geçersiz veya Süresi Dolmuş Link
        </h2>
        <p>Parola sıfırlama bağlantısı geçersiz veya süresi dolmuş.</p>
      </div>
    </template>
    <div class="w-full mt-5 mb-5 text-center">
      <button
        class="hover:text-blue-900"
        type="button"
        @click="$router.push('/')"
      >
        <i class="fas fa-arrow-left"></i>
        Geri Dön
      </button>
    </div>
  </div>
</template>

<script>
import asynBtn from "@/components/general/asyncBtn";
import axios from "axios";
import { user } from "../../../../networking/urlmanager";

export default {
  name: "newPasswordForm",
  components: {
    asynBtn,
  },
  data() {
    return {
      token: null,
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
      load: false,
    };
  },
  computed: {
    isStrongPassword() {
      const hasMinLength = this.password.length >= 3;
      // const hasUpperCase = /[A-Z]/.test(this.password);
      // const hasLowerCase = /[a-z]/.test(this.password);
      // const hasNumber = /\d/.test(this.password);
      // && hasUpperCase && hasLowerCase && hasNumber
      return hasMinLength;
    },
    isValidForm() {
      return (
        this.isStrongPassword &&
        this.password === this.passwordConfirm &&
        this.password.length > 0 &&
        this.passwordConfirm.length > 0
      );
    },
  },
  created() {
    // Get token from URL query string
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");

    // Validate token exists
    if (!this.token) {
      console.error("No token provided in URL");
    }
  },
  methods: {
    async save() {
      if (!this.isValidForm) {
        this.swalBox(
          "warning",
          "UYARI",
          "Lütfen tüm gereksinimleri karşıladığınızdan emin olun",
          false,
          "Tamam"
        );
        return;
      }

      try {
        this.load = true;
        await axios.post(user.fargotPasswordSet, {
          token: this.token,
          password: this.password,
        });

        this.swalBox(
          "success",
          "BAŞARILI",
          "Parolanız başarıyla güncellendi",
          false,
          "Tamam"
        );

        // Redirect to login after successful password reset
        setTimeout(() => {
          this.$router.push("/");
        }, 500);
      } catch (err) {
        if (err.response?.status === 400) {
          this.swalBox(
            "error",
            "HATA",
            "Geçersiz veya süresi dolmuş token. Lütfen yeni bir parola sıfırlama talebi oluşturun.",
            false,
            "Tamam"
          );
        } else {
          this.errorBox(err.response);
        }
      } finally {
        this.load = false;
      }
    },
  },
};
</script>

<style>
.asynBtn:hover {
  opacity: 0.5;
}
</style>
