var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full relative"},[(_vm.load)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"67vh"}},[_c('i',{staticClass:"fas fa-spinner fa-3x fa-spin"})]):_vm._e(),_c('button',{staticClass:"px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute right-5 top-5 z-50 shadow-xl",class:_vm.$store.state.mapView == true ? 'bg-black ' : 'bg-white',on:{"click":function($event){return _vm.$store.commit('toggleMapStatus')}}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":_vm.$store.state.mapView == false
          ? require('@/assets/marker/mapstatus.svg')
          : require('@/assets/marker/mapstatus_white.svg')}})]),(_vm.markers.length > 0)?_c('GroupDetail',{attrs:{"List":_vm.markers},on:{"close":function($event){return _vm.refresh()},"selected":(val) => _vm.setPlaqueSelected(val)}}):_vm._e(),(!_vm.load && _vm.markers.length)?_c('div',[_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"67vh !important"},attrs:{"center":_vm.position,"zoom":_vm.zoomLevel,"map-type-id":_vm.$store.state.mapView ? 'hybrid' : 'roadmap',"options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }}},[_c('GmapCluster',{on:{"click":_vm.handleClusterMouseOver}},_vm._l((_vm.markers),function(m){return _c('GmapMarker',{key:m.id,attrs:{"title":m.title,"label":{
            text: m.title, // + '-' + m.speedDirection
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          },"position":m.position,"icon":m.icon}})}),1)],1)],1):_vm._e(),(!_vm.load && _vm.markers.length == 0)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"50vh"}},[_vm._m(0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline text-center"},[_c('i',{staticClass:"fas fa-exclamation-triangle fa-4x"}),_c('p',{staticClass:"block mt-5 px-20 leading-7"},[_vm._v(" Araç konumu bulunamadı lütfen aracın takip sisteminin doğru çalıştığından emin olun ! ")])])
}]

export { render, staticRenderFns }