<template>
  <div class="overflow-y-auto h-full pb-20 xl:h-auto xl:pb-0 bg-white dark:bg-gray-800">
    <div
      v-if="!load"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <diffInput
        class="mt-4"
        v-for="(item, index) in getModules"
        :title="item.name"
        :key="index"
        :value="getFindItem(item)"
        @change="(r) => changeItem(item, r)"
        :optList="generalPerm"
        type="select"
        :openDirection="(index + 1) % 6 === 0 ? 'top' : 'bottom '"
      />
    </div>
    <label v-if="load" class="text-black block text-center p-5">
      <i class="fas fa-spinner fa-4x fa-spin"></i>
    </label>
    <div class="px-8">
      <PaginationComponents
        v-if="totalCount > 0 && !load"
        :totalRowCount="totalCount"
        :currentPage="currentPage"
        :pageCount="pageCount"
        @changePage="(r) => (currentPage = r)"
      />
    </div>
    <div class="w-full mt-4 text-right px-4 py-2">
      <asyncBtn
        icon="fas fa-check"
        :text="$t('general.saveButtonTitle')"
        :loadTitle="$t('general.saveButtonLoadTitle')"
        :loadState="saveLoad"
        @click="() => save()"
        class="w-full md:w-56"
      />
    </div>
  </div>
</template>
<script>
//Global Components
import PaginationComponents from "@/components/general/pagination.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";

// networking
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";

export default {
  name: "authority-modal",
  props: ["rowId"],

  components: {
    diffInput,
    asyncBtn,
    PaginationComponents,
  },

  data() {
    return {
      active: 1,
      load: true,
      saveLoad: false,
      List: [],
      moduleList: [],
      totalCount: 1,
      currentPage: 1,
      pageCount: 2,
      generalPerm: [
        {
          id: "3",
          name: "Yetkisiz",
        },
        {
          id: "1",
          name: "Görüntüleyebilir",
        },
        {
          id: "2",
          name: "Görüntüleyebilir Ve Düzenleyebilir",
        },
      ],
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.moduleList = [];
      this.load = true;
      axios
        .get(managementUser.getPermissions + "?rowId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data[1];
          if (res.data.data[0].length > 0) {
            this.pageCount = res.data.data[0]?.length / 6;
            this.moduleList = res.data.data[0];
            this.totalCount = res.data.data[0].length;
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFindItem(item) {
      const findState = this.List.find((r) => r.permtypeId == item.id);
      return findState ? findState.editState : "0";
    },
    changeItem(item, value) {
      const findIndex = this.List.findIndex((r) => r.permtypeId == item.id);
      if (findIndex > -1) {
        this.List[findIndex].editState = value;
      } else {
        this.List.push({
          editState: value,
          permtypeId: item.id,
        });
      }
    },
    save() {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        this.$t("managementUser.changePermissionText"),
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.saveLoad = true;
          axios
            .post(
              managementUser.changePermission,
              {
                rowId: this.rowId.toString(),
                permList: JSON.stringify(this.List),
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            })
            .finally(() => {
              this.saveLoad = false;
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  computed: {
    getModules() {
      if (this.currentPage > 0)
        return this.moduleList.slice((this.currentPage - 1) * 6, this.currentPage * 6);
    },
  },
};
</script>
