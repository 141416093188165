<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <orderDetail
      :rowId="selected.id"
      @isNewOrder="(val) => (isNewOrder = val)"
      @refresh="addrefresh()"
      ref="orderDetail"
      @orderList="(val) => (orderList = val)"
      @isLaterAddOrder="(val) => (isLaterAddOrder = val)"
    />
    <noteDetail :note="note" />
    <addModal
      :isNewOrder="isNewOrder"
      :orderCount="selected.planCount"
      @refresh="addrefresh()"
      :isLaterAddOrder="isLaterAddOrder"
    />

    <updateEvacuationModal
      :orderNo="selected.orderNo"
      :rowId="selected.id"
      :orderItem="selected"
      @refresh="addrefresh()"
    />

    <vehicleModalVue
      @refresh="getAll()"
      :rowId="selected.id"
      :orderNo="selected.orderNo"
      :order="orderList[0]"
      :isDisabled="selected.tonnage <= selected.receivedTonnage"
      :isOrderStopped="selected.evacuationState == 1"
    />
    <div class="w-full">
      <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
        <div class="w-full">
          <LyrausTable
            :data="List"
            :columns="columns"
            :enablePagination="true"
            :isLoading="load"
            :batchOperations="true"
            :searchBar="true"
            :searchProp="search"
            @update-search-value="(val) => handleSearchValue(val)"
            :handleSearch="handleSearch"
            :alternateRowBg="['white', 'gray-100']"
            :pageCount="pageCount"
            :pageProp="currentPage"
            @update-current-page="handleCurrentPage"
            :totalCount="totalCount"
            :paginationButtonColor="'red-600'"
            :paginationHoverColor="'red-700'"
            :tdClass="'whitespace-nowrap border-gray-200 text-xs py-1'"
            :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
          >
            <template v-slot:rowButtons="{ item, columnKey }">
              <button
                v-if="columnKey === 'totalVehicles'"
                @click="showVehicleList(item)"
              >
                <div
                  class="flex items-center justify-center border rounded-lg w-8 h-6"
                  :class="
                    item.rejectStatus > 0
                      ? 'animate-pulse border-red-400 bg-red-400 text-gray-100'
                      : 'border-gray-400'
                  "
                >
                  {{ item.totalVehicles }}
                </div>
              </button>
              <div v-if="columnKey === 'tonnage'">
                {{
                  Math.ceil(item.tonnage) +
                  " / " +
                  Math.max(item.tonnage - item.receivedTonnage).toFixed(2)
                }}
              </div>
              <div v-if="columnKey === 'process'">
                <asyncBtn
                  @click="changeProcess(item)"
                  :loadState="load"
                  :text="item.evacuationState == 2 ? 'Durdur' : 'Başlat'"
                  icon=""
                  size="py-1 "
                  :containerClass="
                    item.evacuationState === 1
                      ? 'bg-green-700 hover:bg-green-600 w-32'
                      : 'bg-red-700 hover:bg-red-600 w-32'
                  "
                />
              </div>
              <div v-if="columnKey === 'stateId'">
                <div
                  class="w-28 border-r border-opacity-20"
                  :class="
                    item.stateId == 2
                      ? 'text-red-500 animate-pulse duration-200 font-bold text-xs px-3 cursor-pointer select-none hover:bg-black rounded'
                      : item.stateId == 1
                      ? Math.max(item.tonnage - item.receivedTonnage).toFixed(
                          2
                        ) >= 0
                        ? 'text-green-400 animate-pulse duration-200 text-xs font-bold px-3 cursor-pointer select-none hover:bg-black rounded'
                        : 'text-black text-opacity-60  text-xs font-bold px-3 cursor-pointer select-none hover:bg-black rounded'
                      : 'text-gray-600 text-opacity-60  text-xs font-bold px-3 cursor-pointer select-none hover:bg-black rounded'
                  "
                >
                  <p class="">
                    {{
                      item.evacuationState === 1
                        ? "Tahliye Durduruldu"
                        : item.stateId === 1
                        ? Math.max(item.tonnage - item.receivedTonnage).toFixed(
                            2
                          ) <= 0
                          ? "Tamamlandı"
                          : "Başladı"
                        : item.stateId === 2
                        ? "Durduruldu"
                        : "Başlamadı"
                    }}
                  </p>
                </div>
              </div>
              <div v-if="columnKey === 'operations'">
                <div class="flex items-center justify-center">
                  <button
                    class="mx-2"
                    :class="item.explanation == '' ? 'opacity-20' : ''"
                    @click="item.explanation == '' ? '' : noteDetailModal(item)"
                  >
                    <img
                      class="w-4 h-4"
                      src="../../../assets/ship/note.svg"
                      alt=""
                    />
                  </button>
                  <button class="mx-2" @click="orderDetail(item)">
                    <img
                      class="w-4 h-4"
                      src="../../../assets/ship/usersDark.svg"
                      alt=""
                    />
                  </button>
                  <button class="mx-2" @click="deleteItem(item)">
                    <i class="fas fa-trash text-gray-700"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:batchOperations>
              <asyncBtn
                icon="fas fa-plus "
                text="Yeni Depo Tahliyesi"
                class="w-36 btn"
                @click="
                  () => (
                    (isNewOrder = 0),
                    (isLaterAddOrder = false),
                    $modal.show('add-plan-modal')
                  )
                "
              />
            </template>
          </LyrausTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//Npm
import { LyrausTable } from "lyraus-ui";
import moment from "moment";
// Global Components
import asyncBtn from "../../../components/general/asyncBtn.vue";
// Local Components
import noteDetail from "@/components/modals/noteDetail/index.vue";
import orderDetail from "./components/orderDetail/index.vue";
import addModal from "./components/add/index.vue";
import updateEvacuationModal from "./components/updateEvacuation.vue";
import vehicleModalVue from "./components/vehicle-list/index.vue";
// Networking
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";
export default {
  name: "shipEvacuationPlannig-module",
  components: {
    addModal,
    updateEvacuationModal,
    vehicleModalVue,
    orderDetail,
    asyncBtn,
    noteDetail,
  },
  data() {
    return {
      // isFullScreen: false,
      filterState: false, //Kullanılmıyor ?
      List: [],
      note: "",
      columns: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "name",
          label: "Gemi İsmi",
          maxLength: 16,
        },
        {
          key: "startedDate",
          label: "Başlangıç Tarihi",
          isDate: true,
        },
        {
          key: "complatedDate",
          label: "Bitiş Tarihi",
          isDate: true,
        },
        {
          key: "minVehicleCount",
          label: "Min. Araç Sayısı",
        },
        {
          key: "potCount",
          label: "Pot Sayısı",
        },
        {
          key: "totalVehicles",
          label: "Araçlar",
          dontDisplay: true,
          center: true,
        },
        {
          key: "tonnage",
          label: "Toplam / Kalan",
          dontDisplay: true,
        },
        {
          key: "stateId",
          label: "Durum",
          dontDisplay: true,
          center: true,
        },
        {
          key: "process",
          label: "Tahliye Durumu",
        },
        {
          key: "operations",
          label: "İşlemler",
          dontDisplay: true,
          center: true,
        },
      ],
      load: false,
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      selected: { id: "", plaque: "", vehicleId: "" },
      isNewOrder: "",
      orderList: "",
      //Siparişin Sonradan eklenip eklenmediğine bakıyor true ise sipariş sonradan eklenmiştir
      isLaterAddOrder: false,
    };
  },
  methods: {
    async changeProcess(item) {
      this.selected = item;
      if (item.evacuationState === 2) {
        const result = await this.swalBox(
          "warning",
          "UYARI !",
          "Tahliyeyi durdurmak istediğinize emin misiniz?",
          true,
          "Evet, eminim",
          "Hayır, değilim"
        );

        if (result.isConfirmed) {
          const params = {
            typeId: 1,
            shipId: item.id,
          };
          axios
            .post(bulkShipPlanning.process, params, {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            })
            .then(() => {
              this.addrefresh();
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      } else {
        this.$modal.show("storage-process-modal");
      }
    },
    noteDetailModal(item) {
      this.note = item.explanation;
      this.$modal.show("noteDetail-modal");
    },
    addrefresh() {
      this.getAll();
      this.$refs.orderDetail.showRefresh();
    },
    orderDetail(item) {
      this.selected = item;
      this.$modal.show("orderDetail-modal");
    },
    showVehicleList(item) {
      this.$refs.orderDetail.getAll();
      this.selected = item;
      this.$modal.show("vehicle-list-modal");
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    getAll(load = true) {
      if (load) {
        this.List = [];
        this.load = load;
      }
      axios
        .get(
          bulkShipPlanning.getAll +
            "?page=" +
            this.currentPage +
            "&typeId=" +
            2 +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(async (res) => {
          this.List = res.data.data.detail;

          // .map((item) => {
          //   return {
          //     ...item,
          //     state: "Kontrol Ediliyor",
          //     stateId: 0,
          //     action: null,
          //   };
          // });
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;

          // await this.getStatusController();
          this.load = false;
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkShipPlanning.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    // showFilesModal(item) {
    //   this.selected = item;
    //   this.$modal.show("files-modal");
    // },
    // outPointFormatter(name = "", id = 0) {
    //   if (id) {
    //     if (name) return name;
    //     else if (!Number.isInteger(id))
    //       return id.toString().substr(0, 40) + "...";
    //     else return id;
    //   } else return name;
    // },
    async getStatusController() {
      try {
        const result = await axios.get(bulkPlanning.getActionController, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        });
        const list = this.List.map((item) => {
          const findItem = result.data.data?.find((r) => r.id == item.id);
          if (findItem) {
            item.state = findItem.title;
            item.stateId = findItem.type;
            item.action = findItem;
          }
          return item;
        });

        this.List = list;

        return;
      } catch (error) {
        this.swalBox(
          "warning",
          "UYARI!",
          "Durum Kontrol Sistemi başlatılamadı,Lütfen daha sonra tekrar deneyiniz!"
        );
      }
    },
  },
  destroyed() {
    clearInterval(this.$store.state.intervalId);
  },
  created() {
    this.getAll();
  },
  filters: {
    getDateFormat(val) {
      return moment.utc(val).format("LLL");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    filterState(val) {
      if (!val) this.getAll();
    },
    search(val) {
      if (val.length == 0) this.getAll();
    },
  },
};
</script>

<style></style>
