<template>
  <h4 class="w-full block mb-2 pb-2 border-b text-sm">
    {{ title }}
    <span class="font-bold block text-xs mt-1"> {{ value }}</span>
  </h4>
</template>
<script>
export default {
  name: "lineDetail",
  props: ["value", "title"],
};
</script>
