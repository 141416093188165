<template>
  <div>
    <GmapMap
      :center="endPosition"
      ref="gmap"
      :zoom="10"
      :style="{ width: '100%', height: height ? height : '55vh' }"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapPolyline
        v-bind:path.sync="polyline"
        v-bind:options="{ strokeWidth: 20, strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>

      <GmapMarker
        title="'Başlangıç Noktası'"
        :label="{
          text: 'Başlangıç Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="startPosition"
        :icon="require('@/assets/marker/waypoint.png')"
      />

      <GmapMarker
        title="'Bitiş Noktası'"
        :label="{
          text: 'Bitiş Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="endPosition"
        :icon="require('@/assets/marker/waypoint.png')"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "route-report-map",
  props: ["height", "polyline"],
  data() {
    return {
      outpointPosition: undefined,
      targetPosition: undefined,
    };
  },
  methods: {
    getVehicleRoute() {
      this.startPosition = {
        lat: this.polyline[0].lat,
        lng: this.polyline[0].lng,
      };

      this.endPosition = {
        lat: this.polyline[this.polyline.length - 1].lat,
        lng: this.polyline[this.polyline.length - 1].lng,
      };
    },
  },
  created() {
    this.getVehicleRoute();
  },
};
</script>
