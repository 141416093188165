<template>
  <diffInput
    :loading="load"
    type="select"
    :title="title ? '' : 'Planlanmayı Bekleyen Siparişler'"
    label="filterName"
    :optList="List"
    v-model="selected"
    :lightDark="lightDark"
    :hideContainer="hideContainer"
  />
</template>

<script>
// NPM
import moment from "moment";

// Global Inputs
import diffInput from "@/components/general/diffInput.vue";
import { haversineDistance } from "../../utils/recomendation";
// Networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";

export default {
  name: "order-input",
  props: ["value", "title", "lightDark", "isBulk", "hideContainer"],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: "",
      betweenVehicleAndLoad: null,
      totalRoadDistance: null,
      totalFinishTime: null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    distanceTwoLocationHour({ fromLat, fromLng, toLat, toLng }) {
      const distance = haversineDistance(
        { lat: fromLat, lng: fromLng },
        {
          lat: toLat,
          lng: toLng,
        }
      );
      return Math.round((distance / 45) * 60 + 20);
    },
    async getVehicleInfo(plate) {
      try {
        const response = await axios.get(
          orders.getVehicle + "?plate=" + plate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        );

        const { latitude, longitude } = response.data.data[0];
        return { latitude, longitude };
      } catch (err) {
        console.error("Error fetching vehicle info:", err);
        return null; // Return null instead of throwing an error
      }
    },
    async getAll() {
      this.load = true;
      try {
        const result = await axios.get(
          orders.getNotPaginationAll + "?multiple=" + (this.isBulk ? "1" : "2"),
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );
        this.List = await Promise.all(
          result.data.data.map(async (item) => {
            const differentDate = parseInt(
              moment
                .duration(moment(new Date(item.deliveryDate)).diff(moment()))
                .asMinutes()
                .toFixed(2)
            );

            this.betweenVehicleAndLoad = null;
            this.totalRoadDistance = null;
            this.totalFinishTime = null;

            if (item.vehicle) {
              const vehicleResult = await this.getVehicleInfo(item.vehicle);
              if (vehicleResult) {
                this.betweenVehicleAndLoad = this.distanceTwoLocationHour({
                  fromLat: vehicleResult.latitude,
                  fromLng: vehicleResult.longitude,
                  toLat: item.outpointLat,
                  toLng: item.outpointLng,
                });
                this.totalRoadDistance = this.distanceTwoLocationHour({
                  fromLat: item.outpointLat,
                  fromLng: item.outpointLng,
                  toLat: item.targetPointLat,
                  toLng: item.targetPointLng,
                });
                this.totalFinishTime =
                  this.betweenVehicleAndLoad + this.totalRoadDistance;
              }
            }

            let filterName = item.orderNo + " / " + item.name;
            if (
              this.totalFinishTime !== null &&
              differentDate <= this.totalFinishTime - 30
            ) {
              filterName += " - Teslim Tarihi Gecikti";
            }

            return {
              ...item,
              filterName,
              // $isDisabled: differentDate > this.totalFinishTime ? false : true,
            };
          })
        );
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
  },

  created() {
    this.selected = this.value;
    this.getAll();
  },
  watch: {
    selected(val) {
      this.$emit("change", val);

      const findItem = this.List.find((item) => item.id == val);
      if (findItem) {
        this.$emit("changeObject", findItem);
        this.$emit("updateFlag", true);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
