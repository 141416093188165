<template>
  <div
    class="relative"
    @dblclick="doubleClick"
    :style="{
      'min-width:': editState ? '100px !important' : 'auto !important',
    }"
  >
    <span
      v-if="!editState"
      class="border border-white px-2 py-1 rounded-xl block text-center shadow"
      :class="{
        'text-black': stateId == 2,
      }"
      :style="{
        'background-color': getColor,
      }"
    >
      {{ defaultTitle }}
    </span>
    <span
      v-if="editLoad"
      class="block w-full p-2 border-gray-200 rounded-lg dark:border-gray-500 dark:text-gray-300 text-black border px-4 bg-white dark:bg-gray-700 items-center justify-start"
    >
      <div>
        <i class="fas fa-spinner fa-spin"></i>
        Kayıt Ediliyor
      </div>
    </span>
    <select
      v-if="editState && !load && !editLoad"
      v-model="selected"
      @change="() => changeState(selected)"
      class="w-full border-gray-200 rounded dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4 bg-white dark:bg-gray-700"
    >
      <option v-for="item in statusList" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>
    <span
      v-if="editState && load"
      class="border bg-white border-white px-2 rounded-lg block text-center shadow text-xs py-1 text-black"
    >
      <i class="fas fa-spinner fa-spin"></i>
      Yükleniyor
    </span>
    <button
      v-if="editState"
      @click="editState = false"
      class="text-sm absolute -top-2 -right-2 text-red-white font-bold bg-red-500 rounded-full h-5 w-5"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>
</template>

<script>
//Networking
import axios from "axios";
import {
  planning,
  bulkPlanning,
  bulkShipPlanning,
} from "@/networking/urlmanager";
export default {
  name: "status-changer",
  props: [
    "statusList",
    "stateId",
    "defaultTitle",
    "planId",
    "typeId",
    "plaque",
    "driverTC",
    "orderId",
    "active",
    "shipId",
  ],
  data() {
    return {
      editState: false,
      load: false,
      selected: "",
      editLoad: false,
    };
  },
  model: {
    prop: "stateId",
    event: "change",
  },
  methods: {
    doubleClick() {
      if (!(this.stateId === 8 || this.stateId === 9 || this.stateId === 10)) {
        this.editState = true;
      }
    },
    changeState(val) {
      if (this.load == false && this.stateId != this.selected) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Planın statüsünü değiştirmek istediğinize emin misiniz ?",
          true,
          this.$t("general.confirmText"),
          this.$t("general.rejectText")
        ).then((acc) => {
          if (acc.isConfirmed) {
            if (acc.isConfirmed) {
              if (val >= 5 && val < 8 && this.stateId < 5) {
                this.$emit("bulkVehicleStatu", 1);
                return;
              } else if (val == 8) {
                if (this.stateId < 5) {
                  this.swalBox(
                    "warning",
                    "UYARI !",
                    "Öncelikle  BOŞALTMA NOKTASINA GİDİYOR  statüsünü seçip oradaki EVRAKLARI doldurmanız gerekmektedir...",
                    false,
                    this.$t("general.OkayTitle")
                  );
                  return;
                }
                this.$emit("bulkVehicleStatu", 2);
                return;
              }
            }

            this.editLoad = true;
            if (this.active == 1) {
              axios
                .post(
                  bulkPlanning.setState,
                  {
                    rowId: this.planId.toString(),
                    stateId: val.toString(),
                    plaque: this.plaque,
                    orderId: this.orderId,
                    stateName: this.defaultTitle,
                    driverTc: this.driverTC,
                  },

                  {
                    headers: {
                      Authorization:
                        "Bareer " + this.$store.state.userData.token,
                    },
                  }
                )
                .then(() => {
                  this.swalBox(
                    "success",
                    this.$t("general.successTitle"),
                    "",
                    false,
                    this.$t("general.OkayTitle")
                  );
                  this.$emit("refresh", true);
                  this.editState = false;
                  this.editLoad = false;
                })
                .catch((err) => {
                  this.errorBox(err.response);
                  this.editLoad = false;
                });
              return;
            }
            if (this.active == 2) {
              axios
                .post(
                  bulkShipPlanning.setState,
                  {
                    rowId: this.planId.toString(),
                    stateId: val.toString(),
                    plaque: this.plaque,
                    orderId: this.orderId,
                    stateName: this.defaultTitle,
                    driverTc: this.driverTC,
                    shipId: this.shipId,
                  },

                  {
                    headers: {
                      Authorization:
                        "Bareer " + this.$store.state.userData.token,
                    },
                  }
                )
                .then(() => {
                  this.swalBox(
                    "success",
                    this.$t("general.successTitle"),
                    "",
                    false,
                    this.$t("general.OkayTitle")
                  );
                  this.$emit("refresh", true);
                  this.editState = false;
                  this.editLoad = false;
                })
                .catch((err) => {
                  this.errorBox(err.response);
                  this.editLoad = false;
                });
              return;
            }
          }
        });
      }
    },
  },
  created() {
    this.selected = this.stateId;
  },
  computed: {
    getColor() {
      let color = "";
      switch (this.stateId) {
        case 1:
          color = "#525255 !important";
          break;
        case 2:
          color = "#ffffff !important";
          break;
        case 3:
          color = "#2b2c31 !important";
          break;
        case 4:
          color = "#F3AE1C !important";
          break;
        case 5:
          color = "#3f51a3 !important";
          break;
        case 6:
          color = "#A16DAE !important";
          break;
        case 7:
          color = "#00AEEF !important";
          break;
        case 8:
          color = "#0DBC5E !important";
          break;
        case 9:
          color = "#EE302F !important";
          break;
      }
      return color;
    },
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.statusList.map((r) => {
          if (r.id == val) {
            this.defaultTitle = r.name;
            this.$emit("change", this.defaultTitle);
          }
        });
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
