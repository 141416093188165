<template>
  <modal
    name="edit-state-tonnage-and-kilometer-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
    style="z-index: 99 !important"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Kilometre Ve Tonaj Bilgisini Düzenle</h4>
      <button
        class="p-2 w-1/12"
        @click="$modal.hide('edit-state-tonnage-and-kilometer-plan-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="handleSave"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mt-4">
        <diffInput
          type="number"
          minlength="1"
          :required="true"
          v-model="tonnage"
          title="Tonaj"
        />
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="number"
          minlength="1"
          :required="true"
          v-model="kilometer"
          title="Kilometre"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="
            isLoading
              ? 'Araç Verileri Çekiliyor'
              : $t('general.saveButtonLoadTitle')
          "
          :loadState="load || isLoading"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//Axios
import axios from "axios";
import { planning, tracking } from "@/networking/urlmanager";

export default {
  name: "edit-tonnage-kilometer-modal",
  props: [
    "rowId",
    "defaultTonnage",
    "defaultKilometer",
    "planId",
    "vehiclePlaque",
    "wayPointNo",
    "typeId",
  ],
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      isLoading: false,
      tonnage: "",
      kilometer: "",
      load: false,
      capacity: 0,
    };
  },
  methods: {
    handleSave() {
      this.load = true;
      if (this.tonnage > this.capacity) {
        this.swalBox(
          "warning",
          "UYARI !",
          "Araç kapasitesinden fazla tonaj girdiniz kaydetmek istediğinize emin misiniz?",
          true,
          "Evet,eminim",
          "Hayır Değilim"
        ).then((acc) => {
          if (acc.isConfirmed) {
            this.save();
          } else {
            this.load = false;
          }
        });
      } else {
        this.save();
      }
    },
    save() {
      axios
        .post(
          planning.setStateTonnageAndKilometer,
          {
            rowId: this.rowId,
            tonnage: this.tonnage,
            kilometer: this.kilometer,
            planId: this.planId,
            wayPointNo: this.wayPointNo,
            typeId: this.typeId,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-state-tonnage-and-kilometer-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    // changeFillingPoint(item) {
    //   this.changeLocationState = false;
    //   this.fillingPoint = item;
    //   this.receivedName = item.adress;
    //   if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // changeDeliveryPoint(item) {
    //   this.changeLocationState = false;
    //   this.deliveryPoint = item;
    //   this.deliveredName = item.adress;

    //   if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // changeProductType(state) {
    //   this.changeLocationState = false;
    //   this.explosiveState = state;
    //   if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // getTimeDetail(mins) {
    //   var h = (mins / 60) | 0,
    //     m = mins % 60 | 0;
    //   return h + " Saat" + " " + m + " Dakika";
    // },
    // changePolyline(val) {
    //   this.polyLine = polyLine.encode(val);
    // },
    // async calculatePrice() {
    //   let montlyKilometer = this.hakedisKilometer * this.transitionCount * 2;
    //   let hgsPrice =
    //     parseFloat(this.costDistribution.cost.cash) * this.transitionCount * 2;
    //   if (this.isOneWay == 1) {
    //     montlyKilometer = this.hakedisKilometer * this.transitionCount;
    //     hgsPrice = parseFloat(this.costDistribution.cost.cash);
    //   }

    //   this.expenseDetail = await calculator(
    //     this.$store.state.userData.token,
    //     1,
    //     montlyKilometer * 12,
    //     montlyKilometer,
    //     hgsPrice,
    //     this.isAmortisman == 1,
    //     this.isOneWay == 1,
    //     this.dayCount
    //   );

    //   const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;

    //   this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
    //   this.otherExpensePrice = total - this.totalFuelPrice;

    //   if (this.isOneWay == 1) {
    //     this.tonnagePrice =
    //       (total / montlyKilometer / 27) * this.hakedisKilometer;
    //   } else {
    //     this.tonnagePrice =
    //       (total / montlyKilometer / 27) * this.hakedisKilometer * 2;
    //   }
    //   this.expensivePrice = total;
    // },
    getVehicleData() {
      this.isLoading = true;
      axios
        .get(tracking.getAll + "?targetVehicle=" + this.vehiclePlaque, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.capacity = res.data.data[0].capacity;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    defaultTonnage(val) {
      this.tonnage = val;
    },
    defaultKilometer(val) {
      this.kilometer = val;
    },
  },
  created() {
    this.tonnage = this.defaultTonnage;
    this.kilometer = this.defaultKilometer;
    this.getVehicleData();
  },
};
</script>

<style></style>
