<template>
  <button @click="isFullScreen = !isFullScreen" class="absolute right-4">
    <img
      :src="
        !isFullScreen
          ? require('@/assets/table/open.svg')
          : require('@/assets/table/close.svg')
      "
      alt=""
    />
  </button>
</template>

<script>
export default {
  name: "table-resize-btn",
  props: ["value"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      isFullScreen: false,
    };
  },
  watch: {
    isFullScreen(val) {
      this.$emit("change", val);
    },
  },
};
</script>
