<template>
  <div v-if="item">
    <GmapMap
      :center="item.recomendation.position"
      ref="gmap"
      :zoom="10"
      :style="{ width: '100%', height: height ? height : '55vh' }"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapMarker
        :title="
          item.recomendation.name
            ? item.recomendation.name
            : item.recomendation.vehicle
        "
        :position="item.recomendation.position"
        :label="{
          text: item.recomendation.name
            ? item.recomendation.name
            : item.recomendation.vehicle, // + '-' + m.speedDirection
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :icon="positionIcon"
      >
      </GmapMarker>

      <GmapMarker
        title="'Yükleme Noktası'"
        :label="{
          text: 'Yükleme Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="{ lat: item?.outpointLat, lng: item?.outpointLng }"
        :icon="require('@/assets/marker/waypoint.png')"
      />
      <GmapMarker
        title="'Teslim Noktası'"
        :label="{
          text: 'Teslim Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="{ lat: item?.targetPointLat, lng: item?.targetPointLng }"
        :icon="require('@/assets/marker/flag.png')"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "map-item",
  props: ["item", "height"],
  data() {
    return {
      positionIcon: null,
    };
  },
  methods: {},
  created() {
    if (this.item) {
      this.positionIcon = this.positionMarker(
        this.item?.vehicle?.speed,
        this.item?.vehicle?.speedDirection
      );
    }
  },
  watch: {
    item(val) {
      if (val) {
        this.positionIcon = this.positionMarker(
          val?.vehicle?.speed,
          val?.vehicle?.speedDirection
        );
      }
    },
  },
};
</script>
