<template>
  <button
    :disabled="load && !fileKey"
    :class="['text-red-500', containerClass]"
    @click="showDetail()"
  >
    {{ filekey ? (load ? "Hazırlanıyor" : "Görüntüle") : "" }}
  </button>
</template>

<script>
// Networking
import { planning } from "@/networking/urlmanager";
import Axios from "axios";

export default {
  name: "async-btn",
  props: ["filekey", "containerClass"],
  data() {
    return {
      load: false,
    };
  },
  methods: {
    showDetail() {
      if (!this.filekey) return;
      if (this.load) return;

      this.load = true;
      Axios.post(
        planning.readFile,
        {
          fileUrl: this.filekey,
        },
        {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        }
      )
        .then((result) => {
          window.open(result.data.data, "_blank");
        })
        .catch((err) => {
          alert("Dosya görüntüleme işlemi başarısız oldu!");
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>
