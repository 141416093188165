<template>
  <div v-if="item">
    <GmapMap
      :center="{ lat: centerLat, lng: centerLng }"
      ref="gmap"
      :zoom="10"
      :style="{ width: '100%', height: height ? height : '55vh' }"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapPolyline
        v-bind:path.sync="polylineListTwo"
        v-bind:options="{ strokeWidth: 20, strokeColor: '#2516c9' }"
      >
      </GmapPolyline>
      <GmapPolyline
        v-bind:path.sync="polylineListOne"
        v-bind:options="{ strokeWidth: 20, strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>

      <GmapMarker
        title="'Yükleme Noktası'"
        :label="{
          text: 'Yükleme Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="outpointPosition"
        :icon="require('@/assets/marker/waypoint.png')"
      />

      <GmapMarker
        title="'Teslim Noktası'"
        :label="{
          text: 'Teslim Noktası',
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="targetPosition"
        :icon="require('@/assets/marker/flag.png')"
      />
    </GmapMap>
  </div>
</template>

<script>
import { decode } from "../../../../utils/encoder";

export default {
  name: "route-report-map",
  props: ["item", "height", "polylineOne", "polylineTwo"],
  data() {
    return {
      polylineListOne: [],
      polylineListTwo: [],
      outpointPosition: undefined,
      targetPosition: undefined,
    };
  },
  methods: {
    getVehicleRoute() {
      this.polylineListOne = decode(this.polylineOne).polyline;
      this.polylineListTwo = decode(this.polylineTwo).polyline;
      this.centerLat =
        this.polylineListOne[this.polylineListOne.length - 1].lat;
      this.centerLng =
        this.polylineListOne[this.polylineListOne.length - 1].lng;

      this.outpointPosition = {
        lat: Number(this.item.outpointLat),
        lng: Number(this.item.outpointLng),
      };
      this.targetPosition = {
        lat: Number(this.item.targetPointLat),
        lng: Number(this.item.targetPointLng),
      };
    },
  },
  created() {
    this.getVehicleRoute();
    console.log("item", this.item);
  },
};
</script>
