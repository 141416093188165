var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"text-white bg-red-600 rounded transition-all duration-200",class:[
    _vm.bgDark
      ? 'bg-custom-dark'
      : _vm.disabled
      ? 'opacity-50 cursor-default'
      : 'bg-red-600 hover:bg-red-300',
    _vm.containerClass,
    _vm.size ? _vm.size : 'pt-3 pb-3 ',
  ],attrs:{"type":_vm.type,"disabled":_vm.loadState || _vm.disabled},on:{"click":function($event){return _vm.$emit('click', true)}}},[(_vm.loadState)?_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" "+_vm._s(_vm.loadTitle)+" ")]):_vm._e(),(!_vm.loadState)?_c('span',[_c('i',{class:_vm.icon}),_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }