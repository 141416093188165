<template>
  <modal
    name="edit-rest-stop-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="15" :rowId="detail.id" />
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-center items-center"
    >
      <h4 class="inline-block w-10/12">NOKTA DÜZENLE</h4>
      <div class="w-2/12 flex items-center justify-center">
        <div class="w-6/12">
          <div class="w-6/12">
            <logBtn />
          </div>
        </div>
        <div class="w-6/12">
          <button
            class="p-2 w-1/12"
            @click="$modal.hide('edit-rest-stop-modal')"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Header -->

    <!-- Body -->

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Adı"
          v-model="name"
          maxlength="1024"
          minlength="2"
          required
          type="Text"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :lat="position.lat"
          :lng="position.lng"
          :draggable="true"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

// Networking
import axios from "axios";
import { restStops } from "@/networking/urlmanager";

//Local Components
export default {
  name: "edit-customer-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    GmapItem,
    logModule,
    logBtn,
  },
  data() {
    return {
      name: "",
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
      load: false,
    };
  },
  methods: {
    save() {
      if (this.name.trim() === "" || this.name.trim().length < 3) {
        this.swalBox(
          "warning",
          "UYARI",
          "Dinlenme noktası ismi en az 3 karakter uzunluğunda olmalıdır.",
          false,
          "Tamam"
        );
        this.load = false;
        return false;
      }
      if (!this.position?.lat) {
        this.swalBox("warning", "UYARI!", "Lütfen ilk önce bir adres seçin");
        return;
      }
      this.load = true;
      axios
        .post(
          restStops.edit,
          {
            name: this.name,
            address: this.position.adress,
            lat: this.position.lat,
            lng: this.position.lng,
            rowId: this.detail?.id,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-rest-stop-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
    getDetail() {
      setTimeout(() => {
        this.name = this.detail?.name;
        this.position = {
          adress: this.detail?.address,
          lat: Number(this.detail?.latitude),
          lng: Number(this.detail?.longitude),
        };
      }, 50);
    },
  },
};
</script>
