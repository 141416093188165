var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{ref:"gmap",style:({ width: '100%', height: _vm.height ? _vm.height : '55vh' }),attrs:{"center":_vm.endPosition,"zoom":10,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }}},[_c('GmapPolyline',{attrs:{"path":_vm.polyline,"options":{ strokeWidth: 20, strokeColor: '#C43A3A' }},on:{"update:path":function($event){_vm.polyline=$event}}}),_c('GmapMarker',{attrs:{"title":"'Başlangıç Noktası'","label":{
        text: 'Başlangıç Noktası',
        color: 'white',
        className:
          'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      },"position":_vm.startPosition,"icon":require('@/assets/marker/waypoint.png')}}),_c('GmapMarker',{attrs:{"title":"'Bitiş Noktası'","label":{
        text: 'Bitiş Noktası',
        color: 'white',
        className:
          'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      },"position":_vm.endPosition,"icon":require('@/assets/marker/waypoint.png')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }