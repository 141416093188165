var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"location-detail-modal","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '60%',"scrollable":true},on:{"opened":function($event){return _vm.getAll()},"closed":function($event){return _vm.reset()}}},[_c('div',{staticClass:"w-full relative"},[_c('button',{staticClass:"p-2 absolute right-5 top-3 z-50",class:_vm.$store.state.mapView == true ? 'text-white ' : 'text-black',on:{"click":function($event){return _vm.$modal.hide('location-detail-modal')}}},[_c('i',{staticClass:"fas fa-times fa-2x"})]),(_vm.load)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"50vh"}},[_c('i',{staticClass:"fas fa-spinner fa-3x fa-spin"})]):_vm._e(),_c('button',{staticClass:"px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute left-5 top-5 z-50 shadow-xl",class:_vm.$store.state.mapView == true ? 'bg-black ' : 'bg-white',on:{"click":function($event){return _vm.$store.commit('toggleMapStatus')}}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":_vm.$store.state.mapView == false
            ? require('@/assets/marker/mapstatus.svg')
            : require('@/assets/marker/mapstatus_white.svg'),"alt":"vehicle map"}})]),(!_vm.load && _vm.markers.length)?_c('div',[_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"60vh !important"},attrs:{"center":_vm.position,"zoom":_vm.zoomLevel,"map-type-id":_vm.$store.state.mapView ? 'hybrid' : 'roadmap',"options":{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
        }}},[_vm._l((_vm.markers),function(m){return _c('GmapMarker',{key:m.id,attrs:{"title":m.title,"label":{
            text: m.title, // + '-' + m.speedDirection
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          },"position":m.position,"icon":m.icon}})}),_vm._l((_vm.detail.waypoints),function(m){return _c('GmapMarker',{directives:[{name:"show",rawName:"v-show",value:(_vm.stateId == 5),expression:"stateId == 5"}],key:m.id + '_way',attrs:{"title":m.no,"label":{
            text: m.no + '. Numaralı Ara Nokta', // + '-' + m.speedDirection
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          },"position":m.position,"icon":m.icon}})}),(_vm.detail.planning && _vm.detail.planning.state < 8)?_c('GmapMarker',{attrs:{"title":_vm.detail.planning.state < 4
              ? 'Yükleme Noktası'
              : _vm.detail.planning.state != 4 && _vm.detail.planning.state < 8
              ? 'Teslim Noktası'
              : '',"label":{
            text: _vm.detail.planning.adress,
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          },"position":_vm.detail.target,"icon":require('../../../../assets/marker/flag.png')}}):_vm._e(),(_vm.taskDetail.polyline && _vm.detail)?_c('GmapPolyline',{attrs:{"path":_vm.taskDetail.polyline,"options":{ strokeColor: '#C43A3A' }},on:{"update:path":function($event){return _vm.$set(_vm.taskDetail, "polyline", $event)}}}):_vm._e()],2),(_vm.stateId == 1 || _vm.stateId == 2 || _vm.stateId == 3 || _vm.stateId == 5)?_c('div',{staticClass:"position absolute bottom-0 bg-white z-50 py-5 w-full px-8 flex items-start justify-start"},[_c('div',{staticClass:"w-4/12"},[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v("Tahmini Varış Süresi")]),_c('p',[_vm._v(_vm._s(_vm.taskDetail.totalMinute))])]),_c('div',{staticClass:"w-4/12 px-5 border-r border-black border-opacity-50 border-l"},[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v("Hedeflenen Varış Süresi")]),(_vm.detail.planning.targetDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.detail.planning.targetDate))+" ")]):_c('span',[_vm._v("Teslim Tarihi Belirtilmemiş")])]),_c('div',{staticClass:"w-4/12 pl-4"},[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v("Hedef Adres "),_c('span',{staticClass:"ml-3 text-red-500"},[_vm._v("("+_vm._s(_vm.detail.planning.state < 4 ? "Yükleme Noktası" : "Boşaltma Noktası")+")")])]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.detail.target.adress)+" ")])])]):_vm._e()],1):_vm._e(),(!_vm.load && _vm.markers.length == 0)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"inline text-center"},[_c('i',{staticClass:"fas fa-exclamation-triangle fa-4x"}),_c('p',{staticClass:"block mt-5 px-20 leading-7"},[_vm._v(" Araç konumu bulunamadı lütfen aracın takip sisteminin doğru çalıştığından emin olun ! ")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }