<template>
  <div>
    <diffInput
      v-if="!disabled"
      :loading="load"
      type="select"
      :manuelSearch="true"
      :title="!titleNone ? (title ? title : $t('devItems.personnelTitle')) : ''"
      :optList="filterList"
      v-model="selected"
      :lightDark="lightDark"
      :required="required"
      :disabled="disabled"
      :hideContainer="hideContainer"
      @asyncFind="asyncFind"
    />
    <!-- Remove commented out input if not needed -->
  </div>
</template>

<script>
// NPM
import moment from "moment";
import Multiselect from "vue-multiselect";
import axios from "axios";
import diffInput from "@/components/general/diffInput.vue";
import { personnelManagement } from "@/networking/urlmanager";

export default {
  name: "personnel-input",
  props: [
    "value",
    "title",
    "disabled",
    "lightDark",
    "required",
    "allType",
    "hideContainer",
    "titleNone",
    "isCache",
  ],
  components: {
    diffInput,
    Multiselect,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: this.value || "",
      filterList: [],
      deepCheckKey: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    async getAll() {
      if (
        this.isCache &&
        this.$store.state.personnelList?.length > 0 &&
        moment().diff(this.$store.state.personnelCacheDate, "minutes") <= 5
      ) {
        const list = this.$store.state.personnelList;
        this.List = [...list];
        this.filterList = [...list];
        return;
      }

      this.load = true;
      try {
        const result = await axios.get(
          personnelManagement.getAllNotPagination,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );

        const list = [{ id: 0, name: "SEÇİLMEDİ" }, ...result.data.data];
        this.List = [...list];
        this.filterList = [...list];

        this.$store.commit("setPersonnelList", this.List);
      } catch (error) {
        console.error(error);
      } finally {
        this.load = false;
      }
    },
    asyncFind(val) {
      this.filterList = this.List.filter((item) => {
        return item.name
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "")
          .includes(
            val.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "")
          );
      });
    },
  },
  async created() {
    await this.getAll();
    if (typeof this.value === "string") {
      const newPlaque = this.value.replace(
        /^(\d+)([A-Za-z]+)(\d+)$/,
        "$1 $2 $3"
      );
      const findItem = this.List.find((r) => r.plaque === newPlaque);
      this.selected = findItem ? findItem.id : "";
    }
  },
  watch: {
    selected(val) {
      if (isNaN(val)) {
        this.deepCheckKey = "plaque";
        const driverTc = this.List.find(
          (r) => r.plaque === val.replace(/^(\d+)([A-Za-z]+)(\d+)$/, "$1 $2 $3")
        ).id;
        this.$emit("change", driverTc);
      } else {
        this.deepCheckKey = "id";
        this.$emit("change", val);
      }
      const findItem = this.List.find((r) => r[this.deepCheckKey] === val);
      if (findItem) {

        const newFindItem = {
          ...findItem,
          plaque: findItem.plaque?.replace(/\s+/g, ""),
        };
        this.$emit("changeObject", newFindItem);
        this.$emit("changeName", findItem.name);
        this.$emit("changeTC", findItem.id);
      } else {
        this.$emit("changeName", "");
        this.$emit("changeTC", "");
      }
    },
    value(val) {
      if (!val) {
        this.selected = "";
        return;
      }
      if (isNaN(val)) {
        const newPlaque = val.replace(/^(\d+)([A-Za-z]+)(\d+)$/, "$1 $2 $3");
        const findItem = this.List.find((r) => r.plaque === newPlaque);
        this.selected = findItem ? findItem.id : "";
      } else {
        this.selected = val;
      }
    },
  },
};
</script>
