<template>
  <div class="bg-gray-200 h-screen w-screen flex items-center justify-center">
    <div class="text-center md:w-6/12">
      <img src="@/assets/error-icons/network-error.png" class="mb-2 mx-auto" alt="" />
      <h1 class="text-black font-bold mb-2">İNTERNET BAĞLANTI HATASI !</h1>
      <p class="text-gray-500">
        Lütfen internet bağlantınızı kontrol edip daha sonra tekrar sayfayı yenileyerek
        tekrar deneyiniz
      </p>

      <button class="btn mt-4">Yenile</button>
    </div>
  </div>
</template>

<script>
export default {
  name:'check-internet-page',
  
};
</script>

<style></style>
