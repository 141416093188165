<template>
  <modal
    name="edit-personnel-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    @opened="getDetail"
  >
    <!-- Header -->
    <logModule :moduleId="6" :rowId="detail.id" />
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-center items-center"
    >
      <h4 class="inline-block w-10/12">
        {{ $t("humanResources.staff.modal.organizeStaff") }}
      </h4>
      <div class="w-2/12 flex items-center justify-center">
        <div class="w-6/12">
          <div class="w-6/12">
            <logBtn />
          </div>
        </div>
        <div class="w-6/12">
          <button class="p-2 w-1/12" @click="$modal.hide('edit-personnel-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Header-->

    <!-- Body -->

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Ad Soyad"
          v-model="fullname"
          type="Text"
          required="true"
          maxlength="100"
          minlength="1"
          :disabled="true"
        />
      </div>

      <div class="w-full md:mt-4">
        <diffInput
          :title="$t('humanResources.staff.modal.turkishIdentityNumber')"
          v-model="tcno"
          type="number"
          required="true"
          :disabled="true"
        />
      </div>

      <div class="w-full md:mt-4">
        <diffInput
          :title="$t('humanResources.staff.phoneNumber')"
          v-model="phone"
          type="text"
          required="true"
          :disabled="true"
        />
      </div>

      <div class="w-full mt-4">
        <diffInput title="Sistem Parolası" v-model="password" type="text" />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

// networking
import axios from "axios";
import { personnelManagement } from "@/networking/urlmanager";
export default {
  name: "personnel-edit-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    logModule,
    logBtn,
  },
  data() {
    return {
      fullname: "",
      tcno: "",
      phone: "",
      password: "",
      username: "",

      load: false,
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          personnelManagement.edit,
          {
            rowId: this.detail.id.toString(),
            fullname: this.fullname,
            tcno: this.tcno,
            phone: this.phone,
            password: this.password,
            username: this.username,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.password = "";
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-personnel-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
    getDetail() {
      this.fullname = this.detail?.fullname;
      this.tcno = this.detail?.tcno;
      this.phone = this.detail?.phone;
      this.username = this.detail?.username;
    },
  },
};
</script>
