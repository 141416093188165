<template>
  <div>
    <div class="p-5 h-[500px] overflow-y-auto xl:pb-1 pb-10">
      <div class="space-y-4">
        <div class="grid grid-cols-1 gap-5">
          <diffInput
            type="text"
            max="255"
            min="1"
            v-model="name"
            :required="true"
            title="Gemi Adı"
            class="text-base"
          />
        </div>
        <div class="w-full grid grid-cols-2 gap-5">
          <!-- Başlangıç Tarihi Input -->
          <diffInput
            v-model="startedDate"
            :max="maxDate"
            type="datetime-local"
            :required="true"
            placeholder="Başlangıç Tarihi"
            :min="todayMinDate"
            title="Başlangıç Tarihi"
          />
          <!-- Bitiş Tarihi Input -->
          <diffInput
            v-model="complatedDate"
            :min="startedDate ? startedDate : todayMinDate"
            :max="maxDate"
            type="datetime-local"
            :required="true"
            :oldDate="true"
            placeholder="Bitiş Tarihi"
            title="Bitiş Tarihi"
            :disabled="!startedDate"
          />
        </div>
        <div class="w-full grid grid-cols-2 gap-5">
          <diffInput
            type="number"
            v-model="estimatedFillingTime"
            :required="true"
            title="Tahmini Dolum Süresi (Dakika)"
            :max="1500"
            :min="1"
            :step="1"
          />
          <diffInput
            type="number"
            v-model="estimatedDeliveryTime"
            :required="true"
            title="Tahmini Tahliye Süresi (Dakika)"
            :max="1500"
            :min="1"
            :step="1"
          />
        </div>
        <div class="w-full grid grid-cols-2 gap-5">
          <diffInput
            type="number"
            v-model="potCount"
            :required="true"
            title="Pot Sayısı (Dolum Noktasındaki tahliye vanası sayısı)"
            :max="50"
            :min="1"
            :step="1"
          />
          <diffInput
            type="number"
            v-model="minVehicleCount"
            :required="true"
            title="Dolum Noktasında Bulunması Gereken Minimum Araç Sayısı"
            :max="500"
            :min="1"
            :step="1"
          />
        </div>
        <div class="w-full mt-4 grid grid-cols-1">
          <diffInput
            type="textarea"
            v-model="explanation"
            :title="$t('general.note')"
            maxlength="2800"
            :required="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Npm
import moment from "moment";

//Networking
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "stepOnePage",
  components: {
    diffInput,
  },
  data() {
    const today = moment();
    return {
      name: "",
      startedDate: "",
      complatedDate: "",
      estimatedFillingTime: "",
      estimatedDeliveryTime: "",
      potCount: 0,
      minVehicleCount: 0,
      explanation: "",
      todayMinDate: today.format("YYYY-MM-DDTHH:mm"), // Bugünün tarihini ve saatini kullan
      maxDate: today.clone().add(2, "years").format("YYYY-MM-DDTHH:mm"),
    };
  },
  methods: {
    validateDates() {
      const today = moment();
      const start = moment(this.startedDate);
      const end = moment(this.complatedDate);

      if (start.isBefore(today, "day")) {
        this.$swal({
          icon: "error",
          title: "Geçersiz Başlangıç Tarihi",
          text: "Başlangıç tarihi bugünden önce olamaz.",
          confirmButtonText: "Tamam",
        });
        return false;
      }

      if (end.isBefore(start, "minute")) {
        this.$swal({
          icon: "error",
          title: "Geçersiz Bitiş Tarihi",
          text: "Bitiş tarihi, başlangıç tarihinden sonra olmalıdır.",
          confirmButtonText: "Tamam",
        });
        return false;
      }

      return true;
    },
    save(callback) {
      if (!this.validateDates()) {
        return callback(false);
      }

      if (this.minVehicleCount < this.potCount * 4) {
        this.$swal({
          icon: "error",
          title: "UYARI",
          text: "Dolum noktasında bulunması gereken minimum araç sayısı, Pot sayısının en az 4 katı olmalıdır.",
          confirmButtonText: "Tamam",
        });
        return callback(false);
      }
      axios
        .post(
          bulkShipPlanning.shipAdd,
          {
            typeId: 1,
            name: this.name,
            startedDate: this.startedDate,
            complatedDate: this.complatedDate,
            estimatedFillingTime: this.estimatedFillingTime,
            estimatedDeliveryTime: this.estimatedDeliveryTime,
            potCount: this.potCount,
            minVehicleCount: this.minVehicleCount,
            explanation: this.explanation,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.handleSuccess(res, callback);
        })
        .catch((err) => {
          this.errorBox(err.response);
          callback(false);
        });
    },
    handleSuccess(res, callback) {
      this.$emit("controllerPage", false);
      let insertId = res.data.data.insertId;
      this.$emit("estimatedTimes", {
        filling: this.estimatedFillingTime,
        delivery: this.estimatedDeliveryTime,
      });
      this.$emit("minVehicleCount", this.minVehicleCount);
      this.$emit("insertId", insertId);
      this.swalBox(
        "success",
        this.$t("general.successTitle"),
        "",
        false,
        this.$t("general.OkayTitle")
      );
      this.$emit("refresh");
      callback(true);
    },
  },
  watch: {
    potCount(val) {
      if (this.minVehicleCount < val * 4) this.minVehicleCount = val * 4;
    },
  },
};
</script>
