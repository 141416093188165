<template>
  <diffInput
    :loading="load"
    type="select"
    :title="title ? title : ''"
    :optList="List"
    :placeholder="placeholder"
    v-model="selected"
    :lightDark="lightDark"
    :hideContainer="hideContainer"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "customer-input",
  props: [
    "value",
    "title",
    "lightDark",
    "toggleSelectAll",
    "hideContainer",
    "placeholder",
  ],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: 0,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = false;
      axios
        .get(product.getAllNotPagination, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          const dataList = result.data.data;
          this.List = dataList.length > 0 ? dataList : [];

          if (toggleSelectAll) {
            this.List = [{ id: 0, name: "Tümü" }, ...this.List];
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value;
    this.getAll();
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
      const findItem = this.List.find((r) => r.id == val);
      if (findItem) this.$emit("changeName", findItem.name);
      if (findItem)
        this.$emit("changeExplosiveState", findItem.typeId == 4 ? true : false);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
