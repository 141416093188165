<template>
  <modal
    name="add-product-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.products.modal.newProduct") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-product-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Header -->

    <!-- Body -->

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('operations.products.modal.productName')"
          v-model="name"
          type="Text"
        />
      </div>
      <div class="w-full mt-4">
        <typeSelect v-model="typeId" :multiple="false" />
      </div>
      <div class="mt-5">
        <propertiesSelect :isEdit="false" v-model="properties" />
      </div>
      <div class="mt-5">
        <dorseTypeSelect :isEdit="false" v-model="dorseTypes" />
      </div>
      <!-- <div class="mt-5">
        <immovablesSelect :isEdit="false" v-model="immovables" />
      </div> -->
      <!-- <div class="mt-5">
        <washPropertiesSelect :isEdit="false" v-model="washProperties" />
      </div> -->

      <div class="w-full mt-4">
        <diffInput
          :title="$t('operations.products.modal.explanation')"
          v-model="explanation"
          type="textarea"
        />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>

    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//networking
import axios from "axios";
import { product } from "@/networking/urlmanager";

// local Components
import typeSelect from "./selectors/types.select.vue";
import dorseTypeSelect from "./selectors/dorse.type.select.vue";
import propertiesSelect from "./selectors/properties.select.vue";
import immovablesSelect from "./selectors/immavables.select.vue";
// import washPropertiesSelect from "../../washPoints/components/properties.select.vue";

export default {
  name: "add-product-modal",
  props: ["defaultName"],
  components: {
    diffInput,
    asyncBtn,
    typeSelect,
    propertiesSelect,
    dorseTypeSelect,
    immovablesSelect,
    // washPropertiesSelect,
  },
  data() {
    return {
      load: false,
      name: "",
      typeId: "",
      sdsId: "0",

      properties: [],
      immovables: [],
      dorseTypes: [],
      // washProperties: [],

      explanation: "",
    };
  },
  methods: {
    show() {
      this.name = "";
      this.sdsId = "1";
      this.typeId = "";
      this.properties = [];
      this.immovables = [];
      this.dorseTypes = [];
      // this.washProperties = [];
      this.explanation = "";

      this.$modal.show("add-product-modal");
    },
    hide() {
      this.$modal.hide("add-product-modal");
    },
    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen ürün ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      this.load = true;
      if (!Array.isArray(this.dorseTypes)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen dorse ürünün taşınabileceği dorse türlerini seçiniz!",
          false,
          this.$t("general.OkayTitle")
        );
        this.load = false;
        return;
      }
      if (this.dorseTypes.length == 0) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen dorse ürünün taşınabileceği dorse türlerini seçiniz!",
          false,
          this.$t("general.OkayTitle")
        );
        this.load = false;
        return;
      }
      axios
        .post(
          product.add,
          {
            name: this.name,
            sdsId: this.sdsId.toString(),
            typeId: this.typeId.toString(),

            explanation: this.explanation,

            dorseTypes: this.dorseTypes,
            properties: this.properties,
            immovables: this.immovables,
            // washProperties: [
            //   {
            //     ...this.washProperties,
            //     status: 2,
            //   },
            // ],
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.sdsId = "1";
            this.typeId = "";
            this.properties = [];
            this.immovables = [];
            this.dorseTypes = [];
            this.explanation = "";

            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
  created() {
    this.name = this.defaultName;
  },
  watch: {
    defaultName(val) {
      this.name = val;
    },
    typeId(val) {
      if (val != 4) this.sdsId = "0";
    },
  },
};
</script>
