<template>
  <button
    class="text-white bg-red-600 rounded transition-all duration-200"
    :class="[
      bgDark
        ? 'bg-custom-dark'
        : disabled
        ? 'opacity-50 cursor-default'
        : 'bg-red-600 hover:bg-red-300',
      containerClass,
      size ? size : 'pt-3 pb-3 ',
    ]"
    :type="type"
    @click="$emit('click', true)"
    :disabled="loadState || disabled"
  >
    <span v-if="loadState">
      <i class="fas fa-spinner fa-spin"></i> {{ loadTitle }}
    </span>
    <span v-if="!loadState"> <i :class="icon"></i> {{ text }} </span>
  </button>
</template>
<script>
export default {
  name: "async-btn",
  props: {
    bgDark: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: "",
      required: false,
    },
    loadState: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "fas fa-paper-plane",
      required: false,
    },
    loadTitle: {
      type: String,
      required: false,
      default: "Gönderiliyor",
    },
    text: {
      type: String,
      required: true,
      default: "Gönder",
    },
    type: {
      type: String,
      required: false,
      default: "submit",
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
