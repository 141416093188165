<template>
  <div>
    <div
      v-for="(item, index) in approveList"
      :key="index"
      @click="activeDetail(item)"
      class="w-full py-3 select-none cursor-pointer px-4 text-xs rounded bg-gray-100 shadow border-b mt-2 flex items-center justify-between"
      :class="selectedItem?.id === item.id && 'border-l-4 border-red-500'"
    >
      <label> {{ item.title }}</label>
      <label
        class="rounded-xl px-2 py-0 font-bold"
        :class="item?.isUnApprovad ? 'text-yellow-500' : 'text-purple-500'"
        style="font-size: 10px"
      >
        {{ item?.isUnApprovad ? "Onay Bekliyor" : "Yetişemeyecek" }}
      </label>
    </div>

    <div class="text-center text-xs mt-2" v-if="totalWarningCount <= 0">
      Herhangi bir uyarı bulunamadı
    </div>
  </div>
</template>

<script>
export default {
  name: "isAnApprovad",
  props: ["totalWarningCount", "List", "isApproved"],
  data() {
    return {
      selectedItem: null,
      approveList: [],
    };
  },
  methods: {
    activeDetail(item) {
      this.selectedItem = item;
      this.$emit("selectedItem", item.vehicle);
    },
    filterList(approved) {
      const approveState =
        approved === 1 ? true : approved === 2 ? false : "noFilter";
      if (approveState !== "noFilter") {
        const newList = this.List.filter(
          (item) => item.isUnApprovad === approveState
        );
        this.approveList = newList;
      } else if (approveState === "noFilter") {
        this.approveList = this.List;
      }
    },
  },
  created() {
    this.approveList = this.List;
  },
  watch: {
    isApproved() {
      this.filterList(this.isApproved);
    },
  },
};
</script>
