<template>
  <modal
    name="edit-wash-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
    @opened="getDetail()"
    @closed="currentStep = 0"
  >
    <logModule :moduleId="9" :rowId="detail.id" />

    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex justify-between items-center"
    >
      <h4 class="inline-block w-3/12">NOKTA DÜZENLE</h4>
      <div class="w-3/12 flex items-center justify-end">
        <logBtn />
        <button
          class="pl-2 pr-3 w-1/12"
          @click="$modal.hide('edit-wash-modal')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="w-full px-4 pb-4 flex items-center justify-center gap-4">
      <button
        :class="{
          'bg-red-500 text-white': currentStep === 0,
          'bg-gray-300 text-gray-700 hover:text-white': currentStep !== 0,
        }"
        class="w-1/2 font-semibold rounded-sm shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
        @click="() => changeStep(0)"
      >
        Yıkama Noktası Bilgileri
      </button>
      <button
        :class="{
          'bg-red-500 text-white': currentStep === 1,
          'bg-gray-300 text-gray-700 hover:text-white': currentStep !== 1,
        }"
        class="w-1/2 font-semibold rounded-sm shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
        @click="() => changeStep(1)"
      >
        Yıkama Noktası Özellikleri
      </button>
    </div>

    <!-- Steps Content -->
    <form
      @submit.prevent="save"
      class="w-full h-full px-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto pb-20 xl:pb-3"
    >
      <div class="relative h-112 overflow-hidden">
        <div
          class="absolute inset-0 flex transition-transform duration-500 ease-in-out"
          :style="{ transform: `translateX(-${currentStep * 100}%)` }"
        >
          <div class="w-full flex-shrink-0 pt-4">
            <StepOne v-model="washPointInfo" />
          </div>
          <div class="w-full flex-shrink-0">
            <StepTwo
              v-model="propertiesList"
              :propertyList="propertyList"
              :isEdit="true"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-4 h-1/6 text-right">
        <asyncBtn
          :icon="currentStep === 0 ? '' : 'fas fa-check'"
          :text="currentStep === 0 ? 'DEVAM ET' : 'KAYDET'"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

// Networking
import axios from "axios";
import { washPoint } from "@/networking/urlmanager";

//Local Components
import StepOne from "./stepOne.vue";
import StepTwo from "./stepTwo.vue";
export default {
  name: "edit-customer-modal",
  props: ["detail", "propertyList"],
  components: {
    diffInput,
    asyncBtn,
    GmapItem,
    logModule,
    logBtn,
    StepTwo,
    StepOne,
  },
  data() {
    return {
      washPointInfo: {
        name: "",
        sensibility: 0,
        position: {
          lat: 0,
          lng: 0,
          adress: "",
        },
      },
      propertiesList: [],
      load: false,
      currentStep: 0,
      steps: 2,
    };
  },
  methods: {
    changeStep(step) {
      if (this.currentSteps === 0) {
        if (
          this.washPointInfo.name.trim() === "" ||
          this.washPointInfo.name.trim().length < 3
        ) {
          this.swalBox(
            "warning",
            "UYARI",
            "Yıkama noktası ismi en az 3 karakter uzunluğunda olmalıdır.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        if (this.washPointInfo.position.adress.trim() === "") {
          this.swalBox(
            "warning",
            "UYARI",
            "Lütfen yıkama noktası adresi seçiniz.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        if (this.washPointInfo.sensibility < 10) {
          this.swalBox(
            "warning",
            "UYARI",
            "Hassasiyet 10'dan az olamaz.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        if (this.propertiesList) {
          this.currentStep = step;
        }
      } else {
        this.currentStep = step;
      }
    },
    getDetail() {
      setTimeout(() => {
        this.washPointInfo.name = this.detail?.name;
        this.washPointInfo.position = {
          adress: this.detail?.address,
          lat: Number(this.detail?.latitude),
          lng: Number(this.detail?.longitude),
        };
        this.washPointInfo.sensibility = this.detail?.sensibility;
      }, 50);
      this.getWashingProperties();
    },
    getWashingProperties() {
      setTimeout(() => {
        const transformedArray = this.detail.properties.reduce((acc, curr) => {
          let existingObj = acc.find((obj) => obj.productId === curr.productId);
          if (!existingObj) {
            existingObj = {
              productId: curr.productId,
              productName: curr.productName,
              status: 1,
              rowIdList: {},
            };
            acc.push(existingObj);
          }
          existingObj[curr.id] = curr.price;
          existingObj.rowIdList[curr.id] = curr.rowId;

          return acc;
        }, []);
        this.propertiesList = transformedArray;
      }, 50);
    },
    save() {
      if (this.currentStep === 0) {
        this.changeStep(1);
      } else {
        const transformedArray = this.propertiesList.flatMap((obj) => {
          const productId = obj.productId;
          return Object.keys(obj)
            .filter((key) => !isNaN(key))
            .map((key) => {
              const params = {
                id: parseInt(key),
                productId: productId,
                price: obj[key] ? obj[key] : 0,
                status: obj.status,
                propertyName: this.propertyList[parseInt(key) - 1].name,
              };
              const rowId = obj.rowIdList
                ? obj.rowIdList[parseInt(key)]
                : undefined;
              if (obj.status === 1 && rowId === undefined) {
                params.status = 2;
              }
              if (obj.status !== 2) {
                params.rowId = rowId;
              }
              return params;
            });
        });
        this.load = true;

        for (const item of transformedArray) {
          if (isNaN(item.price) || item.price < 0) {
            this.swalBox(
              "warning",
              "UYARI",
              "Değerler 0'dan küçük olamaz!",
              false,
              "Tamam"
            );
            this.load = false;
            return false;
          }
        }

        axios
          .post(
            washPoint.edit,
            {
              name: this.washPointInfo.name,
              address: this.washPointInfo.position.adress,
              lat: this.washPointInfo.position.lat,
              lng: this.washPointInfo.position.lng,
              properties: transformedArray,
              sensibility: this.washPointInfo.sensibility,
              rowId: this.detail?.id,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("edit-wash-modal");
            });
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
      }
    },
  },
};
</script>
