<template>
  <modal
    name="edit-user-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <logModule :moduleId="7" :rowId="detail.id" />
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="w-full flex items-center justify-center">
        <h4 class="inline-block w-10/12">
          {{ $t("managementUser.modal.edittitle") }}
        </h4>

        <div class="flex items-center justify-center w-2/12 p-2">
          <div class="w-6/12">
            <logBtn />
          </div>
          <div class="w-6/12">
            <button class="ml-2" @click="$modal.hide('edit-user-modal')">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Kullanıcı Tipi"
          v-model="userType"
          required
          :disabled="true"
          type="select"
          :optList="[
            {
              id: '2',
              name: 'Müşteri',
            },
            {
              id: '1',
              name: 'Operatör',
            },
          ]"
        />
      </div>

      <div v-if="userType === '2'" class="w-full mt-4">
        <CustomersSelect v-model="List" />
      </div>

      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.username')"
          type="Text"
          v-model="username"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.fullname')"
          type="Text"
          v-model="fullname"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.email')"
          type="email"
          v-model="email"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.authority')"
          type="Text"
          v-model="authority"
          :required="true"
        />
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4 md:mr-2">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.password") }}
            </h6>
            <VuePassword
              v-model="password"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
        <div class="w-full md:w-6/12 mt-4">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.rePassword") }}
            </h6>
            <VuePassword
              v-model="passwordAgain"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
      </div>

      <div class="rounded w-full"></div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//npm
import VuePassword from "vue-password";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import CustomersSelect from "../../fleetManagement/components/customers.select.vue";

//networking
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";

export default {
  name: "edit-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    VuePassword,
    logModule,
    logBtn,
    CustomersSelect,
  },
  data() {
    return {
      load: false,
      username: "",
      fullname: "",
      email: "",
      authority: "",
      List: [],
      userType: 1,
      supplyId: "", // Gereksiz state ?
      password: "",
      passwordAgain: "",
    };
  },
  methods: {
    save() {
      if (this.password !== this.passwordAgain) {
        // Passwords do not match, show an error message
        this.errorBox({
          status: 400,
          data: {
            message: this.$t("Girilen Parolalar Uyuşmamaktadır"),
          },
        });
        return;
      }

      this.load = true;
      axios
        .post(
          managementUser.edit,
          {
            rowId: this.detail.id.toString(),
            namesurname: this.fullname,
            username: this.username,
            authority: this.authority,
            supplyId: "0",
            email: this.email,
            password: this.password,
            userType: this.userType,
            customers: this.List,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.load = false;
          this.fullname = "";
          this.username = "";
          this.authority = "";
          this.email = "";
          this.userType = "";
          this.password = "";
          this.passwordAgain = "";
          this.$emit("refresh", true);
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.$modal.hide("edit-user-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },

  watch: {
    detail(val) {
      this.username = val.username;
      this.fullname = val.namesurname;
      this.email = val.email;
      this.authority = val.authority;
      this.userType = val.userType;
      this.List = val.customers.map((item) => ({
        ...item,
        status: 1,
      }));
      this.supplyId = val.supplyId;
    },
  },
};
</script>
