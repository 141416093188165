<template>
  <div class="rounded w-full relative">
    <multiselect
      :options="filterList"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true"
      placeholder="Müşteri seçiniz"
      label="name"
      track-by="customerId"
      selectLabel="EKLE"
      deselectLabel="KALDIR"
      :preselect-first="true"
      :close-on-select="false"
      :clear-on-select="false"
      :internal-search="false"
      @search-change="asyncFind"
    >
      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span class="multiselect__single" v-if="values.length" v-show="!isOpen"
          >{{ values.length }} Müşteri Seçildi</span
        ></template
      >
    </multiselect>
  </div>
</template>

<script>
//npm
import multiselect from "vue-multiselect";

//networking
import axios from "axios";
import { customer } from "@/networking/urlmanager";
import Fuse from "fuse.js";
export default {
  name: "customers-select",
  props: ["value"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      filterList: [],
      List: [],
      load: false,
      fuse: null,
    };
  },
  methods: {
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.customerId === val.customerId
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (item.status === 3) this.selectedList[findIndex].status = 1;
        if (item.status !== 1) this.selectedList[findIndex].status = 2;
      } else {
        const tag = {
          customerName: val.name,
          customerId: val.customerId,
          status: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex(
        (r) => r.customerId === val.customerId
      );
      const item = this.selectedList[findIndex];
      if (item.status === 1) this.selectedList[findIndex].status = 3;
      else this.selectedList.splice(findIndex, 1);
    },
    getList() {
      return this.selectedList.filter((r) => r.status !== 3);
    },
    getAll() {
      this.load = true;
      axios
        .get(customer.getAllNotPagination, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.List = result.data.data.map((item) => ({
            ...item,
            customerId: item.id,
            name: item.customerCode
              ? item.name + "-" + item.customerCode
              : item.name,
          }));

          this.fuse = new Fuse(this.List, {
            keys: ["name"],
            includeScore: false,
            threshold: 0.4,
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
          this.asyncFind();
        });
    },

    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);

      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
  },
  created() {
    this.selectedList = this.value;
    this.getAll();
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.$emit("change", val);
      }
    },
    value(val) {
      if (val) this.selectedList = val;
    },
  },
};
</script>
