<template>
  <div class="min-h-80 p-2 bg-gray-200 dark:bg-gray-900">
    <noteDetail :note="note" title="Operatör Aksiyonu" />
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div class="w-full">
        <LyrausTable
          :data="List"
          :columns="columns"
          :enablePagination="true"
          :searchBar="true"
          :searchProp="search"
          @update-search-value="(val) => handleSearchValue(val)"
          :handleSearch="handleSearch"
          :isLoading="load"
          :pageCount="pageCount"
          :pageProp="currentPage"
          @update-current-page="handleCurrentPage"
          :buttonsColumn="{ label: 'Aksiyon', width: 40 }"
          :totalCount="totalCount"
          :alternateRowBg="['white', 'gray-100']"
          :batchOperations="true"
          :rightExtraSlot="true"
          :paginationButtonColor="'red-600'"
          :paginationHoverColor="'red-700'"
          :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
          :tdClass="'whitespace-nowrap border-gray-200 text-xs py-1'"
          :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
          :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
        >
          <template v-slot:colButtons="{ item, index }">
            <div class="flex items-center justify-end px-2">
              <button @click="noteDetailModal(item, index)">
                <i class="fas fa-book text-gray-700"></i>
              </button>
            </div>
          </template>
          <template v-slot:batchOperations>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </template>
        </LyrausTable>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

// Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import noteDetail from "@/components/modals/noteDetail/index.vue";

// Networking
import axios from "axios";
import { reports } from "../../../networking/urlmanager";
import moment from "moment";

export default {
  name: "fuel-anomalies-report",
  components: {
    asyncBtn,
    noteDetail,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      excelList: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      note: "",
      columns: [
        {
          key: "driverName",
          label: "Sürücü",
        },
        {
          key: "driverTc",
          label: "Sürücü TC",
        },
        {
          key: "plate",
          label: "Plaka",
        },
        {
          key: "orderNo",
          label: "Sipariş No",
        },
        {
          key: "requestDate",
          label: "Bildirim Tarihi",
          isDate: true,
        },
        {
          key: "responseDate",
          label: "Cevaplanma Tarihi",
          isDate: true,
        },
        {
          key: "requestType",
          label: "Sorun Türü",
        },
        {
          key: "operatorName",
          label: "Operatör Adı",
        },
      ],
    };
  },
  methods: {
    noteDetailModal(item) {
      this.note = item.action;
      this.$modal.show("noteDetail-modal");
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    getRequestTypeName(requestType) {
      switch (requestType) {
        case "1":
          return "Kaza";
        case "2":
          return "Arıza";
        case "3":
          return "Bakım";
        case "4":
          return "Diğer";
        default:
          return "Bilinmeyen Durum";
      }
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
    },
    formatList(list) {
      const newList = list.map((item) => ({
        ...item,
        driverName: item.driverName || "-",
        driverTc: item.driverTc || "-",
        plate: item.plate || "-",
        orderNo: item.orderNo || "-",
        requestDate: item.requestDate
          ? moment(item.requestDate).format("YYYY-MM-DD HH:mm")
          : "-",
        responseDate: item.responseDate
          ? moment(item.responseDate).format("YYYY-MM-DD HH:mm")
          : "-",
        requestType: item.requestType
          ? this.getRequestTypeName(item.requestType)
          : "-",
        operatorName: item.operatorName || "-",
      }));
      return newList;
    },
    getAll() {
      this.List = [];
      this.load = true;
      const params = {
        page: this.currentPage,
        search: this.search,
      };
      axios
        .get(reports.getServiceRequestReports, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = this.formatList(res.data.data.detail);
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },

    getExcelData() {
      const params = {
        search: this.search,
      };

      // Return the Promise from axios
      return axios
        .get(reports.getFuelAnomaliesAll, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token, // Fixed typo in "Bearer"
          },
        })
        .then((res) => {
          return this.formatList(res.data.data);
        })
        .catch((err) => {
          this.errorBox(err.response);
          return []; // Return empty array in case of error
        })
        .finally(() => {
          this.load = false;
        });
    },

    async excelExport() {
      try {
        const workbook = XLSX.utils.book_new();

        const headers = {
          driverName: "Sürücü",
          driverTc: "Sürücü TC",
          plate: "Plaka",
          orderNo: "Sipariş No",
          requestDate: "Bildirim Tarihi",
          responseDate: "Cevaplanma Tarihi",
          requestType: "Sorun Türü",
          operatorName: "Operatör Adı",
          action: "Aksiyon",
        };

        const excelData = await this.getExcelData();

        if (!excelData || !Array.isArray(excelData)) {
          throw new Error("Invalid data received");
        }

        // Create worksheet data with headers and rows
        const worksheet = XLSX.utils.aoa_to_sheet([
          Object.values(headers), // Headers row
          ...excelData.map((item) => [
            item.driverName || "",
            item.driverTc || "",
            item.plate || "",
            item.orderNo || "",
            item.requestDate || "",
            item.responseDate || "",
            item.requestType || "",
            item.operatorName || "",
            item.action || "",
          ]),
        ]);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "serviceRequestsReport"
        );

        // Create and save the file
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(data, "fuel-anomalies-report.xlsx");
      } catch (error) {
        console.error("Excel export failed:", error);
        // Handle error appropriately (maybe show an error message to user)
        if (this.errorBox) {
          this.errorBox(error);
        }
      }
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
