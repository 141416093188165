import { render, staticRenderFns } from "./vehicleList.modal.vue?vue&type=template&id=5af89e32"
import script from "./vehicleList.modal.vue?vue&type=script&lang=js"
export * from "./vehicleList.modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports