<template>
  <modal
    name="edit-product-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="5" :rowId="detail.id" />
    <!-- Header -->

    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex items-center justify-center"
    >
      <h4 class="inline-block w-10/12">
        {{ $t("operations.products.modal.editProduct") }}
      </h4>
      <div class="mt-4 w-2/12 ml-auto flex items-center">
        <div class="w-6/12">
          <logBtn />
        </div>
        <div class="w-6/12">
          <button @click="$modal.hide('edit-product-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Header -->

    <!-- Body -->

    <span
      v-if="getLoad"
      class="block p-4 text-center dark:text-gray-200 dark:bg-gray-900"
    >
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </span>
    <div
      v-if="!getLoad"
      class="dark:bg-gray-900 dark:text-white overflow-y-auto h-full pb-20 xl:h-auto xl:pb-0 min-w-full"
    >
      <form
        v-if="!getLoad"
        @submit.prevent="save"
        class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 mt-4"
      >
        <div class="w-full">
          <diffInput
            :title="$t('operations.products.modal.productName')"
            v-model="name"
            type="Text"
          />
        </div>
        <div class="w-full mt-4">
          <typeSelect v-model="typeId" :multiple="false" />
        </div>
        <div class="mt-5">
          <propertiesSelect :isEdit="true" v-model="properties" />
        </div>
        <div class="mt-5">
          <dorseTypeSelect :isEdit="true" v-model="dorseTypes" />
        </div>
        <!-- <div class="mt-5">
          <immovablesSelect :isEdit="true" v-model="immovables" />
        </div> -->
        <!-- <div class="mt-5">
          <washPropertiesSelect :isEdit="true" v-model="washProperties" />
        </div> -->

        <div class="w-full mt-4">
          <diffInput
            :title="$t('operations.products.modal.explanation')"
            v-model="explanation"
            type="textarea"
          />
        </div>
        <div class="w-full mt-4 text-right">
          <asyncBtn
            icon="fas fa-check"
            :text="$t('general.saveButtonTitle')"
            :loadTitle="$t('general.saveButtonLoadTitle')"
            :loadState="load"
            class="w-full md:w-56"
          />
        </div>
      </form>
    </div>

    <!-- Body -->
  </modal>
</template>

<script>
//global components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

//networking
import axios from "axios";
import { product } from "@/networking/urlmanager";

//local components
import typeSelect from "./selectors/types.select.vue";
import dorseTypeSelect from "./selectors/dorse.type.select.vue";
import propertiesSelect from "./selectors/properties.select.vue";
import immovablesSelect from "./selectors/immavables.select.vue";
// import washPropertiesSelect from "./selectors/washProperties.select.vue";

export default {
  name: "edit-product-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    logModule,
    logBtn,
    typeSelect,
    dorseTypeSelect,
    propertiesSelect,
    immovablesSelect,
    // washPropertiesSelect,
  },
  data() {
    return {
      load: false,
      getLoad: false,
      name: "",
      typeId: "",

      properties: [],
      immovables: [],
      dorseTypes: [],
      // washProperties: [],
      // currentWashProperty: [],
      // washStatus: null,
      explanation: "",
    };
  },
  methods: {
    getDetail() {
      this.getLoad = true;
      axios
        .get(product.getSingle + "?rowId=" + this.detail.id, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          const detail = res.data.data[0][0];
          this.name = detail.name;
          this.typeId = detail.typeId;
          this.explanation = detail.explanation;

          if (res.data.data[1].length > 0) {
            this.properties = res.data.data[1].map((item) => {
              return {
                id: item.id,
                name: item.name,
                rowId: item.rowId,
                status: 1,
              };
            });
          } else this.properties = [];

          if (res.data.data[4].length > 0) {
            this.immovables = res.data.data[4].map((item) => {
              return {
                id: item.id,
                name: item.name,
                rowId: item.rowId,
                status: 1,
              };
            });
          } else this.immovables = [];

          if (res.data.data[5].length > 0) {
            this.dorseTypes = res.data.data[5].map((item) => {
              return {
                id: item.typeId,
                name: item.name,
                rowId: item.id,
                status: 1,
              };
            });
          } else this.dorseTypes = [];

          // if (res.data.data[6].length > 0) {
          //   this.washStatus = 1;
          //   this.washProperties = res.data.data[6].map((item) => {
          //     return {
          //       id: item.typeId,
          //       name: item.name,
          //       rowId: item.id,
          //       status: 1,
          //     };
          //   });
          //   this.currentWashProperty = this.washProperties;
          // } else {
          //   this.washStatus = 2;
          // }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.getLoad = false;
        });
    },
    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen ürün ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      if (!Array.isArray(this.dorseTypes)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen dorse ürünün taşınabileceği dorse türlerini seçiniz!",
          false,
          this.$t("general.OkayTitle")
        );
        this.load = false;
        return;
      }
      if (this.dorseTypes.length == 0) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen dorse ürünün taşınabileceği dorse türlerini seçiniz!",
          false,
          this.$t("general.OkayTitle")
        );
        this.load = false;
        return;
      }
      this.load = true;
      const params = {
        rowId: this.detail.id.toString(),
        name: this.name,
        typeId: this.typeId.toString(),
        explanation: this.explanation,
        dorseTypes: this.dorseTypes,
        properties: this.properties,
        immovables: this.immovables,
        // washProperties:
        //   this.washProperties.name !== "Silindi"
        //     ? [
        //         {
        //           ...this.washProperties,
        //           status: this.washStatus,
        //         },
        //       ]
        //     : [
        //         {
        //           ...this.currentWashProperty[0],
        //           status: 3,
        //         },
        //       ],
      };
      axios
        .post(product.edit, params, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.properties = [];

            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-product-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
