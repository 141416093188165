<template>
  <modal
    name="add-wash-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
    @closed="currentStep = 0"
  >
    <!-- Header -->
    <div
      class="w-full flex justify-between items-center pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-3/12">YENİ NOKTA</h4>
      <div class="w-3/12 flex items-center justify-end">
        <button class="pl-2 pr-3 w-1/12" @click="closeModal">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <!-- Header -->
    <!-- Body -->
    <div class="w-full px-4 pb-4 flex items-center justify-center gap-4">
      <button
        :class="{
          'bg-red-500 text-white': currentStep === 0,
          'bg-gray-300 text-gray-700 hover:text-white': currentStep !== 0,
        }"
        class="w-1/2 font-semibold rounded-sm shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
        @click="() => changeStep(0)"
      >
        Yıkama Noktası Bilgileri
      </button>
      <button
        :class="{
          'bg-red-500 text-white': currentStep === 1,
          'bg-gray-300 text-gray-700 hover:text-white': currentStep !== 1,
        }"
        class="w-1/2 font-semibold rounded-sm shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300"
        @click="() => changeStep(1)"
      >
        Yıkama Noktası Özellikleri
      </button>
    </div>
    <form
      @submit.prevent="save"
      class="w-full h-full px-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto pb-20 xl:pb-3"
    >
      <div class="relative h-112 overflow-hidden">
        <div
          class="absolute inset-0 flex transition-transform duration-500 ease-in-out"
          :style="{ transform: `translateX(-${currentStep * 100}%)` }"
        >
          <div class="w-full flex-shrink-0 pt-4">
            <StepOne v-model="washPointInfo" />
          </div>
          <div class="w-full flex-shrink-0">
            <StepTwo
              v-model="propertiesList"
              :propertyList="propertyList"
              :isEdit="true"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-4 h-1/6 text-right">
        <asyncBtn
          :icon="currentStep === 0 ? '' : 'fas fa-check'"
          :text="currentStep === 0 ? 'DEVAM ET' : 'KAYDET'"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// Networking
import axios from "axios";
import { washPoint } from "@/networking/urlmanager";

//Local Components
import StepOne from "./stepOne.vue";
import StepTwo from "./stepTwo.vue";

export default {
  name: "add-customer-modal",
  props: ["propertyList"],
  components: {
    asyncBtn,
    StepOne,
    StepTwo,
  },
  data() {
    return {
      washPointInfo: {
        name: "",
        sensibility: 0,
        position: {
          lat: 0,
          lng: 0,
          adress: "",
        },
      },
      currentStep: 0,
      steps: 2,
      load: false,
      propertiesList: [],
    };
  },
  methods: {
    closeModal() {
      this.washPointInfo = {
        name: "",
        sensibility: 0,
        position: {
          lat: 0,
          lng: 0,
          adress: "",
        },
      };
      this.currentStep = 0;
      this.propertiesList = [];
      this.$modal.hide("add-wash-modal");
    },
    changeStep(step) {
      if (this.currentStep === 0) {
        if (
          this.washPointInfo.name.trim() === "" ||
          this.washPointInfo.name.trim().length < 3
        ) {
          this.swalBox(
            "warning",
            "UYARI",
            "Yıkama noktası ismi en az 3 karakter uzunluğunda olmalıdır.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        if (this.washPointInfo.position.adress.trim() === "") {
          this.swalBox(
            "warning",
            "UYARI",
            "Lütfen yıkama noktası adresi seçiniz.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        if (this.washPointInfo.sensibility < 10) {
          this.swalBox(
            "warning",
            "UYARI",
            "Hassasiyet 10'dan az olamaz.",
            false,
            "Tamam"
          );
          this.load = false;
          return false;
        }
        this.currentStep = step;
      } else {
        this.currentStep = step;
      }
    },
    save() {
      if (this.currentStep === 0) {
        this.changeStep(1);
      } else {
        const transformedArray = this.propertiesList.flatMap((obj) => {
          const productId = obj.productId;
          return Object.keys(obj)
            .filter((key) => !isNaN(key))
            .map((key) => ({
              id: parseInt(key),
              productId: productId,
              price: obj[key],
              status: 2,
              propertyName: this.propertyList[parseInt(key) - 1].name,
            }));
        });
        this.load = true;

        for (const item of transformedArray) {
          if (isNaN(item.price) || item.price < 0) {
            this.swalBox(
              "warning",
              "UYARI",
              "Değerler 0'dan küçük olamaz!",
              false,
              "Tamam"
            );
            this.load = false;
            return false;
          }
        }

        axios
          .post(
            washPoint.add,
            {
              name: this.washPointInfo.name,
              address: this.washPointInfo.position.adress,
              lat: this.washPointInfo.position.lat,
              lng: this.washPointInfo.position.lng,
              sensibility: this.washPointInfo.sensibility,
              properties: transformedArray,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.washPointInfo.name = "";
              this.washPointInfo.sensibility = "1";
              this.washPointInfo.position = {
                lat: 0,
                lng: 0,
                adress: "",
              };
              this.propertiesList = [];
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("add-wash-modal");
            });
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
      }
    },
  },
};
</script>
