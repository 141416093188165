<template>
  <div class="p-4">
    <div class="overflow-y-scroll max-h-72">
      <div v-for="(item, index) in List" :key="index" class="mb-3">
        <label class="cursor-pointer select-none">
          <input
            v-if="disabled"
            type="checkbox"
            v-model="item.isSelected"
            @change="updateSelection(item)"
            class="mr-3"
          />
          {{ item.vehicle }}
        </label>
      </div>
    </div>
    <label
      class="mt-2 border-t w-full block pt-2 font-bold text-sm text-red-500"
      v-if="countTitle"
      >{{ countTitle + " : " + List.length }}</label
    >
  </div>
</template>

<script>
export default {
  name: "vehicles",
  props: ["List", "disabled", "countTitle"],
  methods: {
    updateSelection(item) {
      if (item.isSelected) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    },
  },
};
</script>
