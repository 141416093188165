<template>
  <div class="flex items-center w-full">
    <navbarItem
      v-if="hasPermission(1)"
      id="1"
      title="Araç Takip"
      routeName="/vehicle-tracking"
      icon="fas fa-map-signs"
      @changeOpened="handleOpenChange"
    />

    <navbarItem
      v-if="hasPermission(2)"
      id="2"
      title="Hesap Makinesi"
      routeName="/calculator"
      icon="fas fa-fw fa-calculator"
      @changeOpened="handleOpenChange"
    />

    <navbarItem
      v-if="filteredOperationList.length > 0"
      title="Operasyon"
      :isMenu="true"
      icon="fas fa-fw fa-list"
      :list="filteredOperationList"
      :opened="opened"
      @changeOpened="handleOpenChange"
      id="11"
    />

    <navbarItem
      v-if="filteredReports.length > 0"
      title="Raporlar"
      :isMenu="true"
      icon="fas fa-exchange-alt"
      :list="filteredReports"
      @changeOpened="handleOpenChange"
      :opened="opened"
      id="16"
    />

    <navbarItem
      v-if="filteredToolsList.length > 0"
      title="Yönetim"
      :isMenu="true"
      icon="fas fa-fw fa-cogs"
      :list="filteredToolsList"
      @changeOpened="handleOpenChange"
      :opened="opened"
      id="12"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import navbarItem from "./components/navbar.item.vue";

export default {
  components: {
    navbarItem,
  },
  name: "sidebar",
  data() {
    return {
      opened: 0,
      operationList: [
        {
          id: 11,
          title: "Siparişler",
          url: "/orders",
          icon: "fa-chevron-right",
        },
        {
          id: 3,
          title: "Planlama",
          url: "/planning",
          icon: "fa-chevron-right",
        },
        {
          id: 12,
          title: "Toplu Planlama",
          url: "/collectivePlanning",
          icon: "fa-chevron-right",
        },
        {
          id: 10,
          title: "Gemi Tahliyesi",
          url: "/shipEvacuation",
          icon: "fa-chevron-right",
        },
        {
          id: 13,
          title: "Depo Tahliyesi",
          url: "/storageEvacuation",
          icon: "fa-chevron-right",
        },
        {
          id: 3,
          title: "Planlanmış Siparişler",
          url: "/planning/orders",
          icon: "fa-chevron-right",
        },
      ],
      reports: [
        {
          id: 17,
          title: "Sürücü Puantajı",
          url: "/driverTally",
          icon: "fa-chevron-right",
        },
        {
          id: 18,
          title: "Güzergah Raporu",
          url: "/routeReport",
          icon: "fa-chevron-right",
        },
        {
          id: 19,
          title: "Araç Kilometreleri",
          url: "/vehicleKilometers",
          icon: "fa-chevron-right",
        },
        {
          id: 20,
          title: "Araçların Bekleme Süreleri",
          url: "/vehicleIdleTimes",
          icon: "fa-chevron-right",
        },
        {
          id: 21,
          title: "Bekleme Raporu",
          url: "/customerWaitingTime",
          icon: "fa-chevron-right",
        },
        {
          id: 22,
          title: "Ziyaret Raporu",
          url: "/driverHomeVisitReport",
          icon: "fa-chevron-right",
        },
        {
          id: 23,
          title: "Yıkama Raporu",
          url: "/washPointReport",
          icon: "fa-chevron-right",
        },
        {
          id: 25,
          title: "Yakıt Anomalileri Raporu",
          url: "/fuelAnomaliesReport",
          icon: "fa-chevron-right",
        },
        {
          id: 26,
          title: "Servis İstek Raporu",
          url: "/serviceRequestReports",
          icon: "fa-chevron-right",
        },
        {
          id: 27,
          title: "Rotadan Sapma Raporu",
          url: "/routeDeviationReport",
          icon: "fa-chevron-right",
        },
      ],
      toolsList: [
        {
          id: 5,
          title: "Ürünler",
          url: "/products",
          icon: "fa-chevron-right",
        },
        {
          id: 4,
          title: "Müşteriler",
          url: "/customers",
          icon: "fa-chevron-right",
        },
        {
          id: 6,
          title: "Sürücüler",
          url: "/staff",
          icon: "fa-chevron-right",
        },
        {
          id: 9,
          title: "Yıkama Noktaları",
          url: "/washPoint",
          icon: "fa-chevron-right",
        },
        {
          id: 14,
          title: "Şubeler",
          url: "/branches",
          icon: "fa-chevron-right",
        },
        {
          id: 15,
          title: "Dinlenme Noktaları",
          url: "/restStops",
          icon: "fa-chevron-right",
        },
        {
          id: 16,
          title: "Filo Yönetimi",
          url: "/fleetManagement",
          icon: "fa-chevron-right",
        },
        {
          id: 8,
          title: "Maliyet Kalemleri",
          url: "/management/costing",
          icon: "fa-chevron-right",
        },
        {
          id: 7,
          title: "Kullanıcılar",
          url: "/users",
          icon: "fa-chevron-right",
        },
        {
          id: 24,
          title: "Rota Bölge Editörü",
          url: "/routeZoneDesigner",
          icon: "fa-chevron-right",
        },
      ],
    };
  },
  computed: {
    userPermissions() {
      return this.$store.state.userData.perms || [];
    },
    filteredOperationList() {
      return this.operationList.filter((item) => this.hasPermission(item.id));
    },
    filteredReports() {
      return this.reports.filter((item) => this.hasPermission(item.id));
    },
    filteredToolsList() {
      return this.toolsList.filter((item) => this.hasPermission(item.id));
    },
  },
  methods: {
    hasPermission(moduleId) {
      const permission = this.userPermissions.find(
        (perm) => perm.permtypeId === moduleId
      );
      return permission && permission.editState > 0;
    },
    handleOpenChange(val) {
      this.opened = val;
    },
  },
};
</script>

<style>
.active {
  border-left: 5px solid #fc0101 !important;
  border-radius: 0px !important;
  color: rgb(255, 255, 255) !important;
}

.custom-width:hover {
  width: 20%;
}
</style>
