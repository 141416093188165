<template>
  <modal
    name="add-rest-stop-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ NOKTA</h4>
      <button class="p-2 w-1/12" @click="closeModal">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Header -->

    <!-- Body -->

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Adı"
          v-model="name"
          maxlength="1024"
          minlength="2"
          required
          type="Text"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :draggable="true"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";

// Networking
import axios from "axios";
import { restStops } from "@/networking/urlmanager";

//Local Components
export default {
  name: "add-customer-modal",
  components: {
    diffInput,
    asyncBtn,
    GmapItem,
  },
  data() {
    return {
      name: "",
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
      load: false,
    };
  },
  methods: {
    closeModal() {
      this.name = "";
      this.position = {
        lat: 0,
        lng: 0,
        adress: "",
      };
      this.$modal.hide("add-rest-stop-modal");
    },
    save() {
      if (this.name.trim() === "" || this.name.trim().length < 3) {
        this.swalBox(
          "warning",
          "UYARI",
          "Dinlenme noktası ismi en az 3 karakter uzunluğunda olmalıdır.",
          false,
          "Tamam"
        );
        this.load = false;
        return false;
      }
      if (!this.position?.lat) {
        this.swalBox("warning", "UYARI!", "Lütfen ilk önce bir adres seçin");
        return;
      }
      this.load = true;
      axios
        .post(
          restStops.add,
          {
            name: this.name,
            address: this.position.adress,
            lat: this.position.lat,
            lng: this.position.lng,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.sensibility = "1";
            this.position = {
              lat: 0,
              lng: 0,
              adress: "",
            };
            this.propertiesList = [];
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-rest-stop-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>
