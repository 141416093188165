<template>
  <modal
    name="add-user-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Header-->
    <div
      class="pt-3 pb-2 pr-4 pl-4 h-12 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("managementUser.modal.title") }}
      </h4>

      <button @click="$modal.hide('add-user-modal')" class="w-1/12">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Header-->

    <!-- Body-->
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Kullanıcı Tipi"
          v-model="userType"
          required
          type="select"
          :optList="[
            {
              id: 1,
              name: 'Operatör',
            },
            {
              id: 2,
              name: 'Müşteri',
            },
          ]"
        />
      </div>
      <div v-if="userType === 2" class="w-full mt-4">
        <label class="text-xs ml-4">Müşteri</label>
        <CustomersSelect v-model="List" />
      </div>

      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.username')"
          type="Text"
          v-model="username"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.fullname')"
          type="Text"
          v-model="fullname"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.email')"
          type="email"
          v-model="email"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.authority')"
          type="Text"
          v-model="authority"
          :required="true"
        />
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4 md:mr-2">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.password") }}
            </h6>
            <VuePassword
              v-model="password"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
        <div class="w-full md:w-6/12 mt-4 md:mr-2">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.rePassword") }}
            </h6>
            <VuePassword
              v-model="passwordAgain"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
      </div>

      <div class="rounded w-full"></div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>

    <!-- Body-->
  </modal>
</template>

<script>
//npm
import VuePassword from "vue-password";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import CustomersSelect from "../../fleetManagement/components/customers.select.vue";

//networking
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";
export default {
  components: {
    VuePassword,
    CustomersSelect,
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      username: "",
      fullname: "",
      email: "",
      authority: "",
      List: [],
      userType: 1,
      supplyId: "",
      password: "",
      passwordAgain: "",
      load: false,
    };
  },
  methods: {
    save() {
      // Check if passwords match
      if (this.password !== this.passwordAgain) {
        // Passwords do not match, show an error message
        this.errorBox({
          status: 400,
          data: {
            message: this.$t("Girilen Parolalar Uyuşmamaktadır"),
          },
        });
        return;
      }
      this.load = true;
      axios
        .post(
          managementUser.add,
          {
            namesurname: this.fullname,
            username: this.username,
            authority: this.authority,
            supplyId: this.supplyId.toString(),
            email: this.email,
            password: this.password,
            userType: this.userType.toString(),
            customers: this.List,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.load = false;
          this.fullname = "";
          this.username = "";
          this.authority = "";
          this.email = "";
          this.password = "";
          this.userType = "";
          this.passwordAgain = "";
          this.$emit("refresh", true);
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.$modal.hide("add-user-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>
