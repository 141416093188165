<template>
  <modal
    name="vehicle-idle-time-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Rota Raporu</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('vehicle-idle-time-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Modal Content -->
    <div
      v-else
      class="pb-4 px-4 pt-2 items-start justify-between 2xl:mb-20 mb-8"
    >
      <!-- Recommendation Details -->
      <div class="w-full pb-4 space-y-2">
        <div class="flex items-center justify-between">
          <label class="block font-bold text-lg mb-2">ROTA DETAYLARI</label>
        </div>

        <!-- <div class="flex flex-row justify-between items-start space-x-4">
          <div class="w-1/2">
            <previewDetail label="Güzergah ID" :value="detail.routeId || '-'" />

            <previewDetail
              label="Yapılması Gereken Kilometre"
              :value="detail.expectedKm"
            />
            <previewDetail label="Yapılan Kilometre" :value="detail.actualKm" />
            <previewDetail
              label="Seferi Beklenen Kilometre"
              :value="detail.seferiKm"
            />
          </div>
          <div class="w-1/2">
            <previewDetail label="Araç Plakası" :value="detail.vehicle" />
            <previewDetail label="Sefer Sayısı" :value="detail.routeCount" />
            <previewDetail
              label="Doluma Giriş Tarihi"
              :value="formattedEndDate(detail.entryDeliveryPointDate)"
            />
            <previewDetail
              label="Teslime Giriş Tarihi"
              :value="formattedEndDate(detail.entryFillingPointDate)"
            />
          </div>
        </div> -->
      </div>
      <!-- Map Section -->
      <div class="w-full">
        <vehicleIdleMap v-if="polyline[0]" :polyline="polyline" />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import axios from "axios";

// Local Components
// import previewDetail from "../../../operations/planning/components/preview-modal/previewDetail.vue";
import vehicleIdleMap from "./vehicleIdleMap.vue";
import { reports } from "../../../../networking/urlmanager";

export default {
  name: "vehicle-idle-time-modal",
  props: ["detail"],
  components: {
    vehicleIdleMap,
    // previewDetail,
  },
  data() {
    return {
      isLoading: false,
      polyline: [],
    };
  },
  methods: {
    formattedEndDate(val) {
      return val ? moment(val).format("DD/MM/YYYY HH:MM") : "-";
    },
    getDetails() {
      this.isLoading = true;
      axios
        .get(
          `${reports.getVehicleIdleTimeDetail}?emptyVehicleId=${this.detail.id}`,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          this.polyline = result.data.data.map((item) => ({
            lat: Number(item.startLatitude),
            lng: Number(item.startLongitude),
          }));
        })
        .catch((err) => {
          this.polyline = null;
          this.$modal.hide("vehicle-idle-time-modal");
          this.errorBox(err.response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    detail() {
      this.getDetails();
    },
  },
};
</script>
