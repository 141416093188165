var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',[_c('GmapMap',{ref:"gmap",style:({ width: '100%', height: _vm.height ? _vm.height : '55vh' }),attrs:{"center":{ lat: _vm.item.targetPointLat, lng: _vm.item.targetPointLng },"zoom":10,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }}},[_c('GmapPolyline',{attrs:{"path":_vm.polylineList,"options":{ strokeWidth: 20, strokeColor: '#C43A3A' }},on:{"update:path":function($event){_vm.polylineList=$event}}}),_c('GmapMarker',{attrs:{"title":"'Yükleme Noktası'","label":{
        text: 'Yükleme Noktası',
        color: 'white',
        className:
          'absolute bottom-4 -right-12 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      },"position":{ lat: _vm.item?.outpointLat, lng: _vm.item?.outpointLng },"icon":require('@/assets/marker/waypoint.png')}}),_c('GmapMarker',{attrs:{"title":"'Teslim Noktası'","label":{
        text: 'Teslim Noktası',
        color: 'white',
        className:
          'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
        fontSize: '8.5px',
      },"position":{
        lat: _vm.item?.targetPointLat,
        lng: _vm.item?.targetPointLng,
      },"icon":require('@/assets/marker/flag.png')}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }